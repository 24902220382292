import React from 'react';
import './Toast.css';

const Toast = (props) => {
  const { show, onClose, title, variant = "success" } = props;

  // variant: 'success' | 'info'

  if (!show) {
    return null;
  }
  return (
    <div className={`toast toast-${variant}`}>
      <div className="toast-text">{title}</div>
      <div className="toast-close" onClick={() => onClose()}>
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 0.928571L0.928571 0L6.5 5.57143L12.0714 0L13 0.928571L7.42857 6.5L13 12.0714L12.0714 13L6.5 7.42857L0.928572 13L0 12.0714L5.57143 6.5L0 0.928571Z" fill="currentColor" />
        </svg>
      </div>
    </div>
  )
};

export default Toast;