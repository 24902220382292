import React from "react";
import "./Consent.css";

const AntiWrinkleConsent = () => {
  return (
    <React.Fragment>
      <div className="mt-60">
        <div className="consent-type"> Anti Wrinkle</div>
        <div className="consent-text">
          At SILK Laser Clinics anti-wrinkles injections are performed using a
          purified protein called Botulinum Toxin Type A. Anti- Wrinkle
          Injections are prescribed to weaken or block nerve activity in the
          muscle causing a temporary reduction in muscle activity. Primarily
          used in Cosmetic Injections to soften facial lines and expression,
          Anti-Wrinkle Injections are also used in several medical conditions
          such as excessive sweating, jaw clenching, chronic muscle tension
          headaches and belpharospasm, just to name a few. It can take 2- 7 days
          to see the effects of the treatment and results usually last 3-4
          months.
        </div>

        <div className="consent-layout-wrapper">
          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">
                ON THE DAY OF YOUR APPOINTMENT
              </div>
              <p class="paragraph">
                Prior to treatment you will be given a thorough consultation,
                during this consultation a treatment plan is competed, and a
                prescription is produced. All treatment plans and prescriptions
                are valid for 6 months.
                <br />
                <br />
                The procedure itself takes as little as 5 to 15 minutes and is
                relatively painless.
              </p>
            </div>

            <div class="right-side">
              <div className="consent-subheading">AFTER YOUR TREATMENT</div>
              <p class="paragraph">
                To ensure the best possible result you must:
                <ul>
                  <li>
                    Avoid lying down for at least 4 hours immediately after
                    treatment.
                  </li>
                  <li>
                    Animate the treated area for at least 1 hour after
                    treatment.
                  </li>
                </ul>
                In addition, it is recommended that you avoid the following for
                48hours after treatment:
                <ul>
                  <li>
                    Avoid direct pressure, rubbing or manipulating the injection
                    area.
                  </li>
                  <li>
                    Avoid exercise and/or strenuous activity Avoid excessive
                    alcohol.
                  </li>
                  <li>
                    Avoid extreme temperatures e.g. saunas, excessive sun
                    exposure or sub-zero temperatures.
                  </li>
                  <li>Apply moisturiser with care</li>
                </ul>
                If you are undergoing Professional Skin Treatments, please speak
                with your Laser and Dermal Technician for more information about
                the recommended wait times post treatment.
              </p>
            </div>
          </div>
          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">
                COMMON SHORT TERM SIDE EFFECTS
              </div>
              <p class="paragraph">
                SILK Laser Clinics advises that there are certain inherent and
                potential risks in any treatment plan or procedure and that in
                this specific instance, such operative risks include, but are
                not limited to:
                <ul>
                  <li>Bruising and discomfort around the injected area.</li>
                  <li>
                    Headaches, nausea and in the worst cases experience flu-like
                    symptoms
                  </li>
                  <li>
                    There is the possibility in 1% of cases that the dose of
                    Anti-Wrinkle Injections can cause a 'drooping' in the
                    treated area. However due to the temporary nature of
                    Anti-Wrinkle Injections the unwanted results of these
                    complications will rectify themselves within 2 to 4 months.
                  </li>
                </ul>
              </p>
            </div>

            <div class="right-side">
              <div className="consent-subheading">RISK OF BRUISING</div>
              <p class="paragraph">
                The following substances/ medications/ vitamins can increase the
                risk of bruising:
                <ul>
                  <li>Alcohol pre or post treatment</li>
                  <li>Anti-inflammatory medications</li>
                  <li>Vitamin E and Gingko Biloba</li>
                  <li>Fish and Krill oil</li>
                  <li>Aspirin</li>
                  <li>Smoking</li>
                </ul>
              </p>
            </div>
          </div>
          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">PHOTOGRAPHIC CONSENT</div>
              <p class="paragraph">
                I give permission for my photographs to be used for:
                <ul>
                  <li>Internal education purposes</li>
                  <li>
                    Social media marketing including (but not limited to)
                    Facebook and Instagram
                  </li>
                </ul>
              </p>
            </div>

            <div class="right-side">
              <div className="consent-subheading">FINANCIAL CONSENT</div>
              <p class="paragraph">
                Your cosmetic injector will provide you with full details of
                your cost of treatment prior to the procedure. Any deposits paid
                are redeemable against your treatment. Treatment costs are a
                private service and not covered by Medicare.
                <br />
                <br />I have been made aware of all costs associated with my
                treatment plan
              </p>
            </div>
          </div>

          <div className="consent-text">
            All procedures performed at SILK are proven to be safe and
            effective.
            <br />
            <br />
            Further treatment to address any unwanted side effects may be
            required in the short term (such as for anaphlyaxis, hyaluronidaise
            to dissolve dermal filler, antibiotics (oral or topical) or the long
            term (such as steroids to treat ongoing nodules). Costs for this
            treatment if required may or may not be covered by SILK and will
            depend on many factors and be at the discretion of SILK Management.
            <br />
            <br />
            If you have any concerns or questions with regards to your
            treatment, please contact the clinic you were treated at to speak
            with a member of the medical team during clinic opening hours. Full
            information on our complaints process can be found here.
            <br />
            <br />
            In the case of an adverse response, outside of opening hours, please
            contact SILK Afterhours Care on: 08 8342 7435 If you are
            experiencing a medical emergency, please call 000.
            <br />
            <br />
            There are many alternative treatments, including the option of not
            having the procedure. Your cosmetic injector will discuss these
            options with you.
            <br />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AntiWrinkleConsent;
