import React from 'react';
import './Button.css';

const Button = (props) => {
  const { size = '',  variant = "primary", ...otherProps } = props;

  let btnSize = '';
  if (size !== "") {
    btnSize = "btn-" + size;
  }

  return (
    <button className={`btn btn-${variant} ${btnSize}`} {...otherProps} />
  )
};

export default Button;