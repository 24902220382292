import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import Modal from '../../../../components/Modal/Modal';
import EditUser from './EditUser';
import Deactivate from '../../../../components/Deactivate/Deactivate';

import sortNamecIcon from '../../../../assets/images/icons/sort-name-icon.svg';
import sortNamecUpIcon from '../../../../assets/images/icons/sort-name-up-icon.svg';

import sortRolecIcon from '../../../../assets/images/icons/sort-role-icon.svg';
import sortRolecUpIcon from '../../../../assets/images/icons/sort-role-icon.svg';

import { deleteUser, getUsersList } from '../../../../app/reducers/userSlice';
import { getUserRoles } from '../../../../app/reducers/userRolesSlice';
import { useDispatch, useSelector } from 'react-redux';

const Users = () => {
  const [userModalShow, setUserModalShow] = useState(false);
  const [deactivateModalShow, setDeactivateModalShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const dispatch = useDispatch();

  const usersSelector = useSelector((state) => state.users);
  const authenticationSelector = useSelector((state) => state.authentication);

  const handleEditClick = (rowId) => {
    let editedRecord = usersSelector.users.filter((f) => f.Id === rowId);
    setSelectedUser(editedRecord);
    setUserModalShow(true);
  };

  const handleUserModalClose = () => setUserModalShow(false);

  const handleDeactiveClick = (rowId) => {
    let editedRecord = usersSelector.users.filter((f) => f.Id === rowId);

    setSelectedUser(editedRecord);
    setDeactivateModalShow(true);
  };

  const onDeleteClick = (Id) => {
    dispatch(deleteUser({ id: Id, loginUserId: authenticationSelector.id }));
    setDeactivateModalShow(false);
  };

  useEffect(() => {
    dispatch(getUsersList({ loginUserId: authenticationSelector.id }));
    dispatch(getUserRoles({}));
  }, []);

  const handleDeactivateModalClose = () => setDeactivateModalShow(false);

  const columns = [
    {
      label: 'Name',
      controlId: 'Name',
      sortable: true,
      sortIconDown: sortNamecIcon,
      sortIconUp: sortNamecUpIcon,
    },
    { label: 'Email', controlId: 'EmailId', sortable: true },
    {
      label: 'Role',
      controlId: 'UserRole',
      sortable: true,
      sortIconDown: sortRolecIcon,
      sortIconUp: sortRolecUpIcon,
    },
    { label: 'Home Clinic', controlId: 'HomeClinic', sortable: true },
    { label: 'Other Clinic', controlId: 'ActiveClinic', sortable: false },
  ];

  const action = [
    { id: 1, label: 'Edit', onclick: handleEditClick },
    { id: 2, label: 'Deactivate', onclick: handleDeactiveClick },
  ];

  return (
    <React.Fragment>
      {usersSelector.isLoaded && (
        <React.Fragment>
          <Table data={usersSelector.users} columns={columns} action={action} />
          <Pagination />
        </React.Fragment>
      )}

      <Modal
        show={userModalShow}
        onHide={handleUserModalClose}
        title={`Edit ${selectedUser[0]?.Name}`}
      >
        <EditUser onHide={handleUserModalClose} data={selectedUser} />
      </Modal>

      <Modal
        show={deactivateModalShow}
        onHide={handleDeactivateModalClose}
        title={`Deactivate ${selectedUser[0]?.Name}`}
      >
        <Deactivate
          onHide={handleDeactivateModalClose}
          message={`Are you sure you would like to deactivate ${selectedUser[0]?.Name}. Deactivating their system user account will
            mean they can no longer access the system`}
          buttonText={'Deactivate User'}
          data={selectedUser}
          handleDelete={onDeleteClick}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Users;
