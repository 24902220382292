import React from 'react';
import {
  TextInput,
  SelectBox,
  Calendar,
} from '../../../components/FormElements';
import { useSelector } from "react-redux";

import '../Patients.css';
import { states, genderIdentities,birthGenders, pronouns, heardAboutSilk} from "../../../constants/index";

const Details = (props) => {
  const clinicSelector = useSelector((state) => state.clinic);
  let clinics = clinicSelector.clinics.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  const getSelectedClinicName = (id) => {
    let selected = clinics.filter((f) => f.Id === parseInt(id));
    if (selected.length > 0) {
      return selected[0];
    } else {
      return clinics[0];
    }
  };
  
  const getSelectedOptionLabel = (options, value) => {
    let selected = options.filter((f) => f.value === value);
    if (selected.length > 0) {
      return selected[0];
    } else {
      return '';
    }
  };

  return (
    <React.Fragment>
      <div className='section-title'>Patient Details</div>
      <div className='row'>
        <div className='col-md-6'>
          <TextInput
            label='First Name'
            controlId='firstname'
            defaultValue={props.patient.FirstName}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Surname'
            controlId='surname'
            defaultValue={props.patient.LastName}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <Calendar
            label='Date of Birth'
            controlId='dob'
            onChange={(date) => console.log(date)}
            defaultValue={props.patient.DOB}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Email Address'
            controlId='emailAddress'
            defaultValue={props.patient.EmailId}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Mobile Number'
            controlId='mobileNumber'
            defaultValue={props.patient.MobileNumber}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <SelectBox
            label='Home Clinic'
            options={clinics}
            name='homeClinic'
            defaultValue={getSelectedClinicName(props.patient.HomeClinic)}
            readOnly={props.isReadOnly}
          />
        </div>
      </div>
      <div className='section-title mt-10'>Extended Details</div>
      <div className='row'>
        <div className='col-md-6'>
          <SelectBox
            label='Gender Identity'
            options={genderIdentities}
            name='genderIdentity'
            defaultValue={getSelectedOptionLabel(genderIdentities, props.patient.GenderIdentity)}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <SelectBox
            label='Birth Gender'
            options={birthGenders}
            name='birthGender'
            defaultValue={getSelectedOptionLabel(birthGenders,props.patient.BirthGender)}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className="col-md-6">
              <br></br>
            </div>
            <div className="col-md-6">
        
            </div>
        {/* <div className='col-md-4'>
          <SelectBox
            label='Pronouns'
            options={pronouns}
            name='pronouns'
            defaultValue={ getSelectedOptionLabel(pronouns, props.patient.Pronouns)}
            readOnly={props.isReadOnly}
          />
        </div> */}
        <div className='col-md-6'>
          <TextInput
            label='Address'
            controlId='address'
            defaultValue={props.patient.Address}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-3'>
          <SelectBox
            label='State'
            options={states}
            name='state'
            defaultValue={getSelectedOptionLabel(states, props.patient.State)}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-3'>
          <TextInput
            label='Postal Code'
            controlId='postalCode'
            defaultValue={props.patient.PostalCode}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Emergency Contact Name'
            controlId='emergencyContactName'
            defaultValue={props.patient.EmergencyContactName}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Emergency Contact Number'
            controlId='emergencyContactNumber'
            defaultValue={props.patient.EmergencyContactNumber}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='How did you hear about Silk?'
            name='aboutSilk'
            defaultValue={props.patient.HeardAboutSilk}
            readOnly={props.isReadOnly}
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='What would you like to learn about?'
            controlId='learnAbout'
            defaultValue={props.patient.LearnAbout && JSON.parse(props.patient.LearnAbout).join(', ')}
            readOnly={props.isReadOnly}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Details;
