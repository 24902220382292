import { Button } from '../../../components/FormElements';
import { useFormContext,useFieldArray, useWatch } from "react-hook-form";

import '../TreatmentPlans.css';
import { treatmentAreas } from '../../../constants';
import FillerTreatmentArea from './FillerTreatmentArea';

const Filler = () => {
  const methods = useFormContext();
  const { control } = methods;

  const watchData = useWatch();
  
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "filler"
  });

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <FillerTreatmentArea 
            key={index}
            item={item} 
            index={index} 
            treatmentAreas={treatmentAreas} 
            removeArea={remove} 
            removeButtonShow={watchData?.filler?.length > 1 ? true : false}
          />
        );
      })}
      {watchData?.filler[0]?.areaName !== '' &&
      <div className="mb-20">
        <Button size="md" variant="blue btn-primary" type="button"
          onClick={() => {
            append({ areaName: "", areaId: ""});
          }}
        >Add Another Treatment</Button>
      </div>
      }
    </div>
  );
};

export default Filler;
