import React from 'react';
import '../VideoConsult.css';


const QuequeItem = (props) => {
  const { data } = props;

  return (
    <React.Fragment>
      {data && 
      <div className='queque-item'>
        <div>
          <div className='queque-patient-name'>{data?.patientName}</div>
          <div className='queque-pronouns'>{data?.pronouns}   |   {data?.gender}</div>
        </div>
        <div className='text-right'>
          <div className='queque-nurse-name'>
            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2148 11.5C12.4805 11.5469 13.5469 12.0273 14.4141 12.9414C15.3047 13.8555 15.75 14.9453 15.75 16.2109C15.75 16.7734 15.5508 17.2539 15.1523 17.6523C14.7539 18.0508 14.2734 18.25 13.7109 18.25H2.03906C1.47656 18.25 0.996094 18.0508 0.597656 17.6523C0.199219 17.2539 0 16.7734 0 16.2109C0 14.9453 0.433594 13.8555 1.30078 12.9414C2.19141 12.0273 3.26953 11.5469 4.53516 11.5L7.875 14.8398L11.2148 11.5ZM11.0391 9.63672C10.1719 10.5039 9.11719 10.9375 7.875 10.9375C6.63281 10.9375 5.56641 10.5039 4.67578 9.63672C3.80859 8.74609 3.375 7.67969 3.375 6.4375V2.57031C3.375 2.35938 3.44531 2.14844 3.58594 1.9375C3.72656 1.72656 3.90234 1.58594 4.11328 1.51562L7.10156 0.390625C7.33594 0.296875 7.59375 0.25 7.875 0.25C8.15625 0.25 8.41406 0.296875 8.64844 0.390625L11.6367 1.51562C11.8477 1.58594 12.0234 1.72656 12.1641 1.9375C12.3047 2.14844 12.375 2.35938 12.375 2.57031V6.4375C12.375 7.67969 11.9297 8.74609 11.0391 9.63672ZM6.46875 2.78125V3.34375C6.46875 3.46094 6.52734 3.51953 6.64453 3.51953H7.41797V4.29297C7.41797 4.41016 7.47656 4.46875 7.59375 4.46875H8.15625C8.27344 4.46875 8.33203 4.41016 8.33203 4.29297V3.51953H9.10547C9.22266 3.51953 9.28125 3.46094 9.28125 3.34375V2.78125C9.28125 2.66406 9.22266 2.60547 9.10547 2.60547H8.33203V1.83203C8.33203 1.71484 8.27344 1.65625 8.15625 1.65625H7.59375C7.47656 1.65625 7.41797 1.71484 7.41797 1.83203V2.60547H6.64453C6.52734 2.60547 6.46875 2.66406 6.46875 2.78125ZM5.0625 5.875V6.4375C5.0625 7.21094 5.33203 7.87891 5.87109 8.44141C6.43359 8.98047 7.10156 9.25 7.875 9.25C8.64844 9.25 9.30469 8.98047 9.84375 8.44141C10.4062 7.87891 10.6875 7.21094 10.6875 6.4375V5.875H5.0625Z" fill="#666666" />
            </svg>
            {data?.nurseName}
          </div>
          <div className='queque-location'>
            <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.8" d="M4.70312 14.4766C4.26562 13.8385 3.76432 13.1185 3.19922 12.3164C2.65234 11.5143 2.26042 10.9492 2.02344 10.6211C1.78646 10.293 1.51302 9.89193 1.20312 9.41797C0.893229 8.92578 0.683594 8.57943 0.574219 8.37891C0.483073 8.17839 0.373698 7.92318 0.246094 7.61328C0.136719 7.30339 0.0638021 7.03906 0.0273438 6.82031C0.00911458 6.58333 0 6.3099 0 6C0 4.54167 0.510417 3.30208 1.53125 2.28125C2.55208 1.26042 3.79167 0.75 5.25 0.75C6.70833 0.75 7.94792 1.26042 8.96875 2.28125C9.98958 3.30208 10.5 4.54167 10.5 6C10.5 6.3099 10.4818 6.58333 10.4453 6.82031C10.4271 7.03906 10.3542 7.30339 10.2266 7.61328C10.1172 7.92318 10.0078 8.17839 9.89844 8.37891C9.80729 8.57943 9.60677 8.92578 9.29688 9.41797C8.98698 9.89193 8.71354 10.293 8.47656 10.6211C8.23958 10.9492 7.83854 11.5143 7.27344 12.3164C6.72656 13.1185 6.23438 13.8385 5.79688 14.4766C5.66927 14.6589 5.48698 14.75 5.25 14.75C5.01302 14.75 4.83073 14.6589 4.70312 14.4766ZM3.69141 7.55859C4.12891 7.97786 4.64844 8.1875 5.25 8.1875C5.85156 8.1875 6.36198 7.97786 6.78125 7.55859C7.21875 7.12109 7.4375 6.60156 7.4375 6C7.4375 5.39844 7.21875 4.88802 6.78125 4.46875C6.36198 4.03125 5.85156 3.8125 5.25 3.8125C4.64844 3.8125 4.12891 4.03125 3.69141 4.46875C3.27214 4.88802 3.0625 5.39844 3.0625 6C3.0625 6.60156 3.27214 7.12109 3.69141 7.55859Z" fill="#282828" />
            </svg>
            {data?.location}
          </div>
          {/* {data?.isDirectCall && <div className='direct-call'>Direct call</div>} */}
        </div>
      </div>
      }
    </React.Fragment>
  );
};

export default QuequeItem;