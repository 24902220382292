import React, { useState } from 'react';
import { Link } from "react-router-dom";

import './Footer.css';

const Footer = () => {
  const [cookiesShow, setCookiesShow] = useState(true);
  const onAccept = () => {
    setCookiesShow(false);
  };

  const onMinAccept = () => {
    setCookiesShow(false);
  };

  return (
    <React.Fragment>
    <footer className="footer">
      <div className="container footer-container">
        <div>© 2023 SILK Laser Clinics. All Rights Reserved.</div>
        <Link>Privacy Statement</Link>
      </div>
    </footer>
    </React.Fragment>
  )
};

export default Footer;