import React from "react";
import {
  TextInput,
  SelectBox,
  Button,
  Calendar,
} from "../../../components/FormElements";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { addReceivedDrugBook } from "../../../app/reducers/drugbookSlice";
import { momentfromString, datefromString } from "../../../utilities/dateUtils";

const AddReceived = (props) => {
  const { onHide, data, isReadOnly } = props;
  const clinicSelector = useSelector((state) => state.userClinic);
  const clinicsSelector = useSelector((state) => state.clinic);
  const authSelector = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  let clinics = clinicsSelector.clinics.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  const getSelectedClinic = (id) => {
    let selected = clinics.filter((f) => f.Id === id);
    if (selected.length > 0) {
      return selected[0];
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productName: data ? data.productName: "",
      manufacturerName: data ? data.ManufacturerName : "",
      medicineName: data ? data.Product : "",
      batchNumber: data ? data.BatchNumber : "",
      clinic: clinicSelector
        ? getSelectedClinic(clinicSelector?.UserClinicId)
        : "",
      dateReceived: data ? data.Date : "",
      // receivedBy: data ? data.Patient : '',
      quantity: data ? data.Qty : "",
    },
  });

  const onSubmit = (data) => {
      dispatch(addReceivedDrugBook({
        ProductName: data.productName,
        BatchNumber: data.batchNumber,
        ClinicId: clinicSelector.UserClinicId,
        ReceivedDate: data.dateReceived
        ? new Date(
            data.dateReceived.getTime() -
              data.dateReceived.getTimezoneOffset() * 60000
          ).toISOString()
        : null,
        Quantity: data.quantity,
        UserId: authSelector.id,
      })
    );
    onHide();
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="add-form">
          <div className="row">
          <div className="col-md-6">
              <Controller
                name="productName"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label="Product Name"
                    controlId="productName"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Product Name is required!",
                  },
                }}
              />
            </div>
            {/* <div className="col-md-6">
              <Controller
                name="manufacturerName"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label="Manufacturer Name"
                    controlId="manufacturerName"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Manufacturer Name is required!",
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="medicineName"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label="Medicine Name"
                    controlId="medicineName"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Medicine Name is required!",
                  },
                }}
              />
            </div> */}
            <div className="col-md-6">
              <Controller
                name="batchNumber"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label="Batch Number"
                    controlId="batchNumber"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Batch Number is required!",
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="dateReceived"
                control={control}
                render={({ field }) => (
                  <Calendar
                    label="Date Received"
                    controlId="dateReceived"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Date Received is required!",
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="quantity"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label="Quantity"
                    controlId="quantity"
                    type="number"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Quantity by is required!",
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="clinic"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    label="Clinic"
                    options={clinics}
                    name="clinic"
                    readOnly={true}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
        {!isReadOnly && (
          <div className="modal-footer">
            <div className="cancel-link" onClick={() => onHide()}>
              Cancel
            </div>
            <Button size="sm">Add Entry</Button>
          </div>
        )}
      </form>
    </React.Fragment>
  );
};

export default AddReceived;
