import React, { useState,useEffect } from 'react';
import Heading from '../../components/Heading/Heading';
import BackButton from '../../components/BackButton/BackButton';
import { TextInput, SelectBox, Calendar, Button } from '../../components/FormElements';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment';

import './Patients.css';
import { states, genderIdentities,birthGenders, pronouns, learnAbout, heardAboutSilk} from "../../constants/index";
import Toast from "../../components/Toast/Toast";
import { addPatient } from "../../app/reducers/patientSlice";
import AutocompleteClinic from '../../components/AutocompleteClinic/AutocompleteClinic';

const AddPatient = () => {

  const dobStartDate = moment(new Date()).subtract(18, 'years');
  const clinicSelector = useSelector((state) => state.clinic);
  const dispatch = useDispatch();
  const [isAdded, setIsAdded] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [referringPatientShow, setReferringPatientShow] = useState(false);
  // let clinics = clinicSelector?.clinics?.map((c) => ({
  //   value: c.Name,
  //   label: c.Name,
  //   Id: c.Id,
  // }));
  const clinicsList = clinicSelector?.clinics?.map(d => d.Name);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm();

  const watchData = watch();

  useEffect(()=> {
    if(watchData?.aboutSilk?.value === "Another Client") {
      setReferringPatientShow(true);
    } else {
      setReferringPatientShow(false);
    }
  },[watchData])

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const onSubmit =  (data) => {
    dispatch(
      addPatient({
        FirstName: data.firstname,
        LastName: data.surname,
        EmailId: data.emailAddress,
        DOB: data.dob,
        MobileNumber: data.mobileNumber,
        EmergencyContactNumber: data.emergencyContactNumber,
        GenderIdentity: data.genderIdentity?.value,
        BirthGender: data.birthGender?.value,
        // Pronouns: data.pronouns?.value,
        Pronoun:'',
        HomeClinic: clinicSelector.clinics.find((clinic) => clinic.Name === data.homeClinic)?.Id.toString() || '',
        Address: data.address,
        State: data.state?.value,
        PostalCode: data.postalCode,
        EmergencyContactName: data.emergencyContactName,
        HeardAboutSilk: data.aboutSilk?.value,
        LearnAbout: JSON.stringify(data.learnAbout?.map(option => option.value)),
        ClinicId:  clinicSelector.clinics.find((clinic) => clinic.Name === data.homeClinic)?.Id.toString() || '',
        })
    );
    //setIsAdded(true);
    //setIsShow(true);
  };
  
  const handleToastClose = () => {
    setIsShow(false);
  }

  return (
    <React.Fragment>
      <div className="heading-section">
        <BackButton title="Back to all Patients" to="/patient" />
        <Heading
          title="Patient"
          subHeading="Add a new patient profile"
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {isShow && (
            <div className="row mb-30">
              <div className="col-md-6">
                <Toast
                  show={isShow}
                  onClose={handleToastClose}
                  variant="success"
                  title={`Successfully added.`}
                />
              </div>
            </div>
          )}
          <div className="section-title">Patient Details</div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                control={control}
                name="firstname"
                render={({ field }) => (
                  <TextInput
                    label="First Name"
                    controlId="firstname"
                    {...field}
                    readOnly={isAdded}
                    error = {errors.firstname?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'First Name is required!',
                  },
                  minLength: {
                    value: 2,
                    message: 'First Name should be at least 2 characters long.',
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="surname"
                render={({ field }) => (
                  <TextInput
                    label="Surname"
                    controlId="surname"
                    {...field}
                    readOnly={isAdded}
                    error = {errors.surname?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Surname is required!',
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="dob"
                render={({ field }) => (
                  <Calendar
                    ref={field.ref}
                    label="Date of Birth"
                    controlId="dob"
                    {...field}
                    onChange={(date) => field.onChange(date)}
                    readOnly={isAdded}
                    openToDate={dobStartDate._d}
                    maxDate={new Date(dobStartDate._d)}
                    error = {errors.dob?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Date of birth is required!',
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="emailAddress"
                render={({ field }) => (
                  <TextInput
                    label="Email Address"
                    controlId="emailAddress"
                    {...field}
                    readOnly={isAdded}
                    error = {errors.emailAddress?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Email Address is required!',
                  },
                  validate: {
                    matchPattern: (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      "Email address must be a valid address",
                  }
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="mobileNumber"
                render={({ field }) => (
                  <TextInput
                    label="Mobile Number"
                    controlId="mobileNumber"
                    {...field}
                    readOnly={isAdded}
                    error = {errors.mobileNumber?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Contact number is required!',
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                  name="homeClinic"
                  control={control}
                  rules={{
                    required: 'Home Clinic is required!',
                  }}
                  render={({ field: { onChange } }) => (
                    <AutocompleteClinic
                      label="Home Clinic"
                      controlId="homeClinic"
                      suggestions={clinicsList}
                      onValueChange={(e) => { onChange(e); }}
                      error={errors.homeClinic?.message}
                      readOnly={isAdded}
                    />
                  )}
                />
            </div>
          </div>
          <div className="section-title mt-10">Extended Details</div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                control={control}
                name="genderIdentity"
                render={({ field }) => (
                  <SelectBox
                    label="Gender Identity"
                    options={genderIdentities}
                    name="genderIdentity"
                    {...field}
                    readOnly={isAdded}
                    error = {errors.genderIdentity?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Gender Identity is required!',
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="birthGender"
                render={({ field }) => (
                  <SelectBox
                    label="Birth Gender"
                    options={birthGenders}
                    name="birthGender"
                    {...field}
                    readOnly={isAdded}
                    error = {errors.birthGender?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Birth Gender is required!',
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <br></br>
            </div>
            <div className="col-md-6">
        
            </div>
            {/* <div className="col-md-4">
              <Controller
                control={control}
                name="pronouns"
                render={({ field }) => (
                  <SelectBox
                    label="Pronouns"
                    options={pronouns}
                    name="pronouns"
                    {...field}
                    error = {errors.pronouns?.message}
                    readOnly={isAdded}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Pronouns are required!',
                  },
                }}
              />
            </div> */}
            <div className="col-md-6">
              <Controller
                control={control}
                name="address"
                render={({ field }) => (
                  <TextInput
                    label="Address"
                    controlId="address"
                    {...field}
                    error = {errors.address?.message}
                    readOnly={isAdded}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Address is required!',
                  },
                }}
              />
            </div>
            <div className="col-md-3">
              <Controller
                control={control}
                name="state"
                render={({ field }) => (
                  <SelectBox
                    label="State"
                    options={states}
                    name="state"
                    {...field}
                    error = {errors.state?.message}
                    readOnly={isAdded}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'State is required!',
                  },
                }}
              />
            </div>
            <div className="col-md-3">
              <Controller
                control={control}
                name="postalCode"
                render={({ field }) => (
                  <TextInput
                    label="Postal Code"
                    controlId="postalCode"
                    type="number"
                    {...field}
                    error = {errors.postalCode?.message}
                    readOnly={isAdded}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Postal code is required!',
                  },
                  validate: {
                    minLength: (v) => v.length >= 4 ||
                    "Postal code must be a valid",
                    maxLength: (v) => v.length <= 4 ||
                    "Postal code must be a valid",
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="emergencyContactName"
                render={({ field }) => (
                  <TextInput
                    label="Emergency Contact Name"
                    controlId="emergencyContactName"
                    {...field}
                    error = {errors.emergencyContactName?.message}
                    readOnly={isAdded}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Emergency contact name is required!',
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="emergencyContactNumber"
                render={({ field }) => (
                  <TextInput
                    label="Emergency Contact Number"
                    controlId="emergencyContactNumber"
                    {...field}
                    error = {errors.emergencyContactNumber?.message}
                    readOnly={isAdded}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Emergency contact number required!',
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="aboutSilk"
                render={({ field }) => (
                  <SelectBox
                    label="How did you hear about Silk?"
                    options={heardAboutSilk}
                    name="aboutSilk"
                    {...field}
                    error = {errors.aboutSilk?.message}
                    readOnly={isAdded}
                  />
                )}
                rules={{
                  required: {
                    value: false,
                    message: 'How did you hear required!',
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="learnAbout"
                render={({ field }) => (
                  <SelectBox
                    isMulti
                    options={learnAbout}
                    label="What would you like to learn about?"
                    controlId="learnAbout"
                    {...field}
                    error = {errors.learnAbout?.message}
                    readOnly={isAdded}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Learn about required!',
                  },
                }}
              />
            </div>
            {referringPatientShow &&
              <div className="col-md-6">
                <Controller
                  control={control}
                  name="nameOfReferringPatient"
                  render={({ field }) => (
                    <TextInput
                      label="Name of referring patient"
                      controlId="nameOfReferringPatient"
                      {...field}
                      readOnly={isAdded}
                    />
                  )}
                />
              </div>
            }
          </div>
          <React.Fragment>
              <hr className="divider mt-20 mb-50" />
              <div className="flex justify-end align-center">
                <Link className="cancel-link" to="/patient">
                  Cancel
                </Link>
                <Button type="submit">
                  Add Patient
                </Button>
              </div>
          </React.Fragment>
        </div>
      </form>
    </React.Fragment>
  );
};

export default AddPatient;