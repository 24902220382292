import React from 'react';
import '../TreatmentPlans.css';
import { useSelector } from 'react-redux';
import { Roles } from '../../../constants';

const ReviewDetails = (props) => {
  const { onEdit, data, isApproved } = props;

  const role = useSelector((state) => state.authentication.role);
  const treatmentPlan = useSelector((state) => state.treatmentPlan);

  const isEditable = () => {
    if (role === Roles.Prescriber) return true;
    else if (
      role === Roles.NurseInjector &&
      treatmentPlan.treatmentStatusId <= 1
    ) {
      return true;
    } else if (
      role === Roles.NursePractitioner &&
      treatmentPlan.treatmentStatusId <= 1
    ) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className='review-head flex'>
        <div className='review-qty'>Qty</div>
        <div className='review-product'>Product</div>
        <div className='review-treatment-area'>Treatment Area</div>

        {isEditable && !!!isApproved && (
          <div className='review-edit-treatment-plan' onClick={onEdit}>
            Edit Treatment Plan
          </div>
        )}
      </div>
      <div>
        {data?.antiWrinkle.length !== 0 && (
          <div className='review-section'>
            <div className='review-sub-heading text-blue'>Anti-Wrinkle</div>
            {data?.antiWrinkle.map((item, index) => (
              <div className='review-item' key={index}>
                <div className='review-qty'>{item?.units}</div>
                <div className='review-product'>{item?.productName}</div>
                <div className='review-treatment-area'>
                  {item?.treatmentAreas?.map((item, index) => (
                    <p key={index}>
                      {item.areaName} (Max Dose: {item.dosage})
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

{data?.filler.length !== 0 && (
  <div className='review-section'>
    <div className='review-sub-heading text-blue'>Filler</div>
    {data?.filler.map((item, index) => (
      <div className='review-item' key={index}>
        <div className='review-product'  style={{marginRight:'120px'}}>
          {item?.products?.map((product, productIndex) => (
            <div className='product-container' key={productIndex}>
              <div className='product-info'>
                <div className='review-qty'>{product?.units}</div>
                <div className='product-name'>
                  {product.productName}
                  {/* (Max Dose: {product.dosage}) */}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='review-treatment-area'>{item?.areaName}</div>
      </div>
    ))}
  </div>
)}



        {data?.other.length !== 0 && (
          <div className='review-section'>
            <div className='review-sub-heading text-blue'>Other</div>
            {data?.other.map((item, index) => (
              <div className='review-item' key={index}>
                <div className='review-qty'>{item?.units}</div>
                <div className='review-product'>{item?.productName}</div>
                <div className='review-treatment-area'>{item?.productDesc}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ReviewDetails;
