import React from 'react';
import { Button } from '../../components/FormElements';

const TreatmentValidation = (props) => {
  const {
    onHide,
    buttonText,
  } = props;

  return (
    <React.Fragment>
      <div>
        <div className='deactivate-text'>
          <p> Please make sure that you have made appropriate selections.</p>
        </div>
      </div>
      <div className='modal-footer'>
        <Button size='sm'  onClick={() => onHide()}>
          Ok
        </Button>
      </div>
    </React.Fragment>
  );
};

export default TreatmentValidation;
