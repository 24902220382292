import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Calendar, TextInput, SelectBox, TextArea } from '../../components/FormElements';

import '../TreatmentPlans/TreatmentPlans.css';
import { ToggleButton } from '../../components/FormElements';
import { useSelector } from 'react-redux';
import { Roles } from '../../constants';
import ToggleIcon  from '../../assets/images/icons/comment-toggle-icon.svg';

const DynamicQuestions = (props) => {
  const authenticationSelector = useSelector((state) => state.authentication);
  const role = authenticationSelector.role;

  const [prescriberCommentsVisibility, setPrescriberCommentsVisibility] = useState({});

  useEffect(() => {
    const moveToggleIcon = (fromChildIndex, toChildIndex) => {
      const toggleIconElement = document.querySelector(`.question-section.question-ischild:nth-child(${fromChildIndex}) .ToggleIcon`);
  
      if (toggleIconElement) {
        const parentElement = toggleIconElement.closest('.question-section.question-ischild');
        const targetParent = document.querySelector(`.question-section.question-ischild:nth-child(${toChildIndex})`);
  
        if (parentElement && targetParent) {
          targetParent.querySelector('.question-item-input').appendChild(toggleIconElement);
        }
      }
    };
  
    moveToggleIcon(10, 9);
    moveToggleIcon(14, 13);
  }, []);
  

  const togglePrescriberComments = (categoryId) => {
    setPrescriberCommentsVisibility((prevVisibility) => ({
      ...prevVisibility,
      [categoryId]: !prevVisibility[categoryId],
    }));
  };

  const {
    fieldMeetsCondition,
    questionsBySequence,
    register,
    control,
    isReadOnly = false,
    errors,
  } = props;

  return (
    <React.Fragment>
      {questionsBySequence
        .filter(fieldMeetsCondition(questionsBySequence))
        .map((item, index) => (
          <div
            className={`question-section ${
              !item.IsParent ? "question-ischild" : ""
            }`}
            key={item.QuestionId}
          >
            {item.Component === "toggleButton" && (
              <React.Fragment>
                <div key={index} className="question-item">
                  <div className="question-answer">
                    <div className="toggleButton-wrapper">
                      {item.Options.map((option, index) => (
                        <React.Fragment key={index}>
                          <ToggleButton
                            {...register(item.GUID, {
                              required: "The field is required",
                            })}
                            type="radio"
                            value={option.Value}
                            name={item.GUID}
                            id={item.GUID + index}
                            label={option.Label}
                            disabled={isReadOnly}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    {item.GUID in errors && errors[item.GUID]?.message && (
                      <div className="error">{errors[item.GUID]?.message}</div>
                    )}
                  </div>
                  <div className="question-title">{item.Title}</div>
                </div>
              </React.Fragment>
            )}
            {item.Component === "radioButton" && (
              <React.Fragment>
                <div key={index} className="question-item">
                  <div className="question-radio-item">
                    <div className="question-title">{item.Title}</div>
                    <div className="toggleButton-wrapper">
                      {item.Options.map((option, index) => (
                        <React.Fragment key={index}>
                          <ToggleButton
                            {...register(item.GUID, {
                              required: "The field is required",
                            })}
                            type="radio"
                            value={option.Value}
                            name={item.GUID}
                            id={item.GUID + index}
                            label={option.Label}
                            subLabel={option.SubLable}
                            disabled={isReadOnly}
                            className={"radio-toggle"}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    {item.GUID in errors && errors[item.GUID]?.message && (
                      <div className="error">{errors[item.GUID]?.message}</div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
            {item.Component === "checkBox" && (
              <React.Fragment>
                <div key={index} className="question-item">
                  <div className="question-radio-item">
                    <div className="question-title">{item.Title}</div>
                    <div className="toggleButton-wrapper">
                      {item.Options.map((option, index) => (
                        <React.Fragment key={index}>
                          <ToggleButton
                            {...register(item.GUID, {
                              required: "The field is required",
                            })}
                            type="checkbox"
                            value={option.Value}
                            name={item.GUID}
                            id={item.GUID + index}
                            label={option.Label}
                            subLabel={option.SubLable}
                            disabled={isReadOnly}
                            className={"radio-toggle"}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    {item.GUID in errors && errors[item.GUID]?.message && (
                      <div className="error">{errors[item.GUID]?.message}</div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}

            {item.Component === "textBox" && (
              <div className="question-item-input">
                {item.Options.map((option, index) => (
                  <React.Fragment key={index}>
                    <div>
                      {item.OptionId !== "PrescriberComments" && (
                        <TextInput
                          {...register(option.GUID, {
                            required: "The field is required",
                          })}
                          label={option.Label}
                          controlId={option.GUID}
                          addonType={option.IsReadOnly}
                          disabled={isReadOnly}
                          error={
                            option.GUID in errors &&
                            errors[option.GUID]?.message &&
                            errors[option.GUID]?.message
                          }
                        />
                      )}
                    </div>
                    {item.Checkbox && (role === Roles.Prescriber || role === Roles.NursePractitioner) && (
                      <div>
                        <img
                          className="ToggleIcon"
                          src={ToggleIcon}
                          alt="Toggle Icon"
                          style={{
                            opacity: prescriberCommentsVisibility[item.Category]
                              ? item.Checkbox.OpacityChecked
                              : item.Checkbox.OpacityUnchecked,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            togglePrescriberComments(item.Category)
                          }
                        />
                      </div>
                    )}
                    {item.Component === "textBox" &&
                      item.OptionId === "PrescriberComments" &&
                      prescriberCommentsVisibility[item.Category] &&
                      (role === Roles.Prescriber || role === Roles.NursePractitioner) && (
                        <div>
                          {item.Options.map((option, index) => (
                            <div key={index}>
                              <TextInput
                                {...register(option.GUID, {
                                  required: "The field is required",
                                })}
                                label={option.Label}
                                controlId={option.GUID}
                                disabled={false}
                                error={
                                  option.GUID in errors &&
                                  errors[option.GUID]?.message &&
                                  errors[option.GUID]?.message
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                  </React.Fragment>
                ))}
              </div>
            )}

            {item.Component === "calendar" && (
              <div className="question-item-input">
                {item.Options.map((option, index) => (
                  <div key={index}>
                    <Controller
                      name={option.GUID}
                      control={control}
                      render={({ field }) => (
                        <Calendar
                          label={option.Label}
                          controlId={option.GUID}
                          disabled={isReadOnly}
                          error={
                            option.GUID in errors &&
                            errors[option.GUID]?.message &&
                            errors[option.GUID]?.message
                          }
                          {...field}
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: "The field is required",
                        },
                      }}
                    />
                  </div>
                ))}
              </div>
            )}

            {item.Component === "textArea" && (
              <React.Fragment>
                <div className="question-item-input">
                  {item.Options.map((option, index) => (
                    <div key={index}>
                      <TextArea
                        {...register(option.GUID)}
                        label={option.Label}
                        controlId={option.GUID}
                        disabled={isReadOnly}
                        error={
                          option.GUID in errors &&
                          errors[option.GUID]?.message &&
                          errors[option.GUID]?.message
                        }
                      />
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}

            {item.Component === "selectBox" && (
              <React.Fragment>
                <Controller
                  name={item.GUID}
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      label={item.Title}
                      options={item.Options.map((options) => {
                        options["label"] = options?.Label;
                        options["value"] = options?.Value;
                        return options;
                      })}
                      readOnly={isReadOnly}
                      error={
                        item.GUID in errors &&
                        errors[item.GUID]?.message &&
                        errors[item.GUID]?.message
                      }
                      {...field}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "The field is required",
                    },
                  }}
                />
              </React.Fragment>
            )}
          </div>
        ))}
    </React.Fragment>
  );
};

export default DynamicQuestions;
