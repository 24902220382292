import { useEffect, useState } from 'react';
import { Checkbox, TextInput } from '../../../components/FormElements';
import { Controller } from "react-hook-form";
import axios from "axios";
import { envEndPoints } from '../../../app/config';
import store from '../../../app/store';

import '../TreatmentPlans.css';


const FillerProducts = (props) => {
  const { productindex, control, index, remove, productDetails, watch, products } = props;

  const [autoSuggestProduct, setAutoSuggestProduct] = useState(null);
  
  const watchData = watch(`filler[${index}].products[${productindex}].productName`);

  let productFindBaseUrl = `${envEndPoints().baseUrl}/product/find`;
  const accessToken = store.getState().authentication.accessToken;

  const changeAutoSelectedProduct = (e) => {        
    if (e !== undefined && e !== '') {      
      let productFindUrl =  `${productFindBaseUrl}/${e}`;        
      axios.get(productFindUrl,{ headers : { 'Content-type': 'application/json', Authorization: `bearer ${accessToken}` } }).then((response) => {                          
        setAutoSuggestProduct(response.data);         
      });
    } else {
      setAutoSuggestProduct(null); 
    }
  }

  let selectedProduct = products.find(e => {
    if (watchData !== '') {
      return e.Name === watchData;
    } else {
      return null;
    }
  });

  useEffect(() => {
    changeAutoSelectedProduct(watchData);
  },[]);

  return (
    <div className="row">
      <div className="col-md-6 mb-20">
        <Checkbox
          label={autoSuggestProduct?.Name}
          unitLable="Max Dose:"
          unit={autoSuggestProduct?.Dosage}
          checked={true}
          onChange={(e) => console.log(e)}
        />

        <Controller
          name={`filler[${index}].products[${productindex}].productName`}
          control={control}
          render={({ field }) => (
            <input type="hidden" {...field} />
          )}
        />
      </div>
      <div className="col-md-3">
        <Controller
          name={`filler[${index}].products[${productindex}].units`}
          control={control}
          render={({ field }) => (
            <TextInput
              label="Specify MLs"
              controlId={`products[${productindex}]`}
              {...field}
            />
          )}
        />

      </div>
      <div className="col-md-3">
        <div className="remove-product-link" onClick={() => remove(productindex)}>Remove this product</div>
      </div>
    </div>
  );
};

export default FillerProducts;
