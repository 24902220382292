import React, { useState, useEffect } from 'react';

import './TreatmentPlans.css';
import Heading from '../../components/Heading/Heading';
import DeclinedIcon from '../../assets/images/icons/declined-icon.svg';
import ApprovedIcon from '../../assets/images/icons/approved-icon.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Complete = () => {
  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);
  const statusId = treatmentPlanSelector?.treatmentStatusId;
  const isApproved = statusId === 5;
  const [administeredModalShow, setAdministeredModalShow] = useState(false);

  // const handleAdministered = () => {
  //   setAdministeredModalShow(true);
  // };
  // const handleAdministeredModalClose = () => setAdministeredModalShow(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  
  return (
    <React.Fragment>
      <Heading title="Treatment Plan" />
     
        <div className="outcome-wrapper">
          <img src={ApprovedIcon} />
          <div className="outcome-text">
            <strong>Appointment completed.</strong>
            <p>
              You can continue with next appoinment. Navigate to appointment dashboard by
              clicking{" "}
              <u>
                <button className="popup-link" onClick={() => navigate('/appointments')}>
                  {" "}
                  here
                </button>
              </u>
            </p>
          </div>
        </div>
     
    </React.Fragment>
  );
};


export default Complete;
