import React from 'react';
import { Link } from "react-router-dom";
import './BackButton.css';

const BackButton = (props) => {
  const { title, ...otherProps } = props;

  return (
    <div className="backbutton-wrapper">
      <Link className={"back-btn"} {...otherProps}>
        <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.87109 5.65625V7.27344C4.87109 7.64844 4.69531 7.90625 4.34375 8.04688C3.99219 8.1875 3.6875 8.12891 3.42969 7.87109L0.40625 4.84766C0.242188 4.68359 0.160156 4.48438 0.160156 4.25C0.160156 4.01562 0.242188 3.81641 0.40625 3.65234L3.42969 0.628906C3.6875 0.371094 3.99219 0.3125 4.34375 0.453125C4.69531 0.59375 4.87109 0.851562 4.87109 1.22656V2.84375H15.4883C15.7695 2.84375 15.9102 2.98438 15.9102 3.26562V5.23438C15.9102 5.51562 15.7695 5.65625 15.4883 5.65625H4.87109Z" fill="#979797" />
        </svg>
        {title}
      </Link>
    </div>
  )
};

export default BackButton;