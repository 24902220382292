import React from 'react';
import './NoData.css';

const NoData = (props) => {
  const { icon, title, caption, className="", variant="pink" } = props;
  return (
    <div className={`nodata-wrapper ${variant} ${className}`}>
      <img src={icon} alt='icon' />
      <div className="nodata-title">{title}</div>
      {caption ? <div className="nodata-caption">{caption}</div> : null}
    </div>
  )
};

export default NoData;