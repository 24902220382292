import React, { useState } from 'react';
import {
  TextInput,
  SelectBox,
  Button,
} from '../../../../components/FormElements';
import { useForm, Controller } from 'react-hook-form';
import './Users.css';
import { useSelector, useDispatch } from 'react-redux';
import { addUser } from '../../../../app/reducers/userSlice';
import AutocompleteClinic from '../../../../components/AutocompleteClinic/AutocompleteClinic';

const AddUser = ({ onHide }) => {
  const role = [
    // { value: 'receptionist', label: 'Receptionist', Id: 1 },
    // { value: 'injector', label: 'Nurse Injector', Id: 2 },
    // { value: 'practitioners', label: 'Nurse Practitioners', Id: 3 },
    // { value: 'prescribers', label: 'Prescribers', Id: 4 },
    // { value: 'administrator', label: 'System Administrator', Id: 5 },
    //
    { value: 'Admin', label: 'Admin', Id: 1 },
    { value: 'Prescriber', label: 'Prescriber', Id: 2 },
    { value: 'User', label: 'User', Id: 5 },
    { value: 'NurseInjector', label: 'NurseInjector', Id: 6 },
    { value: 'NursePractitioner', label: 'NursePractitioner', Id: 7 },
    { value: 'Reception', label: 'Reception', Id: 8 },
  ];

  const clinicSelector = useSelector((state) => state.clinic);
  const authenticationSelector = useSelector((state) => state.authentication);

  const dispatch = useDispatch();
  const [roleId, setRoleId] = useState(0);
  const [homeClinicId, setHomeClinicId] = useState(0);
  const [otherClinicId, setOtherClinicId] = useState(0);

  let clinics = clinicSelector.clinics.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      FirstName: '',
      Surname: '',
      EmailId: '',
      HomeClinic: 0,
      OtherClinic: 0,
      Role: 0,
      PlaceOfPractice: '',
      PrescriberNumber: '',
      ContactNumber: '',
      AreaOfEndorsement: ''
    },
  });

  const onRoleChange = (e) => {    
    setRoleId(e.Id);
  };

  const onSubmit = (data) => {    
    dispatch(
      addUser({
        FirstName: data.FirstName,
        LastName: data.Surname,
        RoleId: roleId,
        ActiveClinicId: otherClinicId,
        HomeClinicId: homeClinicId,
        EmailId: data.EmailId,
        PlaceOfPractice: data.PlaceOfPractice,
        PrescriberNumber: data.PrescriberNumber,
        ContactNumber: data.ContactNumber,
        AreaOfEndorsement: data.AreaOfEndorsement,
        loginUserId: authenticationSelector.id
      })
    );
    onHide();
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='add-user-form'>
          <div className='row'>
            <div className='col-md-6'>
              <Controller
                name='FirstName'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='First Name'
                    controlId='FirstName'
                    error={errors.FirstName && errors.FirstName.message}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'First Name is required!',
                  },
                }}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='Surname'
                control={control}
                render={({ field }) => (
                  <TextInput 
                    label='Surname' 
                    controlId='Surname' 
                    error={errors.Surname && errors.Surname.message}
                    {...field}
                    />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Surname is required!',
                  },
                }}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='EmailId'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Email Address'
                    controlId='EmailId'
                    error={errors.EmailId && errors.EmailId.message}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Email address is required!',
                  },
                  validate: {
                    matchPattern: (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      'Email address must be a valid address',
                  },
                }}
              />
            </div>
            <div className='col-md-6'>
            <Controller
              name='HomeClinic'
              control={control}
              rules={{
                required: 'Home Clinic is required!',
              }}
              render={({ field }) => (
                <>
                  <AutocompleteClinic
                    label='Home Clinic'
                    controlId='HomeClinic'
                    onValueChange={(e, i) => {
                      field.onChange(e);
                      setHomeClinicId(i);
                    }}
                    error={errors.HomeClinic && errors.HomeClinic.message}
                  />
                </>
              )}
            />
          </div>
            <div className='col-md-6'>
              <Controller
                name='OtherClinic'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteClinic
                    label='Other Clinic'
                    controlId='OtherClinic'
                    onValueChange={(e,i) => {
                      onChange(e);
                      setOtherClinicId(i);
                    }}
                  />
                )}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='Role'
                control={control}
                render={({ field }) => (
                  <SelectBox
                    label='System Role'
                    options={role}
                    name='Role'
                    onChange={(e) => onRoleChange(e)}
                  />
                )}
              />
            </div>
            {roleId === 2 && (
              <React.Fragment>
                <div className='col-md-6'>
                  <Controller
                    name='PlaceOfPractice'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Place Of Practice' 
                        controlId='PlaceOfPractice' 
                        error={errors.PlaceOfPractice && errors.PlaceOfPractice.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Place Of Practice is required!',
                      },
                    }}
                  />
                </div>
                <div className='col-md-6'>
                  <Controller
                    name='PrescriberNumber'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Prescriber Number' 
                        controlId='PrescriberNumber' 
                        error={errors.PrescriberNumber && errors.PrescriberNumber.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Prescriber Number is required!',
                      },
                    }}
                  />
                </div>
                <div className='col-md-6'>
                  <Controller
                    name='ContactNumber'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Contact Number' 
                        controlId='ContactNumber' 
                        error={errors.ContactNumber && errors.ContactNumber.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Contact Number is required!',
                      },
                    }}
                  />
                </div>
              </React.Fragment>
            )}
            {roleId === 7 && (
              <React.Fragment>
                <div className='col-md-6'>
                  <Controller
                    name='PrescriberNumber'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Prescriber Number' 
                        controlId='PrescriberNumber' 
                        error={errors.PrescriberNumber && errors.PrescriberNumber.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Prescriber Number is required!',
                      },
                    }}
                  />
                </div>
                <div className='col-md-6'>
                  <Controller
                    name='AreaOfEndorsement'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Area Of Endorsement' 
                        controlId='AreaOfEndorsement' 
                        error={errors.AreaOfEndorsement && errors.AreaOfEndorsement.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Area Of Endorsement is required!',
                      },
                    }}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className='modal-footer'>
          <div className='cancel-link' onClick={() => onHide()}>
            Cancel
          </div>
          <Button size='sm'>Add User</Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default AddUser;
