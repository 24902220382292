import React, { useEffect } from 'react';
import Heading from '../../components/Heading/Heading';
import { useSelector } from 'react-redux';
import AntiWrinkleConsent from './AntiWrinkleConsent';
import FillersConsent from './FillersConsent';
import PlateletConsent from './PlateletConsent';
import PdoThreadsConsent from './PdoThreadsConsent';
import CommentSignatureLayout from './CommentSignatureLayout';
import { packageTypes } from '../../constants/index';

const PatientConsent = () => {
  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const isValidState = () => {
    if (
      (!!treatmentPlanSelector.treatmentInfo &&
        treatmentPlanSelector.treatmentInfo !== '' &&
        treatmentPlanSelector.treatmentInfo.packageType ===
          packageTypes.injectibles &&
        treatmentPlanSelector.treatmentStatusId === 4) ||
      treatmentPlanSelector.treatmentInfo.packageType === packageTypes.prp ||
      treatmentPlanSelector.treatmentInfo.packageType === packageTypes.threads
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isAntiWrinkeSelected = () => {
    return (
      treatmentPlanSelector.treatmentInfo.packageType ===
        packageTypes.injectibles &&
      treatmentPlanSelector.treatmentInfo.antiwrinkle[0].productName !== ''
    );
  };

  const isFillerSelected = () => {
    return (
      treatmentPlanSelector.treatmentInfo.packageType ===
        packageTypes.injectibles &&
      treatmentPlanSelector.treatmentInfo.filler[0].areaName !== ''
    );
  };

  return (
    !!treatmentPlanSelector && (
    <React.Fragment>
      <Heading
        title='Treatment Plan'
        subHeading={`Consent to the new treatment plan for ${treatmentPlanSelector.patientName}`}
      />
      {( treatmentPlanSelector.treatmentInfo.packageType ===
        packageTypes.injectibles &&
      treatmentPlanSelector.treatmentInfo.antiWrinkle[0].productName !== '') && <AntiWrinkleConsent></AntiWrinkleConsent>}
      {(treatmentPlanSelector.treatmentInfo.packageType ===
        packageTypes.injectibles &&
      treatmentPlanSelector.treatmentInfo.filler[0].areaName !== '') && <FillersConsent></FillersConsent>}
      {treatmentPlanSelector.treatmentInfo.packageType === packageTypes.prp && (
        <PlateletConsent></PlateletConsent>
      )}
      {treatmentPlanSelector.treatmentInfo.packageType ===
        packageTypes.threads && <PdoThreadsConsent></PdoThreadsConsent>}
      {isValidState ? <CommentSignatureLayout></CommentSignatureLayout> : ''}
    </React.Fragment>
    )
  );
};

export default PatientConsent;
