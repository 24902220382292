import React from 'react';
import './AppointmentCheckbox.css';

const AppointmentCheckbox = React.forwardRef((props, ref) => {
  const { label, id, type, defaultChecked, ...otherProps } = props;

  return (
    <div className='app-checkbox-wrapper'>
      <input
        ref={ref}
        type={type}
        id={id}
        defaultChecked={defaultChecked}
        {...otherProps}
      />
      <label htmlFor={id} className='app-label'>{label}</label>
    </div>
  )
});

export default AppointmentCheckbox;