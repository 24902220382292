import React, { useEffect, useState } from 'react';
import '../VideoConsult.css';

const Countdown = (props) => {
  const { queuesecond, queueminutes } = props;
  const [time, setTime] = useState(Math.floor((queueminutes * 60) + parseInt(queuesecond)));

useEffect(() => {
  setTimeout(() => { 
    if(time !== 0) {
      setTime(time - 1)
    } 
  }, 1000);
}, [time]);

const getFormattedTime = (time) => {
  let total_seconds = parseInt(Math.floor(time));
  let total_minutes = parseInt(Math.floor(total_seconds / 60));
  
  let second = parseInt(total_seconds % 60);
  let minutes = parseInt(total_minutes);
  
  return (minutes).toString().padStart(2, '0') + ":" + (second).toString().padStart(2, '0');
}

  return (
    <React.Fragment>
      <div className="queue-timer">{getFormattedTime(time)}</div>
    </React.Fragment>
  );
};

export default Countdown;

