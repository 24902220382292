import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function App(props) {
  return (
    <React.Fragment>
      <Header {...props} />
      <div className='main'>
        <Outlet />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default App;
