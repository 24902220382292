import { call, put } from 'redux-saga/effects';
import { getClinics, deleteClinic, updateClinic } from '../api/clinicsApi';
import { getClinicsSuccess } from '../reducers/clinicsSlice';
import { setLoader, resetLoader } from '../reducers/loaderSlice';

export function* getClinicsHandler(action: any): Generator<any, any, any> {
  const { reqData, type, history } = action.payload;

  const { response } = yield call(getClinics);
  if (response) {
    yield put(getClinicsSuccess(response.data));
  }
}

export function* deleteClinicHandler(action: any): Generator<any, any, any> {
  const { id, history } = action.payload;
  yield put(setLoader());
  const deleteResponse = yield call(deleteClinic, { id: id });
  if (deleteResponse) {
    const { response } = yield call(getClinics);
    if (response) {
      yield put(getClinicsSuccess(response.data));
      yield put(resetLoader());
    }
  }
}

export function* updateClinicHandler(action: any): Generator<any, any, any> {
  const {} = action.payload;
  yield put(setLoader());
  const updateResponse = yield call(updateClinic, action.payload);
  if (updateResponse) {
    const { response } = yield call(getClinics);
    if (response) {
      yield put(getClinicsSuccess(response.data));
      yield put(resetLoader());
    }
  }
}
