import React from 'react';
import { TextInput, Button } from '../FormElements';
import { useForm } from 'react-hook-form';
import './Deactivate.css';

const Deactivate = (props) => {
  const {
    onHide,
    message,
    buttonText,
    data,
    handleDelete,
    phrase = 'DEACTIVATE',
  } = props;

  const deleteAction = () => {
    props.handleDelete(props.data[0].Id);
  };

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    props.handleDelete(props.data[0].Id);
  };
  return (
    <React.Fragment>
      <div>
        <div className='deactivate-text'>
          <p>{message}</p>
          <p>
            If you are sure, please type “{phrase}” in the field below to
            confirm your action.
          </p>
        </div>
        <TextInput
          label='Confirm phrase'
          controlId='confirm'
          error={errors.confirm && true}
          {...register("confirm", {
            required: true,
            validate: (value) => value === phrase
          })}
        />
      </div>
      <div className='modal-footer'>
        <div className='cancel-link' onClick={() => onHide()}>
          Cancel
        </div>
        <Button size='sm' onClick={handleSubmit(onSubmit)}>
          {buttonText}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Deactivate;
