import React, {useEffect} from 'react';
import Heading from '../../components/Heading/Heading';
import { useForm } from "react-hook-form";

import './AdverseEvent.css';
import AdverseEventForm from './components/AdverseEventForm';
import BackButton from '../../components/BackButton/BackButton';
import { useSelector } from 'react-redux';
import { treatmentAreas } from '../../constants';

const ViewAdverseEvent = () => {
  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const adverseEventDetailsSelector = useSelector((state) => state.adverseEvents.AdverseEventDetails);
  const clinicsSelector = useSelector((state) => state.clinic)

  let clinics = clinicsSelector?.clinics?.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  const getSelectedClinic = (id) => {
  let selected = clinics?.filter((f) => f.Id === id);
    if(selected.length > 0) {
    //setHomeClinicId(selected[0].Id);
    //setHomeClinicName(selected[0].value);
    return selected[0].value;
    }
    else {
      return '';
    }
  };
  
const effectedAreas = JSON.parse(adverseEventDetailsSelector?.EffectedAreas || '{}');

const defaultCheckboxValues = {};
treatmentAreas[0].Areas.forEach((item) => {
  defaultCheckboxValues[item.Code] = effectedAreas[item.Code] || false;
});

  const { control, register, formState: { errors } } = useForm({
    defaultValues: {
        appointment: adverseEventDetailsSelector?.Appointment ?? '',
        clinic: getSelectedClinic(adverseEventDetailsSelector?.ClinicId),
        patientFullName: adverseEventDetailsSelector.PatientName,
        patientDob: adverseEventDetailsSelector.StrDOB,
        currentMedication: adverseEventDetailsSelector?.CurrentMedication ?? 'N/A',
        currentMedicationConditions: adverseEventDetailsSelector?.CurrentMedicalConditions ?? 'N/A',
        treatmentDate: adverseEventDetailsSelector.StrTreatmentDate,
        treatmentPlanId: adverseEventDetailsSelector?.TreatmentPlanId,
        drugEntry: adverseEventDetailsSelector?.DrugEntry ?? 'N/A',
        eventAfterwards: adverseEventDetailsSelector?.EventDescription ?? 'N/A',
        currentSituation: adverseEventDetailsSelector?.CurrentSituation ?? 'N/A',
        beenUnwell: adverseEventDetailsSelector.PretreatemnetIsunwell === 1? 'yes' :'no',
        newMedication:adverseEventDetailsSelector.PretreatemnetNewMedication === 1? 'yes' :'no',
        newSkinCare:adverseEventDetailsSelector.PretreatemnetNewSkincare === 1? 'yes' :'no',
        furtherInformation: adverseEventDetailsSelector.PretreatemnetAdditionalInformation ?? 'N/A',
        exercise: adverseEventDetailsSelector.PostreatemnetAnyExercise  === 1? 'yes' :'no',
        microdermabrasion: adverseEventDetailsSelector.PostreatemnetSkinTreatment === 1? 'yes' :'no',
        cosmeceuticals: adverseEventDetailsSelector.PostreatemnetAnyCosmeceuticals === 1? 'yes' :'no',
        postTreatmentDetails: adverseEventDetailsSelector.PostreatemnetAdditionalInformation ?? 'N/A',
        ...defaultCheckboxValues
    }
  });

  return (
    <React.Fragment>
      <div className="heading-section">
        <BackButton title="Back to all Adverse Events" to="/adverseevent" />

        <Heading
          title="Adverse Event"
          subHeading= {`${adverseEventDetailsSelector.PatientName} | ${adverseEventDetailsSelector.StrTreatmentDate}`}
        />
      </div>
      
      <form>
        <div>
          <AdverseEventForm control={control} register={register} isReadOnly={true} />
        </div>
      </form>

    </React.Fragment>
  );
};

export default ViewAdverseEvent;

