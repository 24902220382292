import React from 'react';
import './Pagination.css';

const Pagination = (props) => {
  const {
    onPageChange,
    totalRecords=1,
    currentPageIndex,
    pageSize=20
  } = props;

  if (currentPageIndex === 0 || totalRecords <= pageSize) {
    return null;
  }

  let totalPages = Math.ceil(totalRecords / pageSize);

  const onNext = () => {
    onPageChange(currentPageIndex + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPageIndex - 1);
  };

  return (
    <ul className="pagination">
        <li onClick={onPrevious} className={`page-btn page-btn-prev ${currentPageIndex === 1 && "disabled"}`}></li>
        <li className="page-number active">{currentPageIndex}</li>
        <li className="brdr-0">of</li>
        <li className="page-number">{totalPages}</li>
        <li onClick={onNext} className={`page-btn page-btn-next ${currentPageIndex === totalPages && "disabled"}`}></li>
    </ul>
  )
};

export default Pagination;