import React, { useState, useEffect } from "react";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import Heading from "../../components/Heading/Heading";
import { useSelector, useDispatch } from "react-redux";
import BackButton from "../../components/BackButton/BackButton";
import {
  TextInput,
  SelectBox,
  Calendar,
  Button,
} from "../../components/FormElements";
import { Link } from "react-router-dom";

import "./Patients.css";
import Toast from "../../components/Toast/Toast";
import { updatePatient } from "../../app/reducers/patientSlice";
import { states, genderIdentities,birthGenders, pronouns, heardAboutSilk, learnAbout} from "../../constants/index";
import { datefromString } from "../../utilities/dateUtils";

const UpdatePatient = () => {
  const patientDetailsSelector = useSelector((state) => state.patient.patientDetails);
  const clinicSelector = useSelector((state) => state.clinic);
  const [referringPatientShow, setReferringPatientShow] = useState(false);
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isShow, setIsShow] = useState(false);
  let clinics = clinicSelector.clinics.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);
  
  const getSelectedClinicName = (id) => {
    let selected = clinics.filter((f) => f.Id === parseInt(id));
    if (selected.length > 0) {
      return selected[0];
    } else {
      return clinics[0];
    }
  };
  
  const getSelectedOptionLabel = (options, value) => {
    let selected = options.filter((f) => f.value === value);
    if (selected.length > 0) {
      return selected[0];
    } else {
      return '';
    }
  };
  const getMultiSelectedOptionLabels = (options, value) => {
    const selected = options.filter((f) => value.includes(f.value));
    if (selected.length > 0) {
      return selected;
    } else {
      return '';
    }
  };
  
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({defaultValues: {
    firstname: patientDetailsSelector.FirstName,
    surname: patientDetailsSelector.LastName,
    emailAddress: patientDetailsSelector.EmailId,
    dob: datefromString(patientDetailsSelector.DOB),
    mobileNumber: patientDetailsSelector.MobileNumber,
    emergencyContactNumber: patientDetailsSelector.EmergencyContactNumber,
    homeClinic: getSelectedClinicName(patientDetailsSelector.HomeClinic),
    genderIdentity: getSelectedOptionLabel(genderIdentities, patientDetailsSelector.GenderIdentity),
    birthGender: getSelectedOptionLabel(birthGenders, patientDetailsSelector.BirthGender),
    pronouns: getSelectedOptionLabel(pronouns, patientDetailsSelector.Pronouns),
    address: patientDetailsSelector.Address,
    state: getSelectedOptionLabel(states, patientDetailsSelector.State),
    postalCode: patientDetailsSelector.PostalCode,
    emergencyContactName: patientDetailsSelector.EmergencyContactName,
    aboutSilk: getSelectedOptionLabel(heardAboutSilk, patientDetailsSelector.HeardAboutSilk),
    learnAbout: getMultiSelectedOptionLabels(learnAbout, patientDetailsSelector.LearnAbout)|| [],
  }
  });

  const watchData = watch();

    
  useEffect(()=> {
    if(watchData?.aboutSilk?.value === "Another Client") {
      setReferringPatientShow(true);
    } else {
      setReferringPatientShow(false);
    }
  },[watchData])

  const onSubmit =  (data) => {
    dispatch(
      updatePatient({
        FirstName: data.firstname,
        LastName: data.surname,
        EmailId: data.emailAddress,
        dob : data.dob ?moment.utc(data.dob, 'DD-MM-YYYY').add(1, 'days').toISOString(): null,
        MobileNumber: data.mobileNumber,
        EmergencyContactNumber: data.emergencyContactNumber,
        GenderIdentity: data.genderIdentity?.value,
        BirthGender: data.birthGender?.value,
        // Pronouns: data.pronouns?.value,
        Pronouns: '',
        HomeClinic: data.homeClinic?.Id?.toString(),
        Address: data.address,
        State: data.state?.value,
        PostalCode: data.postalCode,
        EmergencyContactName: data.emergencyContactName,
        HeardAboutSilk: data?.aboutSilk?.value,
        LearnAbout: JSON.stringify(data?.learnAbout?.map(option => option.value)),
        Id: patientDetailsSelector.Id
        })
    );
    //setIsSuccess(true);
    //setIsShow(true);
  };
  
  const handleToastClose = () => setIsShow(false);

  return (
    <React.Fragment>
      <div className="heading-section">
        <BackButton title="Back to all Patients" to="/patient" />
        <Heading
          title="Patient"
          subHeading={`${patientDetailsSelector.Name} | ${new Date(
            patientDetailsSelector.DOB
          ).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}`}
        />
        {isSuccess && (
          <div className="header-btn-wrapper">
            <Button
              variant="secondary"
              onClick={() => {
                setIsSuccess(false);
                setIsShow(false);
              }}
            >
              Edit
            </Button>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {isShow && (
            <div className="row mb-30">
              <div className="col-md-6">
                <Toast
                  show={isShow}
                  onClose={handleToastClose}
                  variant="success"
                  title={`You have successfully updated ${patientDetailsSelector.Name}`}
                />
              </div>
            </div>
          )}
          <div className="section-title">Patient Details</div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                control={control}
                name="firstname"
                render={({ field }) => (
                  <TextInput
                    label="First Name"
                    controlId="firstname"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="surname"
                render={({ field }) => (
                  <TextInput
                    label="Surname"
                    controlId="surname"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="dob"
                render={({ field }) => (
                  <Calendar
                    ref={field.ref}
                    label="Date of Birth"
                    controlId="dob"
                    {...field}
                    onChange={(date) => field.onChange(date)}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="emailAddress"
                render={({ field }) => (
                  <TextInput
                    label="Email Address"
                    controlId="emailAddress"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="mobileNumber"
                render={({ field }) => (
                  <TextInput
                    label="Mobile Number"
                    controlId="mobileNumber"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="homeClinic"
                render={({ field }) => (
                  <SelectBox
                    label="Home Clinic"
                    options={clinics}
                    name="homeClinic"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
          </div>
          <div className="section-title mt-10">Extended Details</div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                control={control}
                name="genderIdentity"
                render={({ field }) => (
                  <SelectBox
                    label="Gender Identity"
                    options={genderIdentities}
                    name="genderIdentity"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="birthGender"
                render={({ field }) => (
                  <SelectBox
                    label="Birth Gender"
                    options={birthGenders}
                    name="birthGender"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            {/* <div className="col-md-4">
              <Controller
                control={control}
                name="pronouns"
                render={({ field }) => (
                  <SelectBox
                    label="Pronouns"
                    options={pronouns}
                    name="pronouns"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div> */}
             <div className="col-md-6">
              <br></br>
            </div>
            <div className="col-md-6">
        
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="address"
                render={({ field }) => (
                  <TextInput
                    label="Address"
                    controlId="address"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-3">
              <Controller
                control={control}
                name="state"
                render={({ field }) => (
                  <SelectBox
                    label="State"
                    options={states}
                    name="state"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-3">
              <Controller
                control={control}
                name="postalCode"
                render={({ field }) => (
                  <TextInput
                    label="Postal Code"
                    controlId="postalCode"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="emergencyContactName"
                render={({ field }) => (
                  <TextInput
                    label="Emergency Contact Name"
                    controlId="emergencyContactName"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="emergencyContactNumber"
                render={({ field }) => (
                  <TextInput
                    label="Emergency Contact Number"
                    controlId="emergencyContactNumber"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                control={control}
                name="aboutSilk"
                render={({ field }) => (
                  <SelectBox
                    label="How did you hear about Silk?"
                    options={heardAboutSilk}
                    name="aboutSilk"
                    {...field}
                    error = {errors.aboutSilk?.message}
                    readOnly={isSuccess}
                  />
                )}
                rules={{
                  required: {
                    value: false,
                    message: 'How did you hear required!',
                  },
                }}
              />
            </div>
            
            <div className="col-md-6">
              <Controller
                control={control}
                name="learnAbout"
                render={({ field }) => (
                  <SelectBox
                    isMulti
                    options={learnAbout}
                    label="What would you like to learn about?"
                    controlId="learnAbout"
                    {...field}
                    readOnly={isSuccess}
                  />
                )}
              />
            </div>
            {referringPatientShow &&
              <div className="col-md-6">
                <Controller
                  control={control}
                  name="nameOfReferringPatient"
                  render={({ field }) => (
                    <TextInput
                      label="Name of referring patient"
                      controlId="nameOfReferringPatient"
                      {...field}
                      readOnly={isSuccess}
                    />
                  )}
                />
              </div>
            }
          </div>
          {!isSuccess && (
          <React.Fragment>
              <hr className="divider mt-20 mb-50" />
              <div className="flex justify-end align-center">
                <Link className="cancel-link" to="/patient">
                  Cancel
                </Link>
                <Button type="submit">
                  Update Patient
                </Button>
              </div>
          </React.Fragment>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export default UpdatePatient;
