import React from 'react';
import './ToggleButton.css';

const ToggleButton = React.forwardRef((props, ref) => {
  const { label, id, type, DefaultValue, subLabel, className="", ...otherProps } = props;

  return (
    <div className={`toggleButton-item ${className}`}>
      <input
        ref={ref}
        type={type}
        id={id}
        defaultChecked={DefaultValue}
        {...otherProps}
      />
      <label htmlFor={id} className="toggleButton-label">
        {label}
        {subLabel && <small>{subLabel}</small>}
      </label>
    </div>
  )
});

export default ToggleButton;