import React from 'react';
import closeIcon from "../../assets/images/icons/close-icon.svg";
import './Modal.css';
import { useDispatch } from 'react-redux';
import { resetError } from '../../app/reducers/errorModalSlice';

const MessageModal = (props) => {
  const { message, title, onClick } = props;

  const dispatch = useDispatch();

  const OnReset = () =>{
    dispatch(resetError());
  }


  return (
    <React.Fragment>
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
            <div className="modal-close" onClick={() => onClick()}>
              <img src={closeIcon} />
            </div>
          </div>
          <div className="modal-body">
            {/* {children} */}
            {message}
          </div>
        </div>
      </div>
      <div className="modal-overlay" onClick={() => onClick()} />
    </React.Fragment>
  )
};

export default MessageModal;