import React, { useEffect, useState } from 'react';
import { TextInput, SelectBox, Calendar, TextArea, ToggleButton, Checkbox } from '../../../components/FormElements';
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { treatmentAreas } from '../../../constants';

import '../AdverseEvent.css';
import AutocompleteClinic from '../../../components/AutocompleteClinic/AutocompleteClinic';
const AdverseEventForm = (props) => {
  const dispatch = useDispatch();
  const { isReadOnly, control, register } = props;
  const clinicSelector = useSelector((state) => state.clinic);
  const appointmentSelector = useSelector((state) => state.appointment);
  const drugBookSelector = useSelector((state) => state.drugBook);
  const adverseEventsSelector = useSelector((state) => state.adverseEvents);
  const [clinicId, setClinicId] = useState(0);

  let clinics = clinicSelector?.clinics?.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  const options = [
    { value: 'Option1', label: 'Option 1', Id: 1 },
    { value: 'Option2', label: 'Option 2', Id: 2 },
    { value: 'Option3', label: 'Option 3', Id: 3 },
  ];

  return (
    <React.Fragment>
      <div className="section-title">Patient Details</div>
      <div className="row">
        <div className="col-md-6">
          <Controller
            control={control}
            name="appointment"
            render={({ field }) => (
              <TextInput
                label="Appointment"
                controlId="Appointment"
                {...field}
                readOnly={true}
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            name="clinic"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteClinic
                label="Clinic"
                controlId="Clinic"
                initialValue={value}
                readOnly={true}
                onValueChange={(e, i) => {
                  onChange(e);
                  if (i !== undefined) {
                    setClinicId(i);
                  }
                }}
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            control={control}
            name="patientFullName"
            render={({ field }) => (
              <TextInput
                label="Patient Full Name"
                controlId="PatientName"
                {...field}
                readOnly={true}
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            control={control}
            name="patientDob"
            render={({ field }) => (
              <Calendar
                ref={field.ref}
                label="Patient Date of Birth"
                controlId="StrDOB"
                {...field}
                onChange={(date) => field.onChange(date)}
                readOnly={true}
              />
            )}
          />
        </div>
        {/* <div className="col-md-6">
          <Controller
                name='prescribingDoctor'
                control={control}
                render={({ field: { onChange, value } }) => (                  
                  <AutocompletePrescriber
                    label='Prescribing Doctor'
                    controlId='Prescriber'
                    initialValue={value}
                    readOnly={isReadOnly}
                    onValueChange={(e,i) => {
                      onChange(e);
                      setPrescriberId(i);
                    }}
                  />
                )}
              />
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-6">
          <Controller
            control={control}
            name="currentMedication"
            render={({ field }) => (
              <TextArea
                label="Current Medication"
                controlId="CurrentMedication"
                {...field}
                readOnly={isReadOnly}
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            control={control}
            name="currentMedicationConditions"
            render={({ field }) => (
              <TextArea
                label="Current Medical Conditions"
                controlId="CurrentMedicalConditions"
                {...field}
                readOnly={isReadOnly}
              />
            )}
          />
        </div>
      </div>

      <div className="section-title mt-20">Treatment Details</div>
      <div className="row">
        <div className="col-md-6">
          <Controller
            control={control}
            name="treatmentPlanId"
            render={({ field }) => (
              <SelectBox
                label="Treatment Plan"
                options={appointmentSelector?.treatmentPlans?.map((plan) => ({
                  value: plan.id,
                  label: `${plan.strDateOfIssues} ${
                    plan.treatmentInfo?.packageType ?? ""
                  }`,
                }))}
                name="treatmentPlanId"
                {...field}
                readOnly={isReadOnly}
                placeholder={`${
                  adverseEventsSelector?.AdverseEventDetails
                    ?.StrTreatmentDate ?? ""
                } -  
                     ${
                       adverseEventsSelector?.AdverseEventDetails
                         ?.TreatmentType ?? "Treatment Plan"
                     }`}
                required
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            name="drugEntry"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectBox
                controlId="DrugBook"
                options={drugBookSelector?.drugBookAdministered.map((item) => ({
                  label: item.ProductName,
                  value: item.ProductName,
                }))}
                label="Drug Book Entries"
                type="others"
                value={value} 
                onChange={(selectedValue) => { 
                  onChange(selectedValue); 
                }}
                readOnly={isReadOnly}
                required
              />
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Controller
            control={control}
            name="eventAfterwards"
            render={({ field }) => (
              <TextArea
                label="Describe what happened at the time of injection and timeline of event afterwards"
                controlId="EventDescription"
                {...field}
                readOnly={isReadOnly}
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            control={control}
            name="currentSituation"
            render={({ field }) => (
              <TextArea
                label="Current situation: Has the issue beeing resolved or is it ongoing? Please provide details."
                controlId="CurrentSituation"
                {...field}
                readOnly={isReadOnly}
              />
            )}
          />
        </div>
      </div>
      <div className="affected-area-title">Select the affected area</div>
      <div className="row">
        {treatmentAreas[0].Areas.map((item) => (
          <div className="col-md-6 mb-30">
            <Controller
              control={control}
              name={item.Code}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label={item.Name}
                  unitLable="Max Dose:"
                  unit={item.Dosage}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value || false}
                  disabled={isReadOnly}
                />
              )}
            />
          </div>
        ))}
      </div>
      <div className="section-title mt-20">Pre-Treatment Details</div>
      <div className="row">
        <div className="col-md-6">
          <div className="question-item">
            <div className="question-answer">
              <div className="toggleButton-wrapper">
                <ToggleButton
                  {...register("beenUnwell")}
                  type="radio"
                  value={"no"}
                  name={"beenUnwell"}
                  id={"beenUnwellNo"}
                  label={"No"}
                  disabled={isReadOnly}
                  required
                />
                <ToggleButton
                  {...register("beenUnwell")}
                  type="radio"
                  value={"yes"}
                  name={"beenUnwell"}
                  id={"beenUnwellYes"}
                  label={"Yes"}
                  disabled={isReadOnly}
                  required
                />
              </div>
            </div>
            <div className="question-title">
              Been unwell, attended a Doctor’s Appointment?
            </div>
          </div>
          <div className="question-item">
            <div className="question-answer">
              <div className="toggleButton-wrapper">
                <ToggleButton
                  {...register("newMedication")}
                  type="radio"
                  value={"no"}
                  name={"newMedication"}
                  id={"newMedicationNo"}
                  label={"No"}
                  disabled={isReadOnly}
                  required
                />
                <ToggleButton
                  {...register("newMedication")}
                  type="radio"
                  value={"yes"}
                  name={"newMedication"}
                  id={"newMedicationYes"}
                  label={"Yes"}
                  disabled={isReadOnly}
                  required
                />
              </div>
            </div>
            <div className="question-title">Started a new medication?</div>
          </div>
          <div className="question-item">
            <div className="question-answer">
              <div className="toggleButton-wrapper">
                <ToggleButton
                  {...register("newSkinCare")}
                  type="radio"
                  value={"no"}
                  name={"newSkinCare"}
                  id={"newSkinCareNo"}
                  label={"No"}
                  disabled={isReadOnly}
                  required
                />
                <ToggleButton
                  {...register("newSkinCare")}
                  type="radio"
                  value={"yes"}
                  name={"newSkinCare"}
                  id={"newSkinCareYes"}
                  label={"Yes"}
                  disabled={isReadOnly}
                  required
                />
              </div>
            </div>
            <div className="question-title">
              Started a new skin care, changes in products used on the skin?
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <Controller
            control={control}
            name="furtherInformation"
            render={({ field }) => (
              <TextArea
                label="Please provide further information if yes to any questions?"
                controlId="furtherInformation"
                {...field}
                readOnly={isReadOnly}
              />
            )}
          />
        </div>
      </div>
      <div className="section-title mt-20">Post-Treatment Details</div>
      <div className="row">
        <div className="col-md-6">
          <div className="question-item">
            <div className="question-answer">
              <div className="toggleButton-wrapper">
                <ToggleButton
                  {...register("exercise")}
                  type="radio"
                  value={"no"}
                  name={"exercise"}
                  id={"exerciseNo"}
                  label={"No"}
                  disabled={isReadOnly}
                  required
                />
                <ToggleButton
                  {...register("exercise")}
                  type="radio"
                  value={"yes"}
                  name={"exercise"}
                  id={"exerciseYes"}
                  label={"Yes"}
                  disabled={isReadOnly}
                  required
                />
              </div>
            </div>
            <div className="question-title">Do any exercise?</div>
          </div>
          <div className="question-item">
            <div className="question-answer">
              <div className="toggleButton-wrapper">
                <ToggleButton
                  {...register("microdermabrasion")}
                  type="radio"
                  value={"no"}
                  name={"microdermabrasion"}
                  id={"microdermabrasionNo"}
                  label={"No"}
                  disabled={isReadOnly}
                  required
                />
                <ToggleButton
                  {...register("microdermabrasion")}
                  type="radio"
                  value={"yes"}
                  name={"microdermabrasion"}
                  id={"microdermabrasionYes"}
                  label={"Yes"}
                  disabled={isReadOnly}
                  required
                />
              </div>
            </div>
            <div className="question-title">
              Have a skin treatment, such as laser or microdermabrasion?
            </div>
          </div>
          <div className="question-item">
            <div className="question-answer">
              <div className="toggleButton-wrapper">
                <ToggleButton
                  {...register("cosmeceuticals")}
                  type="radio"
                  value={"no"}
                  name={"cosmeceuticals"}
                  id={"cosmeceuticalsNo"}
                  label={"No"}
                  disabled={isReadOnly}
                  required
                />
                <ToggleButton
                  {...register("cosmeceuticals")}
                  type="radio"
                  value={"yes"}
                  name={"cosmeceuticals"}
                  id={"cosmeceuticalsYes"}
                  label={"Yes"}
                  disabled={isReadOnly}
                  required
                />
              </div>
            </div>
            <div className="question-title">
              Use any cosmeceuticals with active ingredients?
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <Controller
            control={control}
            name="postTreatmentDetails"
            render={({ field }) => (
              <TextArea
                label="Please provide further information if yes to any questions?"
                controlId="postTreatmentDetails"
                {...field}
                readOnly={isReadOnly}
              />
            )}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdverseEventForm;

