import React from "react";
import "./Consent.css";

const PlateletConsent = () => {
  return (
    <React.Fragment>
      <div className="mt-60">
        <div className="consent-type"> Platelet Rich Plasma</div>
        <div className="consent-text">
          At SILK Laser Clinics Platelet Rich Plasma treatment involves the
          collection of your blood, approximately 8- 16ml, which is then spun
          down using a centrifuge to separate out the plasma and platelet
          portion. The PRP portion of your blood is then injected back into your
          skin to stimulate new collagen production and to re- energise your
          cells.
        </div>

        <div className="consent-layout-wrapper">
          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">
                ON THE DAY OF YOUR APPOINTMENT
              </div>
              <p class="paragraph">
                Prior to treatment you will be given a thorough consultation,
                during this consultation a treatment plan is competed.
                <br />
                <br />
                The procedure itself can take up to 45mins and includes numbing.
                Mineral make up can gently be applied directly after treatment.
              </p>
            </div>

            <div class="right-side">
              <div className="consent-subheading">AFTER YOUR TREATMENT</div>
              <p class="paragraph">
                The treatment area will feel tender to touch, it is recommended
                that you avoid touching your face for at least 2 hours after
                treatment to allow the injection sites to heal. Ensure hands are
                clean and washed before touching the injected area. To assist
                the healing process, you are recommended to:
                <ul>
                  <li>
                    Gently massage the treatment area using the Aesthetics Rx
                    H2O Hydrating Balm for comfort.
                  </li>
                  <li>Sleep elevated to assist in reducing swelling.</li>
                  <li>
                    Bruising may occur, Arnica tables, cream or spray can assist
                    healing.
                  </li>
                </ul>
              </p>
            </div>
          </div>

          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">SWELLING</div>
              <p class="paragraph">
                You can expect swelling to be present in the treatment area for
                up to 2 days after the treatment, to ensure the best possible
                result, it is recommended that you avoid the following for
                48hours after treatment:
                <ul>
                  <li>Exercise</li>
                  <li>Hot showers</li>
                  <li>Sauna and/or Steam</li>
                  <li>Pool and/or Spa</li>
                  <li>Prolonged sunlight </li>
                  <li>Excessive alcohol</li>
                </ul>
                If you are undergoing Professional Skin Treatments, please speak
                with your Laser and Dermal Technician for more information about
                the recommended wait times post treatment.
              </p>
            </div>
            <div class="right-side">
              <div className="consent-subheading">
                COMMON SHORT TERM SIDE EFFECTS
              </div>
              <p class="paragraph">
                SILK Laser Clinics advises that there are certain inherent and
                potential risks in any treatment plan or procedure and that in
                this specific instance, such operative risks include, but are
                not limited to:
                <ul>
                  <li>
                    Temporary injection site reactions such as redness,
                    pain/tenderness.
                  </li>
                  <li>Bruising, itching, swelling, bumps and/or pimples</li>
                </ul>
                The following substances/ medications/ vitamins can increase the
                risk of bruising:
                <ul>
                  <li>Alcohol pre or post treatment</li>
                  <li>Anti-inflammatory medications</li>
                  <li>Vitamin E and Gingko Biloba</li>
                  <li>Fish and Krill oil</li>
                  <li>Aspirin</li>
                  <li>Smoking</li>
                </ul>
              </p>
            </div>
          </div>
          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">PHOTOGRAPHIC CONSENT</div>
              <p class="paragraph">
                I give permission for my photographs to be used for:
                <ul>
                  <li>Internal education purposes</li>
                  <li>
                    Social media marketing including (but not limited to)
                    Facebook and Instagram
                  </li>
                </ul>
              </p>
            </div>

            <div class="right-side">
              <div className="consent-subheading">FINANCIAL CONSENT</div>
              <p class="paragraph">
                Your cosmetic injector will provide you with full details of
                your cost of treatment prior to the procedure. Any deposits paid
                are redeemable against your treatment. Treatment costs are a
                private service and not covered by Medicare.
                <br />
                <br />I have been made aware of all costs associated with my
                treatment plan
              </p>
            </div>
          </div>

          <div className="consent-text">
            All procedures performed at SILK are proven to be safe and
            effective.
            <br />
            <br />
            Further treatment to address any unwanted side effects may be
            required in the short term (such as for anaphlyaxis, hyaluronidaise
            to dissolve dermal filler, antibiotics (oral or topical) or the long
            term (such as steroids to treat ongoing nodules). Costs for this
            treatment if required may or may not be covered by SILK and will
            depend on many factors and be at the discretion of SILK Management.
            <br />
            <br />
            If you have any concerns or questions with regards to your
            treatment, please contact the clinic you were treated at to speak
            with a member of the medical team during clinic opening hours. Full
            information on our complaints process can be found here.
            <br />
            <br />
            In the case of an adverse response, outside of opening hours, please
            contact SILK Afterhours Care on: 08 8342 7435 If you are
            experiencing a medical emergency, please call 000.
            <br />
            <br />
            There are many alternative treatments, including the option of not
            having the procedure. Your cosmetic injector will discuss these
            options with you.
            <br/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlateletConsent;
