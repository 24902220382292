import React from "react";
import "./Consent.css";

const FillersConsent = () => {
  return (
    <React.Fragment>
      <div className="mt-60">
        <div className="consent-type"> Fillers</div>
        <div className="consent-text">
          At SILK Laser Clinics, there are a variety of hyaluronic acid
          injections available for treatment: dermal fillers, skinboosters and
          bio re- modellers. Hyaluronic acid (HA) is a natural complex sugar
          which already exists in the body; it stabilizes and contributes to the
          elasticity of the skin whilst attracting and binding water. Dermal
          filler is used to temporarily restore volume and correct facial lines
          and folds as a result of ageing. Dermal filler enhances various areas
          of the face giving a fuller, smoother and more youthful appearance.
          Lidocaine is often incorporated in the gel for improved comfort during
          the procedure. Significant improvement can be noted immediately after
          treatment and lasts for 9 to 24 months (may be longer or shorter) and
          is naturally degraded by the body. Skinboosters provide deep hydration
          to the skin, without adding structure or significant volume. Bio
          re-modellers are newer technology which use HA to stimulate stem
          cells, providing a stable environment for the laying-down of new
          collagen and elastin in the superficial layers of the skin. This
          results in improved texture, intense hydration, an overall improvement
          in skin quality and firmer skin.
        </div>

        <div className="consent-layout-wrapper">
          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">
                ON THE DAY OF YOUR APPOINTMENT
              </div>
              <p class="paragraph">
                Prior to treatment you will be given a thorough consultation,
                during this consultation a treatment plan is competed, and a
                prescription is produced. All treatment plans and prescriptions
                are valid for 6 months.
                <br />
                <br />
                The procedure itself can take between 15-45mins depending on the
                size of the treatment area and includes numbing. Mineral make up
                can gently be applied directly after treatment.
              </p>
            </div>

            <div class="right-side">
              <div className="consent-subheading">AFTER YOUR TREATMENT</div>
              <p class="paragraph">
                To ensure the best possible results it is recommended that you
                avoid the following for 48hours after treatment:
                <br />
                Avoid direct pressure, rubbing or manipulating the injection
                area
                <br />
                Do NOT massage the treatment area
                <br />
                Avoid exercise and/or strenuous activity
                <br />
                Avoid excessive alcohol
                <br />
                Avoid extreme temperatures e.g. saunas, excessive sun exposure
                or sub-zero temperatures
                <br />
                Apply moisturiser with careIf you are undergoing Professional
                Skin Treatments, please speak with your Laser and Dermal
                Technician for more information about the recommended wait times
                post treatment.
                <br />
                <br />
                Domestic Flights should be avoided for 48hours after treatment
                and International Flights for 2 weeks.
              </p>
            </div>
          </div>

          {/* <div class="layout-container"> */}
            <div className="consent-subheading">
              COMMON SHORT TERM SIDE EFFECTS
            </div>
            <div className="consent-text-wrapper">
              <ol>
                <li>
                  ILK Laser Clinics advises that there are certain inherent and
                  potential risks in any treatment plan or procedure and that in
                  this specific instance, such operative risks include, but are
                  not limited to:
                  <ul >
                    <li>
                      Temporary injection site reactions such as redness,
                      pain/tenderness
                    </li>
                    <li>Bruising, itching, swelling, bumps and/or pimples</li>
                    <li>Temporary discolouration in the injection site</li>
                    <li>
                      Firmness, lumps and bump which will resolve itself over
                      time, but you can gently massage the area to assist
                    </li>
                    <li>
                      As with all skin injection procedures, there is a risk of
                      infectionThe following substances/ medications/ vitamins
                      can increase the risk of bruising:
                      <ul>
                        <li>Alcohol pre or post treatment</li>
                        <li>
                          Anti-inflammatory medications and Aspirin Vitamin E,
                          Gingko Biloba, Fish and Krill oil
                        </li>
                        <li>Smoking</li>
                        In very rare cases you may experience:
                        <li>
                          Vascular Compromise, blockage or reduced blood flow
                          causing damage to surrounding tissue in the skin
                          and/or eye which may result in temporary, or in rare
                          cases, permanent loss of vision.
                        </li>
                        <li>
                          Skin necrosis, causing damage to the surrounding skin
                          Allergies or hypersensitivity are uncommon but have
                          been reported
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          {/* </div> */}

          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">PHOTOGRAPHIC CONSENT</div>
              <p class="paragraph">
                I give permission for my photographs to be used for:
                <ul>
                  <li>Internal education purposes</li>
                  <li>
                    Social media marketing including (but not limited to)
                    Facebook and Instagram
                  </li>
                </ul>
              </p>
            </div>

            <div class="right-side">
              <div className="consent-subheading">FINANCIAL CONSENT</div>
              <p class="paragraph">
                Your cosmetic injector will provide you with full details of
                your cost of treatment prior to the procedure. Any deposits paid
                are redeemable against your treatment. Treatment costs are a
                private service and not covered by Medicare.
                <br />
                <br />I have been made aware of all costs associated with my
                treatment plan
              </p>
            </div>
          </div>

          <div className="consent-text">
            All procedures performed at SILK are proven to be safe and
            effective.
            <br />
            <br />
            Further treatment to address any unwanted side effects may be
            required in the short term (such as for anaphlyaxis, hyaluronidaise
            to dissolve dermal filler, antibiotics (oral or topical) or the long
            term (such as steroids to treat ongoing nodules). Costs for this
            treatment if required may or may not be covered by SILK and will
            depend on many factors and be at the discretion of SILK Management.
            <br />
            <br />
            If you have any concerns or questions with regards to your
            treatment, please contact the clinic you were treated at to speak
            with a member of the medical team during clinic opening hours. Full
            information on our complaints process can be found here.
            <br />
            <br />
            In the case of an adverse response, outside of opening hours, please
            contact SILK Afterhours Care on: 08 8342 7435 If you are
            experiencing a medical emergency, please call 000.
            <br />
            <br />
            There are many alternative treatments, including the option of not
            having the procedure. Your cosmetic injector will discuss these
            options with you.
            <br/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FillersConsent;
