import { get, put, del } from './restApi';
import { envEndPoints } from '../config';

export const getClinics = () =>
  get({
    url: `${envEndPoints().baseUrl}/clinic`,
  });

export const deleteClinic = (req: any) =>
  del({
    url: `${envEndPoints().baseUrl}/clinic/${req.id}`,
  });

export const updateClinic = (req: any) =>
put({
  url: `${envEndPoints().baseUrl}/clinic/updateClinic`,
  data: req,
});