import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import AppointmentCheckbox from '../../../components/AppointmentCheckbox/AppointmentCheckbox';
import { Link } from 'react-router-dom';
import { TextInput, Button, SelectBox, TextArea } from '../../../components/FormElements';
import dynamicQuestions from '../DynamicQuestions.json';
import { useDispatch } from 'react-redux';
import { saveAppointmentAdditionalDetails } from '../../../app/reducers/appointmentSlice';

import '../Appointments.css';

const Details = (props) => {
  const { appointmentId, additionalDetails, isCompleted, onTabChange } = props;
  const dispatch = useDispatch();

  const { handleSubmit, watch, control, register } = useForm({
    defaultValues: additionalDetails,
  });

  const watchData = watch();

  const onSubmit = (data) => {

    dispatch(
      saveAppointmentAdditionalDetails({
        id: appointmentId,
        AppointmentAdditionalDetails: data,
      })
    );
    onTabChange('Medical History');
  };

  const questionsBySequence = [...dynamicQuestions.Questions].sort((a, b) =>
    a.Sequence > b.Sequence ? 1 : -1
  );

  const fieldMeetsCondition = () => (item) => {
    if (item.conditional) {
      let conditionMeet = true;
      item.conditional.map((e) => {
        if (e.Value !== watchData[e.GUID]) {
          return (conditionMeet = false);
        }
      });
      return conditionMeet;
    }
    return true;
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {questionsBySequence
          .filter(fieldMeetsCondition(questionsBySequence))
          .map((item, index) => (
            <React.Fragment key={index}>
              {item.Title && (
                <div className='app-section-heading mt-20'>{item.Title}</div>
              )}

              {item.Component === 'checkBox' && (
                <div className='row'>
                  {item.Options.map((option, index) => (
                    <div key={index} className='col-md-3 mb-30'>
                      <AppointmentCheckbox
                        {...register(option.GUID)}
                        type='checkbox'
                        name={option.GUID}
                        id={option.GUID}
                        label={option.Label}
                      />
                    </div>
                  ))}
                </div>
              )}

              {item.Component === 'radioButton' && (
                <div className='row'>
                  {item.Options.map((option, index) => (
                    <div key={index} className='col-md-3 mb-30'>
                      <AppointmentCheckbox
                        {...register(item.GUID)}
                        type='radio'
                        value={option.Value}
                        name={item.GUID}
                        id={option.Value}
                        label={option.Label}
                      />
                    </div>
                  ))}
                </div>
              )}

              {item.Component === 'textBox' && (
                <React.Fragment>
                  {item.Options.map((option, index) => (
                    <div key={index}>
                      <TextInput
                        {...register(option.GUID)}
                        label={option.Label}
                        controlId={option.GUID}
                      />
                    </div>
                  ))}
                </React.Fragment>
              )}

              {item.Component === 'selectBox' && (
                <React.Fragment>
                  <Controller
                    name={item.GUID}
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        label={item.Title}
                        options={item.Options.map((options) => {
                          options['label'] = options?.Label;
                          options['value'] = options?.Value;
                          return options;
                        })}
                        {...field}
                      />
                    )}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          ))}


       <div className='row'>
          <div className="col-md-6">
            {/* <div className='section-title'>Assessment</div> */}
            <div>
            Assessment (e.g. Facial Shape, Proportions, Features, Volume Loss, Texture, Symmetry)
            </div>
            <Controller
              control={control}
              name="AssessmentNotes"
              render={({ field }) => (
                <TextArea
                  rows={8}
                  controlId="AssessmentNotes"
                  {...field}
                />
              )}
            />
          </div>
        </div>


        <hr className='divider mt-20 mb-50' />
        <div className='flex justify-end align-center'>
          <Link className='cancel-link' to='/appointments'>
            Cancel
          </Link>
          <Button
            disabled={isCompleted}
            style={{ minWidth: '165px' }}
          >
            Save & Next
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Details;
