import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import './TreatmentPlans.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/FormElements';
import SignatureCanvas from 'react-signature-canvas';
import { useSelector, useDispatch } from 'react-redux';
import { ApprovalStatus } from '../../constants/index';
import { declineTreatmentPlan, patientConsent } from '../../app/reducers/treatmentPlanSlice';
import Modal from '../../components/Modal/Modal';
import Deactivate from '../../components/Deactivate/Deactivate';
import ConfirmDeclineTreatment from './ConfirmDeclineTreatment';

const CommentSignatureLayout = () => {
  let signPad = useRef({});
  const [signature, setSignature] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [declineModalShow, setDeclineModalShow] = useState(false);
  
  const handleDeclineModalClose = () => setDeclineModalShow(false);
  
  const handleDeclineClick = (rowId) => {
    setDeclineModalShow(true);
  };

  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);
  const authenticationSelector = useSelector((state) => state.authentication);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      comments: '',
    },
  });

  const trim = () => {
    const fullDataUrl = signPad.current.toDataURL('image/png');
    //removing the "data:image/png;base64," prefix to accomodate saving in pdf
    const imageData = fullDataUrl.split(',')[1];
    setSignature(imageData);
  };

  const clear = () => {
    signPad.current.clear();
    setSignature('');
  };

  const declineTreatment = () => {
    let plan = {
      id: treatmentPlanSelector.id,
      treatmentStatusId: 7, //Decline
      approvedSignature: '',
      approvalComment: 'Decline',
    };
    dispatch(
      declineTreatmentPlan({
        data: plan,
        navigate,
        redirectUrl: '/treatmentplan/outcome',
      })
    );
  };

  const onSubmit = (formdata) => {
    let statusId = ApprovalStatus.find(
      (approval) => approval.status.toUpperCase() === 'AC'
    )?.id;

    let data = {
      ...formdata,
      approvedSignature: signature,
      treatmentPlanId: treatmentPlanSelector.id,
      treatmentStatusId: statusId,
      userid: authenticationSelector.id,
      appointmentId: treatmentPlanSelector.appointmentId,
    };
    dispatch(
      patientConsent({
        data: data,
        navigate,
        // redirectUrl: '/appointments/details',
        redirectUrl: '/treatmentplan/outcome',
      })
    );
  };

  return (
    <React.Fragment>
      <div className='mt-60'>
        <hr className='divider mt-70 mb-70' />
        <div className='review-sub-heading text-pink'>Consent</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
          
            <div className='col-md-6'>
              <div className='signature-canvas-wrapper'>
                <label className='floating-label'>Consent Signature</label>
                <SignatureCanvas
                  backgroundColor='#F9F9F9'
                  canvasProps={{ className: 'sigCanvas' }}
                  ref={signPad}
                  clearOnResize={false}
                  onEnd={() => trim()}
                />
                <div
                  className='clear-signature'
                  title='Clear Signature'
                  onClick={() => clear()}
                >
                  <svg
                    width='23'
                    height='21'
                    viewBox='0 0 23 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.8594 20.5221C11.8324 20.5221 11.801 20.5625 11.774 20.5625H6.91348C6.05547 20.5625 5.2334 20.2211 4.62696 19.6147L1.39393 16.3803C0.130816 15.118 0.130816 13.0695 1.39393 11.8072L11.3697 1.83144C12.632 0.568232 14.6805 0.568232 15.9428 1.83144L21.567 7.49474C22.8697 8.75704 22.8697 10.8055 21.567 12.0678L15.2689 18.4063H21.9219C22.5193 18.4063 23 18.8869 23 19.4844C23 20.0818 22.5193 20.5625 21.9219 20.5625L11.8594 20.5221ZM2.91858 14.817L6.11387 18.0514C6.35645 18.294 6.63047 18.4063 6.91348 18.4063H11.774C12.057 18.4063 12.3311 18.294 12.5332 18.0514L15.4531 15.1719L8.26563 7.98438L2.91858 13.3301C2.49766 13.7524 2.49766 14.4352 2.91858 14.817Z'
                      fill='#525252'
                    />
                  </svg>
                </div>
              </div>
            </div>
            {/* <div className='col-md-6'>
              <Controller
                name='comments'
                control={control}
                render={({ field }) => (
                  <TextArea label='Consent Comment' rows={8} {...field} />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Consent comment is required!',
                  },
                }}
              />
            </div> */}
          </div>

          <div className='flex justify-end align-center mt-50'>
            <u>
              <button
                className='popup-link'
                type='button'
                onClick={handleDeclineClick}
              >
                Decline Treatment Plan
              </button>
            </u>
            <Button disabled={!signature && true} style={{ minWidth: '165px' }}>
              Consent
            </Button>
          </div>
        </form>
      </div>
      <Modal
        show={declineModalShow}
        onHide={handleDeclineModalClose}
        title="Decline Treatment Plan"
      >
        <ConfirmDeclineTreatment
          onHide={handleDeclineModalClose}
          message={"Are you sure you would like to decline the treatment plan?"}
          buttonText={"Decline"}
          handleDecline ={declineTreatment}
        />
      </Modal>
    </React.Fragment>
  );
};

export default CommentSignatureLayout;
