import React from 'react';
import './TextInput.css';

const TextInput = React.forwardRef((props, ref) => {
  const { label, controlId, addonType="", error="", ...otherProps } = props;

  return (
    <div className="floating-input">
      <input ref={ref} className={`text-input ${error && "invalid"} ${addonType && "addon-space"}`} id={controlId} placeholder=" " autoComplete="false" {...otherProps} />
      <label className="floating-label" htmlFor={controlId}>{label}</label>

      {addonType === "search" &&
        <svg className="input-addon" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.8" d="M17.7539 15.8242C17.918 15.9883 18 16.1875 18 16.4219C18 16.6562 17.918 16.8555 17.7539 17.0195L16.7695 18.0039C16.6055 18.168 16.4062 18.25 16.1719 18.25C15.9375 18.25 15.7383 18.168 15.5742 18.0039L12.0586 14.4883C11.8945 14.3242 11.8125 14.125 11.8125 13.8906V13.3281C10.5 14.3594 9 14.875 7.3125 14.875C5.29688 14.875 3.57422 14.1602 2.14453 12.7305C0.714844 11.3008 0 9.57812 0 7.5625C0 5.54688 0.714844 3.82422 2.14453 2.39453C3.57422 0.964844 5.29688 0.25 7.3125 0.25C9.32812 0.25 11.0508 0.964844 12.4805 2.39453C13.9102 3.82422 14.625 5.54688 14.625 7.5625C14.625 9.25 14.1094 10.75 13.0781 12.0625H13.6406C13.875 12.0625 14.0742 12.1445 14.2383 12.3086L17.7539 15.8242ZM4.11328 10.7617C5.00391 11.6289 6.07031 12.0625 7.3125 12.0625C8.55469 12.0625 9.60938 11.6289 10.4766 10.7617C11.3672 9.87109 11.8125 8.80469 11.8125 7.5625C11.8125 6.32031 11.3672 5.26562 10.4766 4.39844C9.60938 3.50781 8.55469 3.0625 7.3125 3.0625C6.07031 3.0625 5.00391 3.50781 4.11328 4.39844C3.24609 5.26562 2.8125 6.32031 2.8125 7.5625C2.8125 8.80469 3.24609 9.87109 4.11328 10.7617Z" fill="#282829" />
        </svg>
      }

      {addonType === "lock" &&
        <svg className="input-addon" width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.8" d="M14.3125 8.125C14.7812 8.125 15.1797 8.28906 15.5078 8.61719C15.8359 8.94531 16 9.34375 16 9.8125V16.5625C16 17.0312 15.8359 17.4297 15.5078 17.7578C15.1797 18.0859 14.7812 18.25 14.3125 18.25H1.9375C1.46875 18.25 1.07031 18.0859 0.742188 17.7578C0.414062 17.4297 0.25 17.0312 0.25 16.5625V9.8125C0.25 9.34375 0.414062 8.94531 0.742188 8.61719C1.07031 8.28906 1.46875 8.125 1.9375 8.125H2.78125V5.59375C2.78125 4.11719 3.29688 2.86328 4.32812 1.83203C5.38281 0.777344 6.64844 0.25 8.125 0.25C9.60156 0.25 10.8555 0.777344 11.8867 1.83203C12.9414 2.86328 13.4688 4.11719 13.4688 5.59375V8.125H14.3125ZM10.6562 8.125V5.59375C10.6562 4.89062 10.4102 4.29297 9.91797 3.80078C9.42578 3.30859 8.82812 3.0625 8.125 3.0625C7.42188 3.0625 6.82422 3.30859 6.33203 3.80078C5.83984 4.29297 5.59375 4.89062 5.59375 5.59375V8.125H10.6562Z" fill="#282828" />
        </svg>
      }
      {error && <span className="errors-message">{error}</span>}
    </div>
  )
});

export default TextInput;