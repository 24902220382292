import React from 'react';
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import './Table.css';

const Table = ({ data, columns, action, columnsMergeId, columnsMergeWith, handleSort }) => {
  return (
    <div className="table-wrapper">
      <table className="table">
        <TableHead {...{ columns, action, handleSort: handleSort }} />
        <TableBody {...{ columns, data, columnsMergeId, columnsMergeWith, action }} />
      </table>
    </div>
  )
};

export default Table;