import React, { useEffect, useState } from 'react';
import Heading from '../../components/Heading/Heading';
import './VideoConsult.css';
import timerBg from '../../assets/images/icons/timer-bg.svg';
import Countdown from './components/Countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { assignPresciber } from '../../app/reducers/videoConsultSlice';

const Queue = () => {
  const [selectedPrescriber, setselectedPrescriber] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  //Real time use below.
  // var callerAcsToken = useSelector(state =>state.communications.acsToken);
  var callerAcsToken =
    'eyJhbGciOiJSUzI1NiIsImtpZCI6IjVFODQ4MjE0Qzc3MDczQUU1QzJCREU1Q0NENTQ0ODlEREYyQzRDODQiLCJ4NXQiOiJYb1NDRk1kd2M2NWNLOTVjelZSSW5kOHNUSVEiLCJ0eXAiOiJKV1QifQ.eyJza3lwZWlkIjoiYWNzOjJhMzA4ZDVkLTc1Y2YtNDg1ZC1iZTRkLTM5YTE1NjE0YjM3Nl8wMDAwMDAxOS1mYzgyLTI0ZDktZTgwNi02YjNhMGQwMGI0MGMiLCJzY3AiOjE3OTIsImNzaSI6IjE2ODk0NzYxNjkiLCJleHAiOjE2ODk1NjI1NjksInJnbiI6ImF1IiwiYWNzU2NvcGUiOiJ2b2lwIiwicmVzb3VyY2VJZCI6IjJhMzA4ZDVkLTc1Y2YtNDg1ZC1iZTRkLTM5YTE1NjE0YjM3NiIsInJlc291cmNlTG9jYXRpb24iOiJhdXN0cmFsaWEiLCJpYXQiOjE2ODk0NzYxNjl9.Mv4uHyvpxm1GZxZq7d5papikS_S_VGZ0fRNczA_n-4x4flUkyKIVsDtYtwOv7Tt2czdNi_qY0DE4ZQrgZizg8kEzQ5bxaq312Ml8DbcC7nl6uDYLIeW8TNAj7cAgIF13ZXlFWYSlvqvHSHTE4MXx5IqzdYVWdSZTEo2BCCT8YpBVTrpWdjcbkDA71rFBKS9wgG_1i08NvvtIem_h3NMqnJU65UW3Mt95lB8OBLLSabZEHmT9EofjkgC3AgdKd4uO0oHHTvrnY8o1fU355NcJgt2Nu-Voq4YpZG57VYxmZfAbE95aioG37U42Tb4X7kkDxH8n6Q62_e4PWuIAs7yE2A';
  const availablePrescribers = useSelector(
    (state) => state.users.availablePrescribers
  );
  //const vConsult = useSelector((state) => state.videoConsult);
  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);
  const vConsult = useSelector((state) => state.videoConsult);

  useEffect(() => {
    const interval = setInterval(() => {
      let data = {
        treatmentPlanId: treatmentPlanSelector.id,
      };
      dispatch(
        assignPresciber({
          data: data,
          navigate,
          redirectUrl: '/videoconsult/conduct',
        })
      );
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // const checkPrescriber = (e, id) => {
  //   if (e.target.checked) {
  //     setselectedPrescriber(id);
  //   }
  // };

  // const onPlaceCall = (prescriberId) => {
  //   dispatch(getAcsIdToken());
  //   const prescriberAcsToken = availablePrescribers.find(
  //     (item) => item.Id === prescriberId
  //   )?.AcsToken;
  //   const prescriberAcsId = availablePrescribers.find(
  //     (item) => item.Id === prescriberId
  //   )?.AcsId;
  //   console.log('nurseAcsTOken ', callerAcsToken);
  //   navigate('/videoconsult/conduct', {
  //     state: { prescriberAcsToken, prescriberAcsId, callerAcsToken },
  //   });
  // };

  // const onCancel = (e, id) => {
  //   console.log('id');
  //   setselectedPrescriber('');
  //   window.location.reload();
  // };

  return (
    <React.Fragment>
      <Heading title='Treatment Plan' />
      <div className='queue-section'>
        <div className='row'>
          {/* <div className='col-md-6'>
            <div className='prescribers-wrapper'>
              <div className='queue-title'>
                Immediately available prescribers
              </div>
              {availablePrescribers && availablePrescribers.length ? (
                <React.Fragment>
                  <div className='row mt-30'>
                    {availablePrescribers.map((item, index) => (
                      <div key={index} className='col-md-6'>
                        <Prescribers
                          label={item.Name}
                          availableIn='Available immediately'
                          controlId={`prescriber-${item.id}`}
                          name='annie'
                          onChange={(e) => checkPrescriber(e, item.Id)}
                          disabled={selectedPrescriber && true}
                        />
                      </div>
                    ))}
                  </div>
                  {selectedPrescriber && (
                    <div>
                      <div className='queue-note'>
                        Call{' '}
                        {
                          availablePrescribers.find(
                            (item) => item.Id === selectedPrescriber
                          )?.Name
                        }{' '}
                        ?
                      </div>
                      <div className='mt-30'>
                        <Button
                          onClick={() => onPlaceCall(selectedPrescriber)}
                          style={{ minWidth: '165px', marginRight: '10px' }}
                        >
                          Place Call
                        </Button>
                        <Button
                          onClick={onCancel}
                          style={{ minWidth: '165px' }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img
                    src={prescriberIcon}
                    className='noprescribers-icon'
                    alt='prescribers icon'
                  />
                  <div className='queue-note'>
                    No Prescribers are immediately available
                  </div>
                </React.Fragment>
              )}
            </div> 
          </div>*/}
          <div className='container'>
            <div className='queue-wrapper'>
              <div className='queue-title'>You are in queue</div>
              <div className='queue-timer-wrapper'>
                <img src={timerBg} alt='prescribers icon' />
                <Countdown queueminutes={vConsult.waitTime} queuesecond={0} />
              </div>
              <div className='queue-note'>
                You are in queue for the next available prescriber
              </div>
              {/* <div className='mt-30'>
                <Button>You are in Queue</Button>
              </div> */}
              <div className='fs-16 mt-30'>
                Please note that the estimated wait time is based on historical
                data, and may not be 100% accurate. A prescriber will be with
                you as soon as possible, please be patient.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Queue;
