import React, { useState } from 'react';
import '../TextInput/TextInput.css';

const TextArea = React.forwardRef((props, ref) => {
  const { label, controlId, error="", value: propValue, onChange, ...otherProps } = props;
  const [value, setValue] = useState(propValue || '');

  const normalizeText = (text) => {
    return text.replace(/'/g, '');
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const normalizedValue = normalizeText(value); 
    setValue(normalizedValue); 
    if (onChange) {
      onChange(normalizedValue);
    }
  };

  return (
    <div className="floating-input">
      <textarea
        ref={ref}
        className="text-input"
        id={controlId}
        placeholder=" "
        value={value} 
        onChange={handleInputChange}
        {...otherProps}
      />
      <label className="floating-label" htmlFor={controlId}>{label}</label>
      {error && <span className="errors-message">{error}</span>}
    </div>
  );
});

export default TextArea;
