import React from 'react';
import {
  TextInput,
  SelectBox,
  Button,
} from '../../../../components/FormElements';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import '../Users/Users.css';
import { brands, states } from '../../../../constants';
import { updateClinic } from '../../../../app/reducers/clinicsSlice';

const EditClinic = (props) => {
  const dispatch = useDispatch();

  const getSelectedOptionLabel = (options, value) => {

    let selected = options.filter((f) => f.value === value);
    if (selected.length > 0) {
      return selected[0];
    } else {
      return '';
    }
  };


  const getSelectedBrandLabel = (options, value) => {

    let selected = options.filter((f) => f.label === value);
    if (selected.length > 0) {
      return selected[0];
    } else {
      return '';
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ClinicName: props.data[0].Name,
      State: getSelectedOptionLabel(states, props.data[0].State),
      Brand: getSelectedBrandLabel(brands, props.data[0].Brand),
    },
  });

  const onSubmit = (data) => {
    dispatch(updateClinic(
      {
        Id: props.data[0].Id,
        State: data.State.value,
        Brand: data.Brand.value,
      }
    ))
    props.onHide();
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='add-user-form'>
          <Controller
            name='ClinicName'
            control={control}
            render={({ field }) => (
              <TextInput
                label='Clinic Name'
                controlId='ClinicName'
                error={errors.ClinicName && true}
                disabled={true} 
                {...field}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'Clinic Name is required!',
              },
            }}
          />
          <Controller
            name='State'
            control={control}
            render={({ field }) => (
              <SelectBox
                label='State'
                options={states}
                name='State'
                error={errors.State && true}
                {...field}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'State is required!',
              },
            }}
          />
          <Controller
            name='Brand'
            control={control}
            render={({ field }) => (
              <SelectBox
                label='Brand'
                options={brands}
                name='Brand'
                error={errors.Brand && true}
                {...field}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'Brand is required!',
              },
            }}
          />
        </div>
        <div className='modal-footer'>
          <div className='cancel-link' onClick={() => props.onHide()}>
            Cancel
          </div>
          <Button size='sm'>Update Clinic</Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default EditClinic;
