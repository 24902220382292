import React, { useState, useEffect } from 'react';
import Heading from '../../components/Heading/Heading';
import Table from '../../components/Table/Table';
import { AdverseEventList } from '../../constants';
import Pagination from '../../components/Pagination/Pagination';
import './AdverseEvent.css';
import { useNavigate, useLocation } from 'react-router-dom';

import sortClinicIcon from '../../assets/images/icons/sort-name-icon.svg';
import sortClinicUpIcon from '../../assets/images/icons/sort-name-up-icon.svg';

import sortDoctorIcon from '../../assets/images/icons/sort-role-icon.svg';
import Toast from '../../components/Toast/Toast';
import { getAdverseEvent, getAdverseEvents } from '../../app/reducers/adverseEventsSlice';
import { useDispatch, useSelector } from 'react-redux';

const AdverseEvent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [currentPageIndex, setCurrentPageIndex] = useState(1);  
  const [order, setSortOrder] = useState('ASC');
  const [sortedColumn, setSortedColumn] = useState('');

  const pageSize = 20;

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

   useEffect(() => {
    let data = { PageIndex:1, PageSize:pageSize };
    dispatch(getAdverseEvents({data:data}));
  }, []);

  const handleSort = (columnId) => {
    if (sortedColumn == "" || sortedColumn === columnId) {
      setSortOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } 
    setSortedColumn(columnId);
    setCurrentPageIndex(1);
    let data = { PageIndex:1, PageSize:pageSize, Parameter:columnId, Order: order === 'ASC' ? 'DESC' : 'ASC' };
    dispatch(getAdverseEvents({data:data}));
  };

  const adverseEventsSelector = useSelector((state) => state.adverseEvents.adverseEvents);
  const [isShow, setIsShow] = useState(true)

  const columns = [
    {
      label: 'Patient',
      controlId: 'PatientName',
      sortable: true,
    },
    {
      label: 'Date',
      controlId: 'StrAdDate',
      sortable: true,
    },
    {
      label: 'Clinic',
      controlId: 'ClinicName',
      sortable: true,
      sortIconDown: sortClinicIcon,
      sortIconUp: sortClinicUpIcon,
    },
    {
      label: 'Prescribing Doctor',
      controlId: 'Prescriber',
      sortable: true,
      sortIconDown: sortDoctorIcon,
      sortIconUp: sortDoctorIcon,
    },
    {
      label: 'Product',
      controlId: 'Product',
      sortable: true,
    }
  ];

  const handleViewClick = (rowId) => {
      dispatch(
        getAdverseEvent({
          id: rowId,
          navigate,
          redirectUrl: '/adverseevent/view',
        })
      );
  };

  const action = [
    { id: 1, label: 'View', onclick: handleViewClick },
  ];
  const handleToastClose = () => setIsShow(false);
  const onPageIndexChange = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    const data = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      ...(sortedColumn && { Parameter: sortedColumn }),
      ...(order && { Order: order }),
    };
    dispatch(getAdverseEvents({data:data}));
  };

  return (
    <React.Fragment>
      <Heading title="Adverse Event" />
      {/* <div className="adverse-search-wrapper">
        <div className="adverse-search">
          <TextInput
            label="Find an appointment"
            controlId="appointment"
            addonType="search"
          />
        </div>
        <Button variant='secondary' onClick={() => navigate('/adverseevent/add')}>Add New</Button>
      </div> */}
      {location?.state?.isSuccess && (
        <div className="success-msg-wrapper">
          <Toast
            show={isShow}
            onClose={handleToastClose}
            title="You have successfully added a new adverse event."
          />
        </div>
      )}
      <div>
        <Table data={adverseEventsSelector} 
        columns={columns} 
        action={action} 
        handleSort={handleSort}/>
        <Pagination pageSize={pageSize} currentPageIndex={currentPageIndex} totalRecords={adverseEventsSelector?.totalCount} onPageChange={e => onPageIndexChange(e)} />
      </div>
    </React.Fragment>
  );
};

export default AdverseEvent;

