import React from 'react';
import { TextInput, Button } from '../../components/FormElements';
import { useForm } from 'react-hook-form';

const ConfirmDeclineTreatment = (props) => {
  const {
    onHide,
    message,
    buttonText,
    data,
    handleDecline,
  } = props;

  const declineAction = () => {
    props.handleDelete(props.data[0].Id);
  };

  const { register, handleSubmit, formState: { errors } } = useForm();


  const onSubmit = (data) => {
    props.handleDecline();
  };
  return (
    <React.Fragment>
      <div>
        <div className='deactivate-text'>
          <p>{message}</p>
        </div>
      </div>
      <div className='modal-footer'>
        <div className='cancel-link' onClick={() => onHide()}>
          Cancel
        </div>
        <Button size='sm' onClick={handleSubmit(onSubmit)}>
          {buttonText}
        </Button>
      </div>
    
    </React.Fragment>
  );
};

export default ConfirmDeclineTreatment;
