import React, { useState, useEffect } from 'react';

import './TreatmentPlans.css';
import Heading from '../../components/Heading/Heading';
import DeclinedIcon from '../../assets/images/icons/declined-icon.svg';
import ApprovedIcon from '../../assets/images/icons/approved-icon.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Outcome = () => {
  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);
  const statusId = treatmentPlanSelector?.treatmentStatusId;
  const isApproved = statusId === 5;
  const [administeredModalShow, setAdministeredModalShow] = useState(false);

  // const handleAdministered = () => {
  //   setAdministeredModalShow(true);
  // };
  // const handleAdministeredModalClose = () => setAdministeredModalShow(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  if(statusId === 5 || statusId === 7)
  {return (
    <React.Fragment>
      <Heading title="Treatment Plan" />
      {isApproved ? (
        <div className="outcome-wrapper">
          <img src={ApprovedIcon} />
          <div className="outcome-text">
            <strong>The treatment plan is approved.</strong>
            <p>
              You can continue with treatment. Once you’ve administered,
              remember to create your drug book entries for this appointment by
              clicking{" "}
              <u>
                <button className="popup-link" onClick={() => navigate('/appointments/details')}>
                  {" "}
                  here
                </button>
              </u>
            </p>
          </div>
        </div>
      ) : (
        <div className="outcome-wrapper">
          <img src={DeclinedIcon} />
          <div className="outcome-text">
            <strong>The treatment plan is not approved.</strong>
            <p>
              Based on the provided information, we can not finalise your
              treatment plan at present.
            </p>
          </div>
        </div>
      )}
      {/* <Modal
        show={administeredModalShow}
        onHide={handleAdministeredModalClose}
        title="Add Drugs Administered"
      >
        <AddAdministered onHide={handleAdministeredModalClose} />
      </Modal> */}
    </React.Fragment>
  );
};
}

export default Outcome;
