import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import '../Appointments.css';
import { Button, SelectBox, TextArea, TextInput } from '../../../components/FormElements';
import { useSelector, useDispatch } from 'react-redux';
import { currentdate } from '../../../utilities/dateUtils';
import {
  submitDetails,
} from '../../../app/reducers/appointmentSlice';

const DischargeSummary = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [fileLimit, setFileLimit] = useState(false);

  const [drugDettails, setDrugDettails] = useState([]);
  const appoinmentsSelector = useSelector((state) => state.appointment);
  const userSelector = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const patientName =  appoinmentsSelector.patient && appoinmentsSelector.patient.FirstName + ' ' + appoinmentsSelector.patient.LastName;
  const patintId = appoinmentsSelector.patient && appoinmentsSelector.patient.Id;
  const clinicName = appoinmentsSelector.appointmentDetails.clinicName && appoinmentsSelector.appointmentDetails.clinicName;
  const clinicId = appoinmentsSelector.appointmentDetails.clinicId && appoinmentsSelector.appointmentDetails.clinicId;
  const userId = userSelector.id;
  const userName = userSelector.userName;
  const isCompleted = appoinmentsSelector.appointmentDetails && appoinmentsSelector.appointmentDetails.AppointmentStatus && appoinmentsSelector.appointmentDetails.AppointmentStatus;

  const dischargeSummaries = appoinmentsSelector.dischargeSummaries && appoinmentsSelector.dischargeSummaries;


  if(dischargeSummaries && dischargeSummaries.length > 0 && drugDettails.length === 0) {
    dischargeSummaries.map(d => 
      setDrugDettails([...drugDettails, {...d}])
    )
  }
  

  const MAX_COUNT = 5;
  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
        // if (uploaded.findIndex((f) => f.name === file.name) === -1) {
            uploaded.push(file);
            if (uploaded.length === MAX_COUNT) {setFileLimit(true)};
           
        // }
    })
    
    if (!limitExceeded) setUploadedFiles(uploaded)
  }

  const handleFileEvent =  (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    //e.target.value = '';
    handleUploadFiles(chosenFiles);
  }

  const removeFile = (i) => {
   
    uploadedFiles.splice(i, 1);
    setUploadedFiles([...uploadedFiles]);

    setFileLimit(false);
  }


  const isnonInjectiblesExists = () => {
    let Plans = appoinmentsSelector.treatmentPlans.filter(plan => (plan.isSelected === true && plan?.treatmentInfo?.packageType !== "injectibles"))

    return Plans;
  }


  const getProduct = () => {
    let Plans = appoinmentsSelector.treatmentPlans.filter(plan => (plan.isSelected === true && plan?.treatmentInfo?.packageType === "injectibles"))
    let products = [];

    Plans.map(d => {
      const treatmentInfo = d.treatmentInfo;
      Object.keys(treatmentInfo).map(tiData => {
        if (Array.isArray(treatmentInfo[tiData])) {

          const existingProduct = products.map(prodData => prodData.label);

          let productsList = treatmentInfo[tiData];
          let isProductInside = productsList.filter(prodData => prodData?.products?.length).length > 0;

          if (isProductInside) {
            productsList = [];
            treatmentInfo[tiData].filter(prodData => prodData?.products?.length).map(prodData => {
              productsList = productsList.concat(prodData.products);
            })
          }

          const filterProductList = productsList
            .filter(prodData => {
              if ((!existingProduct.includes(prodData.productName) && prodData.productId !== undefined)) {
                existingProduct.push(prodData.productName)
                return true;
              }
              return false;
            })
            .map(prodData => ({ value: (prodData.productId || prodData.productName), label: prodData.productName }));
          products = products.concat(filterProductList)

        }
      })
    })

    return products;
  };

  const getTreatmentAreas = () => {
    let Plans = appoinmentsSelector.treatmentPlans.filter(plan => (plan.isSelected === true && plan?.treatmentInfo?.packageType == "injectibles"))
    let treatmentAreas = [];
    Plans.map(d => {
      const treatmentInfo = d.treatmentInfo;
      Object.keys(treatmentInfo).map(tiData => {
        if (Array.isArray(treatmentInfo[tiData])) {

          let treatmentAreaList = treatmentInfo[tiData];

          let isTreatmentAreasInside = treatmentAreaList.filter(prodData => ('treatmentAreas' in prodData)).length > 0;

          if (isTreatmentAreasInside) {
            treatmentAreaList = [];
            treatmentInfo[tiData].filter(prodData => ('treatmentAreas' in prodData)).map(prodData => {
              treatmentAreaList = treatmentAreaList.concat(prodData.treatmentAreas);
            })
          }

          if (tiData !== 'other') {
            const existingNames = treatmentAreas.map(tData => tData.label);
            treatmentAreas = treatmentAreas.concat(treatmentAreaList.filter(tData => {
              if ((existingNames.indexOf(tData.areaName) === -1)) {
                existingNames.push(tData.areaName);
                return true;
              }
              return false;
            }).map( tData => ({ label: tData.areaName, value: tData.areaName})));
          }

        }
      })
    });
    return treatmentAreas;
  }

  const {
    control: controlDetails,
    handleSubmit: handleSubmitDetails,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
    }
  });


  const {
    control,
    handleSubmit,
    formState: { errors: formError }
  } = useForm(
    {
      defaultValues: {
        prpThreadsSummary: appoinmentsSelector.appointmentDetails.PrpNotes,
        notes: appoinmentsSelector.appointmentDetails.DischargeNotes
      }
    }
  );

  const onDataSubmit = (data, e) => {
    setDrugDettails([...drugDettails, {...data, patientId: patintId,patientName: patientName, clinicId:clinicId, clinicName:clinicName , administeredDate: currentdate(), administeredUserId: userId, administeredUserName: userName}]);
    reset(
      {
        "volume": "",
        "productName": "",
        "treatmentArea": "",
        "batchNumber": ""
      }
    );
  };
  const removeData = (i) => {
    drugDettails.splice(i, 1);
    setDrugDettails([...drugDettails]);
  }

  const onSubmit = (formData) => {

    var data = {
      dischargeSumaries:drugDettails,
      ...formData,
      appointmentId: appoinmentsSelector.appointmentDetails.AppointmentId && appoinmentsSelector.appointmentDetails.AppointmentId,
      files: uploadedFiles,
    }

    dispatch(
      submitDetails({
        data: data,
        navigate,
        redirectUrl: '/treatmentplan/outcome',
      })
    );
    
  }
 

  return (
    <React.Fragment>

      {(getTreatmentAreas().length > 0 || getProduct().length > 0 ) &&
      <React.Fragment>
        {!isCompleted && <React.Fragment> 
        <div className='section-title'>Add drugs administered details</div>
      <form key={1} onSubmit={handleSubmitDetails(onDataSubmit)}>
        <div className='discharge-control'>
          <div className='discharge-control-item'>
            <Controller
              control={controlDetails}
              name="volume"
              render={({ field }) => (
                <TextInput
                  label="Volume"
                  controlId="volume"
                  {...field}
                  error={errors.volume && errors.volume.message}
                 
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Volume is required!',
                },
              }}
            />
          </div>
          <div className='discharge-control-item'>
            <Controller
              control={controlDetails}
              name="productName"
              render={({ field }) => (
                <SelectBox
                  label="Product"
                  options={getProduct()}
                  {...field}
                  error={errors.productName && errors.productName.message}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Product is required!',
                },
              }}
            />
          </div>

          <div className='discharge-control-item'>
            <Controller
              control={controlDetails}
              name="treatmentArea"
              render={({ field }) => (
                <SelectBox
                  label="Treatment Area"
                  options={getTreatmentAreas()}
                  {...field}
                  error={errors.treatmentArea && errors.treatmentArea.message}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Treatment area is required!',
                },
              }}
            />
          </div>
          <div className='discharge-control-item'>
            <Controller
              control={controlDetails}
              name="batchNumber"
              render={({ field }) => (
                <TextInput
                  label="Batch Number"
                  controlId="batchNumber"
                  {...field}
                  error={errors.batchNumber && errors.batchNumber.message}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Batch number is required!',
                },
              }}
            />
          </div>
          <div className='discharge-control-item'>
            <Button type="submit">
              Add New
            </Button>
          </div>

        </div>
      </form>
      </React.Fragment>}
      <div className='section-title'>Drugs administered details</div>
      <div className='discharge-table-wrapper'>
        <table className='discharge-table'>
          <thead>
            <tr>
              <th>Volume</th>
              <th>Product</th>
              <th>Treatment Area</th>
              <th>Batch Number</th>
              {/* <th>Patient Name</th> */}
              <th>Clinic</th>
              <th>Date Administered</th>
              <th>Administered By</th>
              <th></th>
            </tr>
          </thead>
          <tbody>


            {drugDettails.length !== 0 && drugDettails.map((item, index) =>
              <tr key={index}>
                <td>{item.volume}</td>
                <td>{item.productName?.label}</td>
                <td>{item.treatmentArea?.label}</td>
                <td>{item.batchNumber}</td>
                {/* <td>{item.patientName}</td> */}
                <td>{item.clinicName}</td>
                <td>{item.administeredDate}</td>
                <td>{item.administeredUserName}</td>
                {!isCompleted &&
                <td className='remove-link'>
                  <a onClick={() => removeData(index)}>Remove</a>
                </td>
                }
              </tr>
            )}
          </tbody>
        </table>
      </div>
      </React.Fragment>}
      
      <form key={2} onSubmit={handleSubmit(onSubmit)}>
        {isnonInjectiblesExists().length > 0 &&
          <div className='row'>
              <div className="col-md-6">
                <div className='section-title'>Add PRP/Threads Summary here</div>
                <Controller
                  control={control}
                  name="prpThreadsSummary"
                  render={({ field }) => (
                    <TextArea
                      label="PRP/Threads Summary"
                      rows={8}
                      controlId="prpThreadsSummary"
                      {...field}
                      error={formError.prpThreadsSummary && formError.prpThreadsSummary.message}
                      disabled ={isCompleted}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'PRP/Threads treatment summary is required!',
                    },
                  }}
                />
              </div>
            </div>}
        <div className='row'>
          <div className="col-md-6">
            <div className='section-title'>Add your comment here</div>
           
            <div>These notes will not be shared with the client. The notes can be found in the treatment summary form in Zenoti</div>
            <Controller
              control={control}
              name="notes"
              render={({ field }) => (
                <TextArea
                  label="Treatment Comment"
                  rows={8}
                  controlId="notes"
                  {...field}
                  error={formError.notes && formError.notes.message}
                  disabled ={isCompleted}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Treatment Comment is required!',
                },
              }}
            />
          </div>
        </div>
        {! isCompleted && <div>
        <div className='section-title'>Upload Photos

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input id='fileUpload' type='file' multiple
                accept='image/*'
                          onChange={handleFileEvent}
                          disabled={fileLimit}
            />
          </div>

          <div className="uploaded-files-list">
            
          {uploadedFiles.map((file, index) => (
                <div>
                      <div >
                          {file.name}
                          &nbsp;&nbsp;
                          <Button size='sm' type="button" onClick={() => removeFile(index)} >
                          Remove
                          </Button>
                      </div>
                  </div>
                  ))}
        </div>
        <hr className="divider mt-20 mb-50" />

        <div className="flex justify-end">
          <Button
            type="submit"
           // disabled={drugDettails.length === 0 && true}
          >
            Send Treatment Summary
          </Button>
        </div>
        </div>
        }
      </form>
    </React.Fragment>
  );
};

export default DischargeSummary;
