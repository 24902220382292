import React, { useEffect, useState } from 'react';

import '../Reporting.css';
import { Button, Calendar, SelectBox } from '../../../components/FormElements';
import Table from '../../../components/Table/Table';
import Pagination from '../../../components/Pagination/Pagination';
import { callPerformance } from '../../../constants';
import callTimeIcon from '../../../assets/images/icons/callTime.svg';
import waitingTimeIcon from '../../../assets/images/icons/waitingTime.svg';
import highVolumeIcon from '../../../assets/images/icons/highVolume.svg';
import { register } from '../../../serviceWorkerRegistration';
import { Controller, useForm } from 'react-hook-form';
import AutocompleteNurse from '../../../components/AutocompleteNurse/AutocompleteNurse';
import AutocompletePrescriber from '../../../components/AutocompletePrescriber/AutocompletePrescriber';
import AutocompleteClinic from '../../../components/AutocompleteClinic/AutocompleteClinic';
import { getAppointment } from '../../../app/reducers/appointmentSlice';
import { getCallPerformanceReport, emailCallPerformanceReport } from '../../../app/reducers/reportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CallPerformance = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const pageSize = 20;
  
    const {
      control,
      register,
      handleSubmit,
      watch,
      formState: { errors, isValid },
    } =useForm({
      defaultValues: {
        Clinic: 0,
        Prescriber: 0,
        Nurse: 0
      },
    });
  
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchData, setSearchData] = useState({});
    const [clinicId, setClinicId] = useState(0);
    const [prescriberId, setPrescriberId] = useState(0);
    const [nurseId, setNurseId] = useState(0);
  
    const reportSelector = useSelector((state) => state.report);
  
    const onPageIndexChange = (pageIndex) => {
      setCurrentPageIndex(pageIndex);
      if (searchClicked) {
        dispatch(
          getCallPerformanceReport({
            ...searchData,
            PageIndex: pageIndex,
            PageSize: pageSize,
          })
        );
      } else {
        dispatch(
          getCallPerformanceReport({
            PageIndex: pageIndex,
            PageSize: pageSize,
          })
        );
      }
    };
  
    useEffect(() => {
      setCurrentPageIndex(1);
      dispatch(getCallPerformanceReport(searchData));
    }, [searchData]);
  
    const handleViewClick =  async (row) => {
      dispatch(
        await getAppointment({
          id: row.AppointmentId,
          navigate,
          redirectUrl: '/appointments/details',
        })
      );
    };

    const columns = [
    {
        label: "Clinic",
        controlId: "ClinicName",
        sortable: true,
    },
    {
        label: "Nurse",
        controlId: "NurseName",
        sortable: true,
    },
    {
        label: "Prescriber",
        controlId: "PrescriberName",
        sortable: true,
    },
    {
        label: "Date",
        controlId: "StrConsultationDate",
        sortable: true,
    },
    {
        label: "Waited",
        controlId: "StrWaitedTime",
        sortable: true,
    },
    {
        label: "Duration",
        controlId: "StrDuration",
        sortable: true,
    }
    // {
    //   label: 'Appointment',
    //   controlId: 'appointment',
    //   sortable: true,
    // }
  ];

  const action = [
    { id: 1, label: 'View Appointment',  onclick: handleViewClick }
  ];

  const watchData = watch();

  const validate = {
    isClinic: watchData.Clinic && watchData.Clinic.length >= 3 ? true : false,
    isPrescriber: watchData.Prescriber && watchData.Prescriber.length >= 3 ? true : false,
    isNurse: watchData.Nurse && watchData.Nurse.length >= 3 ? true : false,
    isFromDate: fromDate ? true : false,
    isToDate: toDate ? true : false,  
  };

  const validateAtLeastOneField = () => {
    const isValidate = Object.entries(validate).filter(
      ([key, value]) => value === true
    );
    return isValidate.length >= 2 ? true : false;
  };

  const generateExcel = async () => {
    dispatch(emailCallPerformanceReport(searchData));
  }

  const onSubmit = (data) => {
    setSearchClicked(true);
    setCurrentPageIndex(1);
    const updatedData = {
      ClinicId: clinicId,
      PrescriberId: prescriberId,
      NurseId: nurseId,
      fromDate: fromDate
        ? new Date(
          fromDate.getTime() - fromDate.getTimezoneOffset() * 60000
          ).toISOString()
        : null,
        toDate: toDate
        ? new Date(
          toDate.getTime() - toDate.getTimezoneOffset() * 60000 + 86400000 - 1
          ).toISOString()
        : null,
      PageIndex: currentPageIndex,
      PageSize: pageSize,
    };
    setSearchData(updatedData);
  };

  return (
    <React.Fragment>
    <div className="mt-20">
        <div className="row">
        {/* <div className="search-control-wrapper">
        <div className='search-control'> */}
        {/* <div className="search-control"> }
            <RangeCalendar
              label='Date Range'
              controlId='dateRange'
              onChange={(date) => console.log('date', date)}
            />
          </div> */}
          <div className='col-md'>
            <Calendar
              name="FromDate"
              label="From Date"
              controlId="fromDate"
              onChange={(date) => setFromDate(date)}
            />
          </div>
          <div className='col-md'>
            <Calendar
              label="To Date"
              controlId="toDate"
              onChange={(date) => setToDate(date)}
            />
          </div>
          <div className='col-md'>
            <Controller
                name='Clinic'
                control={control}
                render={({ field: { onChange, value } }) => (                  
                  <AutocompleteClinic
                    label='Clinic'
                    controlId='Clinic'
                    onValueChange={(e,i) => {
                      onChange(e);
                      setClinicId(i);
                    }}
                  />
                )}
                {...register("Clinic", { validate: validateAtLeastOneField })}
              />
          </div>
          <div className='col-md'>
            <Controller
                name='Prescriber'
                control={control}
                render={({ field: { onChange, value } }) => (                  
                  <AutocompletePrescriber
                    label='Prescriber'
                    controlId='Prescriber'
                    onValueChange={(e,i) => {
                      onChange(e);
                      setPrescriberId(i);
                    }}
                  />
                )}
                {...register("Prescriber", { validate: validateAtLeastOneField })}
              />
          </div>
          <div className='col-md'>
            <Controller
                name='Nurse'
                control={control}
                render={({ field: { onChange, value } }) => (                  
                  <AutocompleteNurse
                    label='Nurse'
                    controlId='Nurse'
                    onValueChange={(e,i) => {
                      onChange(e);
                      setNurseId(i);
                    }}
                  />
                )}
                {...register("Nurse", { validate: validateAtLeastOneField })}
              />
          </div>

          <div className='col-md'>
          <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!validateAtLeastOneField()}
        >
          Search
        </Button>
        </div>
        </div>
      </div>
      
      <div className='row'>
        <div className='col-md-4'>
          <div className='report-info-item'>
            <img src={callTimeIcon} />
            <div className='report-info'>
              <div className='report-number text-pink'>{reportSelector.avgCallTime}</div>
              <p>Average call time </p>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='report-info-item'>
            <img src={waitingTimeIcon} />
            <div className='report-info'>
              <div className='report-number text-tan'>{reportSelector.avgWaitTime}</div>
              <p>Average waiting time</p>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='report-info-item'>
            <img src={highVolumeIcon} />
            <div className='report-info'>
              <div className='report-number text-blue'>{reportSelector.numberOfCalls}</div>
              <p>Calls where the wait time exceeds 5 minutes</p>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-30'>
        <Table data={reportSelector.callPerformanceReport} columns={columns} />
        <Pagination
        pageSize={pageSize}
        currentPageIndex={currentPageIndex}
        totalRecords={reportSelector.callPerformanceCount}
        onPageChange={(e) => onPageIndexChange(e)}
      />
       <div
          className="search-button generate-excel-button" 
        >
          <Button onClick ={generateExcel}>Email Billing Report</Button>
        </div>
      </div>
    </React.Fragment>
  )
};

export default CallPerformance;