import React, { useState, useCallback, useEffect } from "react";
import "./Tabs.css";

const Tabs = ({ children, activeTabIndex, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(
    activeTabIndex !== undefined && activeTabIndex !== null && activeTabIndex !== ''
      ? activeTabIndex
      : children[0].props.controlId
  );

  const handleActiveTab = useCallback(
    (controlId) => {
      setActiveTab(controlId);
      if (onTabChange) {
        onTabChange(controlId);
      }
    },
    [onTabChange]
  );

  useEffect(() => {
    if (activeTabIndex !== undefined && activeTabIndex !== null && activeTabIndex !== '') {
      setActiveTab(activeTabIndex);
    }
  }, [activeTabIndex]);

  const tabs = children.map((child) => (
    <button
      onClick={(e) => {
        e.preventDefault();
        !child.props.isTabDisabled && handleActiveTab(child.props.controlId);
      }}
      className={
        child.props.controlId === activeTab
          ? ["tab-item", "isActive"].join(" ")
          : child.props.isTabDisabled
          ? "tab-item tab-disabled"
          : "tab-item"
      }
      key={child.props.controlId}
    >
      {child.props.label}
    </button>
  ));
  const tabContent = children.filter((child) => child.props.controlId === activeTab);
  return (
    <div className="tab-wrapper">
      <div className="tabs">{tabs}</div>
      <div className="tab-panel">{tabContent}</div>
    </div>
  );
};

const Tab = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

export { Tabs, Tab };
