import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading/Heading";
import { useNavigate, useLocation } from "react-router-dom";
import { Calendar, TextInput, Button } from "../../components/FormElements";
import Table from "../../components/Table/Table";
import ViewToggle from "../../components/ViewToggle/ViewToggle";
import Pagination from "../../components/Pagination/Pagination";
import AppointmentCard from "../../components/AppointmentCard/AppointmentCard";

import sortNamecIcon from "../../assets/images/icons/sort-name-icon.svg";
import sortNamecUpIcon from "../../assets/images/icons/sort-name-up-icon.svg";
import noDataIcon from "../../assets/images/icons/no-patient-icon.svg";

import "./Appointments.css";
import NoData from "../../components/NoData/NoData";
import {
  deleteAppointment,
  getAppointment,
  searchAppointments,
} from "../../app/reducers/appointmentSlice";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal/Modal";
import Deactivate from "../../components/Deactivate/Deactivate";

import Toast from "../../components/Toast/Toast";
import { Controller, useForm } from "react-hook-form";
import AutocompleteNurse from "../../components/AutocompleteNurse/AutocompleteNurse";
import AutocompleteClinic from "../../components/AutocompleteClinic/AutocompleteClinic";
import { Roles } from "../../constants";

const Appointments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const pageSize = 20;

  const appoinmentsSelector = useSelector((state) => state.appointment);
  const userClinicId = useSelector((state) => state.userClinic.UserClinicId);
  const authenticationSelector = useSelector((state) => state.authentication);

  useEffect(() => {
    let data = { PageIndex: 1, PageSize: pageSize, ClinicId: userClinicId };
    dispatch(searchAppointments({ data: data }));
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      window.history.go(1);
    });
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm();

  const [deactivateModalShow, setDeactivateModalShow] = useState(false);

  const [displayView, setDisplayView] = useState("list");
  const [searchAppointment, setSearchAppointment] = useState("");
  const [isShow, setIsShow] = useState(true);

  const [searchFor, setSearchFor] = useState("");
  const [AppDate, setAppDate] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [order, setSortOrder] = useState("ASC");
  const [sortedColumn, setSortedColumn] = useState("");
  const [clinicId, setClinicId] = useState(userClinicId);
  const [nurseId, setNurseId] = useState(0);

  const handleViewClick = (rowId) => {
    if ((authenticationSelector.role === Roles.Reception)) {
      dispatch(
        getAppointment({
          id: rowId,
          navigate,
          redirectUrl: "/appointments/receptiondetails",
        })
      );
    } else {
      dispatch(
        getAppointment({
          id: rowId,
          navigate,
          redirectUrl: "/appointments/details",
        })
      );
    }
  };

  const handleDeactiveClick = (rowId) => {
    dispatch(deleteAppointment({ id: rowId }));
    setDeactivateModalShow(true);
  };

  const handleToastClose = () => setIsShow(false);

  const handleDeactivateModalClose = () => setDeactivateModalShow(false);

  const handleSort = (columnId) => {
    if (sortedColumn == "" || sortedColumn === columnId) {
      setSortOrder(order === "ASC" ? "DESC" : "ASC");
    }
    setSortedColumn(columnId);
    setCurrentPageIndex(1);
    let data = {
      PageIndex: 1,
      PageSize: pageSize,
      Parameter: columnId,
      Order: order === "ASC" ? "DESC" : "ASC",
      ClinicId: clinicId ? clinicId : userClinicId,
    };
    dispatch(searchAppointments({ data: data }));
  };

  const columns = [
    {
      label: "Patient",
      controlId: "PatientName",
      sortable: true,
      sortIconDown: sortNamecIcon,
      sortIconUp: sortNamecUpIcon,
      onClick: () => handleSort("PatientName"),
    },
    // {
    //   label: "Med History Updated",
    //   controlId: "StrLatestMedHistroyDate",
    //   sortable: false,
    // },
    {
      label: "AppDate",
      controlId: "StrAppointmentStartDate",
      sortable: true,
      onClick: () => handleSort("AppDate"),
    },
    {
      label: "Clinic",
      controlId: "Clinic",
      sortable: true,
      onClick: () => handleSort("Clinic"),
    },
    {
      label: "Nurse",
      controlId: "Nurse",
      sortable: true,
      onClick: () => handleSort("Nurse"),
    },
    {
      label: "Status",
      controlId: "AppointmentStatusLabel",
      sortable: false,
    },
  ];

  const action = [
    { id: 1, label: "View", onclick: handleViewClick },
    { id: 2, label: "Deactivate", onclick: handleDeactiveClick },
  ];

  const filterAppointments = (item) => {
    const nameMatches =
      !searchAppointment ||
      item.PatientName.toLowerCase().includes(searchAppointment.toLowerCase());
    const dateMatches =
      !AppDate ||
      new Date(item.AppointmentStartDate).toLocaleDateString("en-GB") ===
        AppDate.toLocaleDateString("en-GB");
    const clinicMatches = !clinicId || item.ClinicId == clinicId;
    const nurseMatches = !nurseId || item.NurseId == nurseId;

    return nameMatches && dateMatches && clinicMatches && nurseMatches;
  };

  const watchData = watch();

  const validate = {
    isClinic: watchData.Clinic && watchData.Clinic.length >= 3 ? true : false,
    isNurse: watchData.Nurse && watchData.Nurse.length >= 3 ? true : false,
    isDate: AppDate ? true : false,
    isAppointment:
      searchAppointment && searchAppointment.length >= 3 ? true : false,
  };

  const validateAtLeastOneField = () => {
    const isValidate = Object.entries(validate).filter(
      ([key, value]) => value === true
    );
    return isValidate.length >= 2 ? true : false;
  };

  const onSubmit = () => {
    setSearchFor(
      (searchAppointment !== undefined ? searchAppointment : "") +
        " " +
        (AppDate !== undefined && AppDate !== null
          ? new Date(AppDate).toLocaleDateString("en-GB")
          : "") +
        " " +
        (watchData.Clinic !== undefined ? watchData.Clinic : "") +
        " " +
        (watchData.Nurse !== undefined ? watchData.Nurse : "")
    );
    const data = {
      PatientName: searchAppointment,
      Date: AppDate
        ? new Date(
            AppDate.getTime() - AppDate.getTimezoneOffset() * 60000
          ).toISOString()
        : null,
      PageIndex: currentPageIndex,
      PageSize: pageSize,
      ...(sortedColumn && { Parameter: sortedColumn }),
      ...(order && { Order: order }),
      ...{ ClinicId: watchData.Clinic ? clinicId : userClinicId },
      ...(watchData.Nurse && { Nurse: nurseId }),
    };
    dispatch(searchAppointments({ data: data }));
  };

  const onPageIndexChange = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    const data = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      ...(sortedColumn && { Parameter: sortedColumn }),
      ...(order && { Order: order }),
      ...{ ClinicId: clinicId ? clinicId : userClinicId },
    };
    dispatch(searchAppointments({ data: data }));
  };

  const inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearchAppointment(lowerCase);
  };

  return (
    <React.Fragment>
      <Heading title="Appointments" />
      <div className="view-toggle-wrapper">
        {<ViewToggle displayView={displayView} onClick={setDisplayView} />}
        <div className="ml-auto">
          <Button
            variant="secondary"
            onClick={() => navigate("/appointments/add")}
          >
            Add New
          </Button>
        </div>
      </div>
      {location?.state?.isSuccess && (
        <div className="success-msg-wrapper">
          <Toast
            show={isShow}
            onClose={handleToastClose}
            title={`You have successfully added a new appointment for ${location?.state?.PatientName}`}
          />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="search-control-wrapper">
          <div className="search-control">
            <div className="search-control-item">
              <TextInput
                label="Find an appointment"
                controlId="searchAppointment"
                value={searchAppointment}
                onChange={inputHandler}
              />
            </div>
            <div className="search-control-item">
              <Calendar
                label="Date"
                controlId="AppDate"
                onChange={(date) => setAppDate(date)}
              />
            </div>

            <div className="col-md">
              <Controller
                name="Clinic"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteClinic
                    label="Clinic"
                    controlId="Clinic"
                    onValueChange={(e, i) => {
                      onChange(e);
                      setClinicId(i);
                    }}
                  />
                )}
                {...register("Clinic", { validate: validateAtLeastOneField })}
              />
            </div>

            <div className="col-md">
              <Controller
                name="Nurse"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteNurse
                    label="Nurse"
                    controlId="Nurse"
                    onValueChange={(e, i) => {
                      onChange(e);
                      setNurseId(i);
                    }}
                  />
                )}
                {...register("Nurse", { validate: validateAtLeastOneField })}
              />
            </div>
          </div>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!validateAtLeastOneField()}
          >
            Search
          </Button>
        </div>
      </form>
      {appoinmentsSelector.totalCount === 0 ? (
        <React.Fragment>
          <NoData
            variant="pink"
            icon={noDataIcon}
            title="Appointment not found"
            caption={[
              "Unfortunately we didn’t find any appointments matching: ",
              <strong>{searchFor}</strong>,
            ]}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {searchFor && appoinmentsSelector.isLoaded && (
            <div className="search-results-no">
              {appoinmentsSelector.appointments?.length} Search results for:{" "}
              <strong>{searchFor}</strong>
            </div>
          )}
          {appoinmentsSelector.isLoaded && displayView === "list" ? (
            <Table
              data={appoinmentsSelector.appointments}
              columns={columns}
              action={action}
              columnsMergeId="PatientName"
              columnsMergeWith={["StrDOB", "Pronouns"]}
              handleSort={handleSort}
            />
          ) : (
            <React.Fragment>
              {appoinmentsSelector.isLoaded && (
                <div className="row appointment-card-view">
                  {appoinmentsSelector.appointments &&
                    appoinmentsSelector.appointments.map((item) => (
                      <div key={item.Id} className="col-md-4">
                        <AppointmentCard
                          data={item}
                          onClick={handleViewClick}
                        />
                      </div>
                    ))}
                </div>
              )}
            </React.Fragment>
          )}
          <Pagination
            pageSize={pageSize}
            currentPageIndex={currentPageIndex}
            totalRecords={appoinmentsSelector.totalCount}
            onPageChange={(e) => onPageIndexChange(e)}
          />
        </React.Fragment>
      )}
      <Modal
        show={deactivateModalShow}
        onHide={handleDeactivateModalClose}
        title="Deactivate Appointment;"
      >
        <Deactivate
          onHide={handleDeactivateModalClose}
          message={"Are you sure you would like to deactivate?"}
          buttonText={"Deactivate Appointment"}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Appointments;
