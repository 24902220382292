import React, { useState } from 'react';
import './Accordion.css';

const Accordion = ({ title, children, isDefaultActive }) => {
  const [isActive, setIsActive] = useState(
    isDefaultActive ? isDefaultActive : false
  );

  return (
    <div className='accordion-item'>
      <div
        className={isActive ? 'accordion-title isActive' : 'accordion-title'}
        onClick={() => setIsActive(!isActive)}
      >
        {isActive ? (
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='20' cy='20' r='18.5' stroke='white' strokeWidth='3' />
            <path
              d='M20 5.21875C27.9688 5.21875 34.5312 11.7812 34.5312 19.75C34.5312 27.7773 27.9688 34.2812 20 34.2812C11.9727 34.2812 5.46875 27.7773 5.46875 19.75C5.46875 11.7812 11.9727 5.21875 20 5.21875ZM20 8.03125C13.4961 8.03125 8.28125 13.3047 8.28125 19.75C8.28125 26.2539 13.4961 31.4688 20 31.4688C26.4453 31.4688 31.7188 26.2539 31.7188 19.75C31.7188 13.3047 26.4453 8.03125 20 8.03125ZM28.2031 15.707C28.4375 15.9414 28.4375 16.4102 28.2031 16.7031L18.0664 26.7227C17.7734 27.0156 17.3633 27.0156 17.0703 26.7227L11.7383 21.332C11.5039 21.0977 11.5039 20.6289 11.7383 20.3359L13.0859 19.0469C13.3789 18.7539 13.7891 18.7539 14.082 19.0469L17.5977 22.5625L25.8594 14.3594C26.1523 14.0664 26.6211 14.0664 26.8555 14.3594L28.2031 15.707Z'
              fill='white'
            />
          </svg>
        ) : (
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='20' cy='20' r='18.5' stroke='#282828' strokeWidth='3' />
          </svg>
        )}

        <div>{title}</div>
      </div>
      {isActive && <div className='accordion-content'>{children}</div>}
    </div>
  );
};

export default Accordion;
