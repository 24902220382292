import React from 'react';
import {
  TextInput,
  SelectBox,
  Button,
  Checkbox
} from '../../../../components/FormElements';
import '../Users/Users.css';
import { useForm, Controller } from 'react-hook-form';
import { updateProduct } from '../../../../app/reducers/productSlice';
import { useDispatch } from 'react-redux';
import ToggleButton from '../../../../components/FormElements/ToggleButton/ToggleButton';
import { brands } from '../../../../constants';

const EditProduct = (props) => {
  const dispatch = useDispatch();

  const manufacturer = [{ value: 'juvederm', label: 'Juvederm' }];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Name: props.data[0].Name,
      Manufacturer: props.data[0].Name,
      silklaserclinics: props.data[0].AvailableFor.includes('Silk Laser Clinics')? true : false,
      australianskinclinics: props.data[0].AvailableFor.includes('Australian Skin Clinics')? true : false,
      edenlaserclinics: props.data[0].AvailableFor.includes('Eden Laser Clinics')? true : false,
    },
  });
  const onSubmit = (data) => {

    let brands = [];
    if (data.silklaserclinics && data.silklaserclinics === true) {
      brands.push(1);
    }
    if (data.australianskinclinics && data.australianskinclinics === true) {
      brands.push(2);
    }
    if (data.edenlaserclinics && data.edenlaserclinics === true) {
      brands.push(3);
    }

    let getProductsPayload ={
      'pageIndex':props.pageIndex,
      'pageSize':20, 
    }
if(props.sortColumn && props.sortOrder!==''){
  getProductsPayload = {
    ...getProductsPayload,
    parameter:props.sortColumn
  }

  if(props.sortOrder !== ''){
    getProductsPayload = {
      ...getProductsPayload,
      order: props.sortOrder
    }
  }
}

    dispatch(
      updateProduct({
        Name: data.Name,
        Manufacturer: data.Manufacturer.value,
        Brands: brands,
        Id: props.data[0].Id,
        getProductsPayload :{...getProductsPayload}
      })
    );
    props.onHide();
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='add-user-form'>
              <Controller
                name='Name'
                control={control}
                render={({ field }) => (
                  <TextInput label='Product Name' controlId='Name' readOnly={true} {...field} />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Product Name is required!',
                  },
                }}
              />
            <Controller
              name='Manufacturer'
              control={control}
              render={({ field }) => (
                <SelectBox
                  label='Manufacturer Name'
                  options={manufacturer}
                  name='Manufacturer'
                  {...field}
                />
              )}
            />
            {/* <Controller
              name='AvailableFor'
              control={control}
              render={({ field }) => (
                <SelectBox
                  label='Available For'
                  options={availablefor}
                  name='AvailableFor'
                  {...field}
                />
              )}
            /> */}
            <div>
              <br/>
              <label className='mb-10'>Available For</label>
            </div>
          <React.Fragment>
                {brands.map((item, k) => (
                  <div className='col-md-6 mb-20' key={k}>
                    <Controller
                      name={item.name}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          label={item.label}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </div>
                ))}
              </React.Fragment>
          <br/>
        </div>
        <div className='modal-footer'>
          <div className='cancel-link' onClick={() => props.onHide()}>
            Cancel
          </div>
          <Button size='sm'>Update Product</Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default EditProduct;
