import React, { useState } from "react";
import Heading from "../../components/Heading/Heading";
import { DrugBookList } from "../../constants";
import Dropdown from "../../components/Dropdown/Dropdown";
import Modal from "../../components/Modal/Modal";
import AddReceived from "./components/AddReceived";
import AddAdministered from "./components/AddAdministered";
import AdministeredDrugBook from "./AdministeredDrugBook";
import ReceivedDrugBook from "./ReceivedDrugBook";
import RemovedDrugBook from "./RemovedDrugBook";
import { SelectBox } from "../../components/FormElements";
import "../DrugBook/DrugBook.css";
import { useDispatch } from "react-redux";
import { Button } from "../../components/FormElements";
import {
  getDrugBookAdministered,
  getDrugBookReceived,
  getDrugBookRemoved,
} from "../../app/reducers/drugbookSlice";
import Toast from "../../components/Toast/Toast";
import { useLocation } from "react-router-dom";
import AddRemoved from "./components/AddRemoved";
import { useEffect } from "react";

const DrugBook = () => {
  const [receivedModalShow, setReceivedModalShow] = useState(false);
  const [removedModalShow, setRemovedModalShow] = useState(false);
  const [viewReceivedModalShow, setViewReceivedModalShow] = useState(false);
  const [administeredModalShow, setAdministeredModalShow] = useState(false);
  const [viewAdministeredModalShow, setViewAdministeredModalShow] =
    useState(false);
  const [rowData, setRowData] = useState("");
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState({"value": "Administered"});
  const [searchClicked, setSearchClicked] = useState(true);
  const [isShow, setIsShow] = useState(true);
  const location = useLocation();
  const pageSize = 20;

  const handleViewClick = (rowId) => {
    let getType = DrugBookList.find((e) => {
      if (rowId) {
        return e.Id === rowId;
      }
    });
    setRowData(getType);
    if (getType?.Type === "Received") {
      setViewReceivedModalShow(true);
    } else if (getType?.Type === "Administered") {
      setViewAdministeredModalShow(true);
    }
  };

  const action = [{ id: 1, label: "View", onclick: handleViewClick }];
  let data = { pageIndex:1, pageSize:pageSize};

  const handleSearchClick = () => {
    if (selectedOption.value === "Received") {
      dispatch(getDrugBookReceived({data:data}));
    } else if (selectedOption.value === "Administered") {
      dispatch(getDrugBookAdministered({data:data}));
    } else if (selectedOption.value === "Removed") {
      dispatch(getDrugBookRemoved({data:data}));
    }
    setSearchClicked(true);
  };

  const options = [
    {
      value: "Received",
      label: "Received",
      Id: 1,
    },
    {
      value: "Administered",
      label: "Administered",
      Id: 2,
    },
    {
      value: "Removed",
      label: "Removed",
      Id: 2,
    },
  ];

  const handleDropdownChange = (value) => {
    setSelectedOption(value);
    setSearchClicked(false);
  };

  const handleReceived = () => {
    setReceivedModalShow(true);
  };

  const handleRemoved = () => {
    setRemovedModalShow(true);
  };

  const handleReceivedModalClose = () => setReceivedModalShow(false);
  const handleRemovedModalClose = () => setRemovedModalShow(false);
  const handleViewReceivedModalClose = () => setViewReceivedModalShow(false);
  const handleViewAdministeredModalClose = () =>
    setViewAdministeredModalShow(false);

  const dropdownItems = [
    { id: 1, label: "Received", onclick: handleReceived },
    { id: 2, label: "Removed", onclick: handleRemoved },
  ];
  const handleToastClose = () => setIsShow(false);

  useEffect(() => {
    dispatch(getDrugBookAdministered({data:data}));
  }, []);

  return (
    <React.Fragment>
      <Heading title="Drug Book" />
      {location?.state?.isSuccess && (
        <div className="success-msg-wrapper">
          <Toast
            show={isShow}
            onClose={handleToastClose}
            title="You have successfully added a new drug book entry."
          />
        </div>
      )}
      <div className="mt-60 text-right">
        <Dropdown label="Add New" items={dropdownItems} />
      </div>
      {/* <div className="search-control-container">
        <div className="search-control-item">
          <div className="select-box-container">
            <SelectBox
              label="Drugbook category"
              options={options}
              name="search"
              value={selectedOption}
              onChange={handleDropdownChange}
            />
          </div>
          <Button onClick={handleSearchClick}>Search</Button>
        </div>
        <div className="button-container">
          <Button onClick={handleSearchClick}>Search</Button>
        </div>
      </div> */}
      
      <div className="search-wrapper">

      <div className="search-dropdown">
        <SelectBox
              label="Drugbook category"
              options={options}
              name="search"
              value={selectedOption}
              onChange={handleDropdownChange}
            />
        </div>
        <div className="search-button">
        <Button onClick={handleSearchClick}>Show</Button>
        </div>
        </div>

      {searchClicked && selectedOption.value == "Received" && (
        <ReceivedDrugBook />
      )}
      {searchClicked && selectedOption.value == "Administered" && (
        <AdministeredDrugBook></AdministeredDrugBook>
      )}
      {searchClicked && selectedOption.value == "Removed" && (
        <RemovedDrugBook></RemovedDrugBook>
      )}

      <Modal
        show={receivedModalShow}
        onHide={handleReceivedModalClose}
        title="Add Drugs Received"
      >
        <AddReceived onHide={handleReceivedModalClose} />
      </Modal>

      <Modal
        show={removedModalShow}
        onHide={handleRemovedModalClose}
        title="Add Drugs Removed Record"
      >
        <AddRemoved onHide={handleRemovedModalClose} />
      </Modal>

      <Modal
        show={viewReceivedModalShow}
        onHide={handleViewReceivedModalClose}
        title="Drugs Received"
      >
        <AddReceived data={rowData} isReadOnly={true} />
      </Modal>

      <Modal
        show={viewAdministeredModalShow}
        onHide={handleViewAdministeredModalClose}
        title=" Drugs Administered"
      >
        <AddAdministered data={rowData} isReadOnly={true} />
      </Modal>
    </React.Fragment>
  );
};

export default DrugBook;
