import React from 'react';
import { Tabs, Tab } from '../../components/Tabs/Tabs';
import Heading from '../../components/Heading/Heading';

import './Reporting.css';
import Billing from './components/Billing';
import CallPerformance from './components/CallPerformance';

const Reporting = () => {

  return (
    <React.Fragment>
      <Heading title="Reporting" />
      <div className="tab-container">
        <Tabs>
          <Tab controlId={"billing"} label={"Billing"}>
            <Billing />
          </Tab>
          <Tab controlId={"callPerformance"} label={"Call Performance"}>
            <CallPerformance />
          </Tab>
        </Tabs>
      </div>
    </React.Fragment>
  )
};

export default Reporting;