import React from 'react';
import './ViewToggle.css';

const ViewToggle = (props) => {
  const { displayView, onClick } = props;

  return (
    <div className="view-btn-group">
      <div onClick={()=> onClick('list')} className={displayView === "list" ? "view-btn-item active" : "view-btn-item"}>
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.5 10.5C2.75 10.5 3 10.75 3 11V13C3 13.2812 2.75 13.5 2.5 13.5H0.5C0.21875 13.5 0 13.2812 0 13V11C0 10.75 0.21875 10.5 0.5 10.5H2.5ZM2.5 0.5C2.75 0.5 3 0.75 3 1V3C3 3.28125 2.75 3.5 2.5 3.5H0.5C0.21875 3.5 0 3.28125 0 3V1C0 0.75 0.21875 0.5 0.5 0.5H2.5ZM2.5 5.5C2.75 5.5 3 5.75 3 6V8C3 8.28125 2.75 8.5 2.5 8.5H0.5C0.21875 8.5 0 8.28125 0 8V6C0 5.75 0.21875 5.5 0.5 5.5H2.5ZM15.5 11C15.75 11 16 11.25 16 11.5V12.5C16 12.7812 15.75 13 15.5 13H5.5C5.21875 13 5 12.7812 5 12.5V11.5C5 11.25 5.21875 11 5.5 11H15.5ZM15.5 1C15.75 1 16 1.25 16 1.5V2.5C16 2.78125 15.75 3 15.5 3H5.5C5.21875 3 5 2.78125 5 2.5V1.5C5 1.25 5.21875 1 5.5 1H15.5ZM15.5 6C15.75 6 16 6.25 16 6.5V7.5C16 7.78125 15.75 8 15.5 8H5.5C5.21875 8 5 7.78125 5 7.5V6.5C5 6.25 5.21875 6 5.5 6H15.5Z" fill="currentColor" />
        </svg>
        List
      </div>
      <div onClick={()=> onClick('card')} className={displayView === "card" ? "view-btn-item active" : "view-btn-item"}>
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 6C3.53125 6 4 6.46875 4 7V9C4 9.5625 3.53125 10 3 10H1C0.4375 10 0 9.5625 0 9V7C0 6.46875 0.4375 6 1 6H3ZM8 6C8.53125 6 9 6.46875 9 7V9C9 9.5625 8.53125 10 8 10H6C5.4375 10 5 9.5625 5 9V7C5 6.46875 5.4375 6 6 6H8ZM13 6C13.5312 6 14 6.46875 14 7V9C14 9.5625 13.5312 10 13 10H11C10.4375 10 10 9.5625 10 9V7C10 6.46875 10.4375 6 11 6H13ZM3 0C3.53125 0 4 0.46875 4 1V3C4 3.5625 3.53125 4 3 4H1C0.4375 4 0 3.5625 0 3V1C0 0.46875 0.4375 0 1 0H3ZM8 0C8.53125 0 9 0.46875 9 1V3C9 3.5625 8.53125 4 8 4H6C5.4375 4 5 3.5625 5 3V1C5 0.46875 5.4375 0 6 0H8ZM13 0C13.5312 0 14 0.46875 14 1V3C14 3.5625 13.5312 4 13 4H11C10.4375 4 10 3.5625 10 3V1C10 0.46875 10.4375 0 11 0H13Z" fill="currentColor" />
        </svg>
        Cards
      </div>
    </div>
  )
};

export default ViewToggle;