import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../../components/Heading/Heading";
import BackButton from "../../components/BackButton/BackButton";
import { Button } from "../../components/FormElements";
import { Tabs, Tab } from "../../components/Tabs/Tabs";
import Details from "./components/Details";
import { useSelector } from "react-redux";
import "./Patients.css";
import Table from "../../components/Table/Table";
import {getAppointment} from "../../app/reducers/appointmentSlice";
import { useDispatch } from "react-redux";
import { Roles } from "../../constants";
import TreatmentCard from "../../components/TreatmentCard/TreatmentCard";

const ViewPatient = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const patientDetailsSelector = useSelector((state) => state.patient);

  const appoinmentsSelector = useSelector((state) => state.appointment);

  const authenticationSelector = useSelector((state) => state.authentication);

  const handleViewClick = (rowId) => {
    if ((authenticationSelector.role === Roles.Reception)) {
      dispatch(
        getAppointment({
          id: rowId,
          navigate,
          redirectUrl: "/appointments/receptiondetails",
        })
      );
    } else {
      dispatch(
        getAppointment({
          id: rowId,
          navigate,
          redirectUrl: "/appointments/details",
        })
      );
    }
  };

  const columns = [
    {
      label: "AppDate",
      controlId: "StrAppointmentStartDate",
      sortable: false,
      onClick: () => {},
    },
    {
      label: "Clinic",
      controlId: "Clinic",
      sortable: false,
      onClick: () =>{},
    },
    {
      label: "Nurse",
      controlId: "Nurse",
      sortable: false,
      onClick: () => {},
    },
    {
      label: "Status",
      controlId: "AppointmentStatusLabel",
      sortable: false,
    },
  ];

  const action = [
    { id: 1, label: "View", onclick: handleViewClick },
  ];


  return (
    <React.Fragment>
      <div className="heading-section">
        <BackButton title="Back to all Patients" to="/patient" />
        <Heading
          title="Patient"
          subHeading={`${patientDetailsSelector.patientDetails.Name} | ${new Date(
            patientDetailsSelector.patientDetails.DOB
          ).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}`}
        />
        <div className="header-btn-wrapper">
          <Button
            variant="secondary"
            onClick={() => navigate("/patient/update")}
          >
            Edit
          </Button>
        </div>
      </div>
      <Tabs>
        <Tab controlId={"detail"} label={"Detail"}>
          <Details patient={patientDetailsSelector.patientDetails} isReadOnly={true} />
        </Tab>
        <Tab controlId={"appointments"} label={"Appointments"}>
          {appoinmentsSelector.appointments && appoinmentsSelector.appointments.length > 0 && 
            <Table
                data={appoinmentsSelector.appointments}
                columns={columns}
                action={action}
                columnsMergeId="PatientName"
                columnsMergeWith={["StrDOB", "Pronouns"]}
                handleSort={() => {}}
              />
          }
        </Tab>
        <Tab controlId={"treatmentPlans"} label={"Treatment Plans"}>
          {appoinmentsSelector.treatmentPlans && appoinmentsSelector.treatmentPlans.length > 0 && 
            <React.Fragment>
              <div className="row">
              {appoinmentsSelector.treatmentPlans.map(
              (item) =>
                item.treatmentName !== "" && (
                  <div key={item.id} className="col-md-4">
                    <TreatmentCard
                      defaultChecked={item.isSelected}
                      data={item}
                      onChange={(e) => {}}
                      onEdit={(e) => {}}
                    />
                  </div>
                )
            )}
              </div>
            </React.Fragment>
            }
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default ViewPatient;
