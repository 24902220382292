import React, { useEffect, useState } from "react";

import "../Reporting.css";
import {
  Button,
  Calendar
} from "../../../components/FormElements";
import Table from "../../../components/Table/Table";
import Pagination from "../../../components/Pagination/Pagination";
import { billing } from "../../../constants";
import prescriberIcon from "../../../assets/images/icons/prescriber.svg";
import nurseIcon from "../../../assets/images/icons/nurses.svg";
import clinicIcon from "../../../assets/images/icons/clinics.svg";
import NoData from "../../../components/NoData/NoData";
import { getPatient, searchPatients } from "../../../app/reducers/patientSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import AutocompleteClinic from "../../../components/AutocompleteClinic/AutocompleteClinic";
import AutocompletePrescriber from "../../../components/AutocompletePrescriber/AutocompletePrescriber";
import AutocompleteNurse from "../../../components/AutocompleteNurse/AutocompleteNurse";
import { emailBillingReport, getBillingReport } from "../../../app/reducers/reportSlice";
import { getAppointment } from "../../../app/reducers/appointmentSlice";
import { useNavigate } from "react-router-dom";

const Billing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pageSize = 20;

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } =useForm({
    defaultValues: {
      Clinic: 0,
      Prescriber: 0,
      Nurse: 0
    },
  });

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [searchClicked, setSearchClicked] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [clinicId, setClinicId] = useState(0);
  const [prescriberId, setPrescriberId] = useState(0);
  const [nurseId, setNurseId] = useState(0);

  const reportSelector = useSelector((state) => state.report);

  const onPageIndexChange = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    if (searchClicked) {
      dispatch(
        getBillingReport({
          ...searchData,
          PageIndex: pageIndex,
          PageSize: pageSize,
        })
      );
    } else {
      dispatch(
        getBillingReport({
          PageIndex: pageIndex,
          PageSize: pageSize,
        })
      );
    }
  };

  useEffect(() => {
    setCurrentPageIndex(1);
    dispatch(getBillingReport(searchData));
  }, [searchData]);

  const handleViewClick =  async (row) => {
    dispatch(
      await getAppointment({
        id: row.AppointmentId,
        navigate,
        redirectUrl: '/appointments/details',
      })
    );
  };

  const columns = [
    {
      label: "Clinic",
      controlId: "ClinicName",
      sortable: true,
    },
    {
      label: "Nurse",
      controlId: "NurseName",
      sortable: true,
    },
    {
      label: "Prescriber",
      controlId: "PrescriberName",
      sortable: true,
    },
    {
      label: "Date",
      controlId: "StrTreatmentPlanDate",
      sortable: true,
    },
    {
      label: "Duration",
      controlId: "StrCallDuration",
      sortable: true,
    }
    // {
    //   label: "Appointment",
    //   controlId: "AppointmentId",
    //   sortable: true,
    // },
  ];

  const action = [
    { id: 1, label: 'View Appointment',  onclick: handleViewClick }
  ];

  const watchData = watch();

  const validate = {
    isClinic: watchData.Clinic && watchData.Clinic.length >= 3 ? true : false,
    isPrescriber: watchData.Prescriber && watchData.Prescriber.length >= 3 ? true : false,
    isNurse: watchData.Nurse && watchData.Nurse.length >= 3 ? true : false,
    isFromDate: fromDate ? true : false,
    isToDate: toDate ? true : false,  
  };

  const validateAtLeastOneField = () => {
    const isValidate = Object.entries(validate).filter(
      ([key, value]) => value === true
    );
    return isValidate.length >= 2 ? true : false;
  };

  const generateExcel = async () => {
    dispatch(emailBillingReport(searchData));
  }

  const onSubmit = (data) => {
    setSearchClicked(true);
    setCurrentPageIndex(1);
    const updatedData = {
      ClinicId: clinicId,
      PrescriberId: prescriberId,
      NurseId: nurseId,
      fromDate: fromDate
        ? new Date(
          fromDate.getTime() - fromDate.getTimezoneOffset() * 60000
          ).toISOString()
        : null,
        toDate: toDate
        ? new Date(
          toDate.getTime() - toDate.getTimezoneOffset() * 60000 + 86400000 - 1
          ).toISOString()
        : null,
      PageIndex: currentPageIndex,
      PageSize: pageSize,
    };
    setSearchData(updatedData);
  };

  return (
    <React.Fragment>
      <div className="mt-20">
        <div className="row">
          {/* <div className="search-control-wrapper">
        <div className='search-control'> */}
          {/* <div className="search-control"> }
            <RangeCalendar
              label='Date Range'
              controlId='dateRange'
              onChange={(date) => console.log('date', date)}
            />
          </div> */}
          <div className="col-md">
            <Calendar
              name="FromDate"
              label="From Date"
              controlId="fromDate"
              onChange={(date) => setFromDate(date)}
            />
          </div>
          <div className="col-md">
            <Calendar
              label="To Date"
              controlId="toDate"
              onChange={(date) => setToDate(date)}
            />
          </div>
          <div className="col-md">
            <Controller
              name="Clinic"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutocompleteClinic
                  label="Clinic"
                  controlId="Clinic"
                  onValueChange={(e, i) => {
                    onChange(e);
                    setClinicId(i);
                  }}
                />
              )}
              {...register("Clinic", { validate: validateAtLeastOneField })}
            />
          </div>
          <div className="col-md">
            <Controller
              name="Prescriber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutocompletePrescriber
                  label="Prescriber"
                  controlId="Prescriber"
                  onValueChange={(e, i) => {
                    onChange(e);
                    setPrescriberId(i);
                  }}
                />
              )}
              {...register("Prescriber", { validate: validateAtLeastOneField })}
            />
          </div>
          <div className="col-md">
            <Controller
              name="Nurse"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutocompleteNurse
                  label="Nurse"
                  controlId="Nurse"
                  onValueChange={(e, i) => {
                    onChange(e);
                    setNurseId(i);
                  }}
                />
              )}
              {...register("Nurse", { validate: validateAtLeastOneField })}
            />
          </div>

          <div className="col-md">
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={!validateAtLeastOneField()}
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="report-info-item">
            <img src={prescriberIcon} />
            <div className="report-info">
              <div className="report-number text-pink">
                {reportSelector.treatmentPlansApprovedByPrescriber}
              </div>
              <p>Prescriber approved treatment plans</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="report-info-item">
            <img src={nurseIcon} />
            <div className="report-info">
              <div className="report-number text-tan">
                {reportSelector.numberOfNurses}
              </div>
              <p>Nurses with approved treatment plans</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="report-info-item">
            <img src={clinicIcon} />
            <div className="report-info">
              <div className="report-number text-blue">
                {reportSelector.numberOfClinics}
              </div>
              <p>Clinics with approved treatment plans</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-30">
        <Table data={reportSelector.billingReport} columns={columns} />
        <Pagination
          pageSize={pageSize}
          currentPageIndex={currentPageIndex}
          totalRecords={reportSelector.billingCount}
          onPageChange={(e) => onPageIndexChange(e)}
        />
        <div
          className="search-button generate-excel-button" 
        >
          <Button onClick ={generateExcel}>Email Billing Report</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Billing;
