import React, { useEffect, useState } from 'react';
import { Checkbox, TextInput } from '../../../components/FormElements';
import { useFormContext, Controller } from 'react-hook-form';

import '../TreatmentPlans.css';
import AutocompleteProduct from '../../../components/AutocompleteProduct/AutocompleteProduct';
import { treatmentAreas } from '../../../constants';

import axios from "axios";
import {envEndPoints } from '../../../app/config';
import store from "../../../app/store";


const AntiWrinkleProducts = (props) => {
  const { item, index, product, remove, data, removeButtonShow } = props;

  const productNameList = product.map((d) => d.Name);  
  
  const methods = useFormContext();
  const { control, watch, setValue } = methods;
  const [productName, setProductName] = useState('');
  const [autoSuggestProduct, setAutoSuggestProduct] = useState();


  let productFindBaseUrl = `${envEndPoints().baseUrl}/product/find`;
  const accessToken = store.getState().authentication.accessToken;
  
  const selectedProduct = product.find((e) => {    
    if (productName !== '') {
      return e.Name === productName;
    } else {
      return null;
    }
  });

  const getTreatmentAreas = treatmentAreas.find((e) => {
    if (autoSuggestProduct) {
      return e.Category === autoSuggestProduct?.Category;
    }
  });

  const setProductId = () => {
    setValue(`antiWrinkle.${index}.productId`, autoSuggestProduct?.Id);
    setValue(`antiWrinkle.${index}.productCategory`, autoSuggestProduct?.Category);
  };

  const changeAutoSelectedProduct = (e) => {
    if (e !== '') {
      let productFindUrl =  `${productFindBaseUrl}/${e}`;  
      axios.get(productFindUrl,{ headers : { 'Content-type': 'application/json', Authorization: `bearer ${accessToken}` } }).then((response) => {                          
        setAutoSuggestProduct(response.data);         
      });
    } else {
      setAutoSuggestProduct(null); 
    }
  }

  useEffect(() => {
    if (productName && autoSuggestProduct) {
      setProductId();
      }
  }, [autoSuggestProduct, productName]);

  const checkUnitValue = watch(`antiWrinkle.${index}.units`);

  return (
    <div className='antiWrinkle-product-wrapper'>
      <div className='row' key={item.id}>
        <div className='col-md-6'>
          <Controller
            name={`antiWrinkle.${index}.productName`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteProduct
                label='Select Product Name'
                controlId={'product' + item.id}
                type="wrinkle"
                //suggestions={productNameList}
                initialValue={value}
                onValueChange={(e) => {
                  onChange(e);
                  setProductName(e);
                  changeAutoSelectedProduct(e);
                }}
              />
            )}
          />
          <Controller
            name={`antiWrinkle.${index}.productId`}
            control={control}
            render={({ field }) => <input type='hidden' {...field} />}
          />
          <Controller
            name={`antiWrinkle.${index}.productCategory`}
            control={control}
            render={({ field }) => <input type='hidden' {...field} />}
          />
        </div>
        <div className='col-md-3'>
          <TextInput
            {...methods.register(`antiWrinkle.${index}.units`)}
            label='Specify Units'
            controlId={item.id}
          />
        </div>
        {removeButtonShow && (
          <div className='col-md-3 text-right'>
            <div className='remove-product-link' onClick={() => remove(index)}>
              Remove this item
            </div>
          </div>
        )}
      </div>
      {getTreatmentAreas && checkUnitValue ? (
        <React.Fragment>
          <div className='fs-18 mb-20'>Specify Treatment Areas</div>
          <div className='row'>
            <React.Fragment>
              {getTreatmentAreas.Areas.map((item, k) => (
                <div className='col-md-6 mb-20' key={k}>
                  <Controller
                    name={`antiWrinkle[${index}].treatmentAreas.${item.Code}`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        label={item.Name}
                        unitLable='Max Dose:'
                        unit={item.Dosage}
                        onChange={onChange}
                        checked={value}
                      />
                    )}
                  />
                </div>
              ))}
            </React.Fragment>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default AntiWrinkleProducts;
