import React from "react";
import "./Consent.css";

const PdoThreadsConsent = () => {
  return (
    <React.Fragment>
      <div className="mt-60 pdo-threads-layout">
        <div className="consent-type"> PDO Threads</div>
        <div className="consent-text">
          At SILK Laser Clinics PDO threads, also called mono threads are
          single, non-barbed threads made from common dissolvable suture
          material (PDO - polydioxanone). When inserted into the skin, the
          threads initiate growth factor release through tissue trauma.
          <br></br>
          <br />
          Fibroblast activation commences new collagen formation, which is
          remodeled at four weeks. New collagen has a tendency to shrink as it
          is remodeled. The effect is one of volume and shrinkage due to the
          sheer number of threads inserted. They provide a meshwork of new
          collagen and tissue support
        </div>

        <div className="consent-layout-wrapper">
          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">
                ON THE DAY OF YOUR APPOINTMENT
              </div>
              <p class="paragraph">
                Prior to treatment you will be given a thorough consultation,
                during this consultation a treatment plan is competed.
                <br />
                <br />
                The procedure itself can take up to 15mins and includes numbing.
                Mineral make up can gently be applied directly after treatment.
              </p>
            </div>

            <div class="right-side">
              <div className="consent-subheading">AFTER YOUR TREATMENT</div>
              <p class="paragraph">
                The treatment area will feel tender to touch, it is recommended
                that you avoid touching your face for at least 2 hours after
                treatment to allow the injection sites to heal. Ensure hands are
                clean and washed before touching the injected area. To assist
                the healing process, you are recommended to:
                <ul>
                  <li>Sleep elevated to assist in reducing swelling.</li>
                  <li>
                    Bruising may occur and may last for up to 2 weeks, Arnica
                    tables, cream or spray can assist healing. Yellow light LED
                    can help to reduce bruising.
                  </li>
                  <li>Paracetamol may be used for pain relief.</li>
                </ul>
              </p>
            </div>
          </div>

          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">SWELLING</div>
              <p class="paragraph">
                You can expect swelling to be present in the treatment area for
                up to 2days after the treatment and you can use a cool compress
                to assist with this.
              </p>
            </div>
            <div class="right-side">
              <div className="consent-subheading">
                ENSURE THE BEST POSSIBLE RESULTS
              </div>
              <p class="paragraph">
                To ensure the best possible result, it is recommended that you
                avoid the following for 48hours after treatment:
                <ul>
                  <li>Exercise</li>
                  <li>Hot showers</li>
                  <li>Sauna and/or Steam</li>
                  <li>Pool and/or Spa</li>
                  <li>Prolonged sunlight</li>
                  <li>Excessive alcohol</li>
                </ul>
                If you are undergoing Professional Skin Treatments, please speak
                with your Laser and Dermal Technician for more information about
                the recommended wait times post treatment.
              </p>
            </div>
          </div>

          <div class="layout-container">
            <div class="left-side">
              <div className="consent-subheading">PHOTOGRAPHIC CONSENT</div>
              <p class="paragraph">
                I give permission for my photographs to be used for:
                <ul>
                  <li>Internal education purposes</li>
                  <li>
                    Social media marketing including (but not limited to)
                    Facebook and Instagram
                  </li>
                </ul>
              </p>
            </div>
            <div class="right-side">
              <div className="consent-subheading">FINANCIAL CONSENT</div>
              <p class="paragraph">
                Your cosmetic injector will provide you with full details of
                your cost of treatment prior to the procedure. Any deposits paid
                are redeemable against your treatment. Treatment costs are a
                private service and not covered by Medicare.
                <br />
                <br />I have been made aware of all costs associated with my
                treatment plan
              </p>
            </div>
          </div>

          <div className="consent-text">
            All procedures performed at SILK are proven to be safe and
            effective.
            <br /> <br />
            Further treatment to address any unwanted side effects may be
            required in the short term (such as for anaphlyaxis, hyaluronidaise
            to dissolve dermal filler, antibiotics (oral or topical) or the long
            term (such as steroids to treat ongoing nodules). Costs for this
            treatment if required may or may not be covered by SILK and will
            depend on many factors and be at the discretion of SILK Management.
            <br /> <br />
            If you have any concerns or questions with regards to your
            treatment, please contact the clinic you were treated at to speak
            with a member of the medical team during clinic opening hours. Full
            information on our complaints process can be found here
            <br /> <br />
            In the case of an adverse response, outside of opening hours, please
            contact: SILK Afterhours Care: 08 8342 7435
            <br /> <br />
            If you are experiencing a medical emergency, please call 000.
            <br /> <br />
            There are many alternative treatments, including the option of not
            having the procedure. Your cosmetic injector will discuss these
            options with you.
            <br /> <br />
            I agree it has been explained that the results of the treatment are
            not permanent, and I also understand that the treatment may affect
            me differently and that the results could last for a longer or
            shorter period than the norm. I understand that there is a
            possibility of an unsatisfactory result.
            <br /> <br />
            I understand that I should not have any PDO Thread treatment if I
            have: any known allergy or foreign-body sensitivities to plastic
            biomaterial or permanent fillers, acute or chronic skin diseases,
            autoimmune diseases, sepsis or infection, or if I am unwilling to
            follow the post treatment guidance that has been provided.
            <br /> <br />
            I understand that I should not have any PDO Thread treatment if I am
            pregnant, breastfeeding and/or under the age of 18 and confirm that
            I am not pregnant, breastfeeding nor under the age of 18.
            <br /> <br />
            By signing this consent form, I confirm that I have read and
            understood the information contained in this form and the
            consultation with the physician, agree with all of the statements
            set out above and consent to the PDO Thread treatment. I accept that
            any treatment I have is at my own risk and I understand that if I
            fail to disclose information requested by this form that this may
            result in an adverse side effect for which I accept full liability
            and responsibility.
            <br /> <br />
            All information I have provided is true and correct at the time of
            signing this form. If my medical or personal circumstances change, I
            will notify the clinic immediately.
            <br/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PdoThreadsConsent;
