import React, { useRef, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import './TreatmentPlans.css';
import Heading from '../../components/Heading/Heading';
import { useNavigate } from 'react-router-dom';
import { Button, TextArea, TextInput } from '../../components/FormElements';
import SignatureCanvas from 'react-signature-canvas';
import ReviewDetails from './components/ReviewDetails';
import { useSelector, useDispatch } from 'react-redux';
import { treatentPlanReceiver } from '../../app/receivers/appointmentDetailsReceiver';
import { approveTreatmentPlan, declineTreatmentPlan } from '../../app/reducers/treatmentPlanSlice';
import { RoleBasedApprovalStatus } from '../../constants/index';
import { Roles } from '../../constants/index';
import Modal from '../../components/Modal/Modal';
import Deactivate from '../../components/Deactivate/Deactivate';
import ConfirmDeclineTreatment from './ConfirmDeclineTreatment';
import axios from 'axios';
import { envEndPoints } from '../../app/config';

const Review = (props) => {
  let signPad = useRef({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signature, setSignature] = useState('');

  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);
  const authenticationSelector = useSelector((state) => state.authentication);
  const role = useSelector((state) => state.authentication.role);
  
  const [declineModalShow, setDeclineModalShow] = useState(false);
  
  const handleDeclineModalClose = () => setDeclineModalShow(false);
  
  const handleDeclineClick = (rowId) => {
    setDeclineModalShow(true);
  };

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  useEffect(() => {
   if(!!props.isSignExists){
    copySignature();
   }
  }, []);

  const treatmentPlanReceiverData = treatentPlanReceiver(
    treatmentPlanSelector.treatmentInfo
  );

  const { control, handleSubmit } = useForm({
    defaultValues: {
      approvalComment: '',
      placeOfPractice: authenticationSelector.areaOfEndorsement,
      prescriberNumber: authenticationSelector.prescriberNumber,
    },
  });

  const trim = () => {
    const fullDataUrl = signPad.current.toDataURL('image/png');
    //removing the "data:image/png;base64," prefix to accomodate saving in pdf
    const imageData = fullDataUrl.split(',')[1]; 
    setSignature(imageData);
  };

  const clear = () => {
    signPad.current.clear();
    setSignature('');
  };

  const copySignature = async () => {
    
    const response = await axios.get(
      `${envEndPoints().baseUrl}/user/userSign/${authenticationSelector.id}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `bearer ${authenticationSelector.accessToken}`,
        },
      }
    );

    signPad.current.fromDataURL(`data:image/png;base64,${response.data.Signature}`);

    setSignature(response.data.Signature);

  }

  const declineTreatment = () => {
    let plan = {
      id: treatmentPlanSelector.id,
      treatmentStatusId: 7, //Decline
      approvedSignature: '',
      approvalComment: 'Decline',
    };
    dispatch(
      declineTreatmentPlan({
        data: plan,
        navigate,
        redirectUrl: '/treatmentplan/outcome',
      })
    );
  };


  const onSubmit = (formdata) => {
    let statusId = RoleBasedApprovalStatus.find(
      (approval) =>
        approval.role.toLowerCase() ===
        authenticationSelector.role.toLowerCase()
    )?.id;

    if (statusId !== undefined) {
      let data = {
        ...formdata,
        approvedSignature: signature,
        id: treatmentPlanSelector.id,
        treatmentStatusId: statusId, //no to lookup from constants based on login user role.
        userid: authenticationSelector.id,
      };
      dispatch(
        approveTreatmentPlan({
          data: data,
          navigate,
          redirectUrl: '/videoconsult/queue',
        })
      );
    } else {
      alert('Only NurseInjector or NursePractitioner can approve plan.');
    }
  };

  const editPlan = () => {
    navigate('/treatmentplan/packages');
  };

  const buttonText= authenticationSelector.role.toLowerCase() === 'nurseinjector' ? 'Submit' : 'Approve';

  return (
    <React.Fragment>
      <Heading
        title="Treatment Plan"
        subHeading={`Review the new treatment plan for ${treatmentPlanSelector.patientName}`}
      />
      <div className="mt-60">
        {!!treatmentPlanReceiverData && (
          <ReviewDetails
            data={treatmentPlanReceiverData}
            onEdit={() => editPlan()}
            isApproved={false}
          />
        )}
        <hr className='divider mt-70 mb-70' />
        {/* <div className='review-sub-heading text-pink'>Approval</div> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            {/* <div className='col-md-6'>
              <Controller
                name='approvalComment'
                control={control}
                render={({ field }) => (
                  <TextArea label='Nurse Comment' rows={8} {...field} />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Nurse comment is required!',
                  },
                }}
              />
            </div> */}
            <div className='col-md-6'>
              {/*!!props.isSignExists &&
                <button className="popup-link"  type="button" onClick={copySignature}>
                  copy previous signature
                </button>
              */}
              <div className='signature-canvas-wrapper'>
                <label className='floating-label'>Signature</label>
                <SignatureCanvas
                  backgroundColor='#F9F9F9'
                  canvasProps={{ className: 'sigCanvas' }}
                  ref={signPad}
                  clearOnResize={false}
                  onEnd={() => trim()}
                />
                <div
                  className='clear-signature'
                  title='Clear Signature'
                  onClick={() => clear()}
                >
                  <svg
                    width='23'
                    height='21'
                    viewBox='0 0 23 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.8594 20.5221C11.8324 20.5221 11.801 20.5625 11.774 20.5625H6.91348C6.05547 20.5625 5.2334 20.2211 4.62696 19.6147L1.39393 16.3803C0.130816 15.118 0.130816 13.0695 1.39393 11.8072L11.3697 1.83144C12.632 0.568232 14.6805 0.568232 15.9428 1.83144L21.567 7.49474C22.8697 8.75704 22.8697 10.8055 21.567 12.0678L15.2689 18.4063H21.9219C22.5193 18.4063 23 18.8869 23 19.4844C23 20.0818 22.5193 20.5625 21.9219 20.5625L11.8594 20.5221ZM2.91858 14.817L6.11387 18.0514C6.35645 18.294 6.63047 18.4063 6.91348 18.4063H11.774C12.057 18.4063 12.3311 18.294 12.5332 18.0514L15.4531 15.1719L8.26563 7.98438L2.91858 13.3301C2.49766 13.7524 2.49766 14.4352 2.91858 14.817Z'
                      fill='#525252'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {role === Roles.NursePractitioner && (
            <div className='row'>
              <div className='col-md-6'>
                <Controller
                  name='placeOfPractice'
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label='Place of Practice'
                      addonType='lock'
                      readOnly={true}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='col-md-6'>
                <Controller
                  name='prescriberNumber'
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label='Prescriber Number'
                      addonType='lock'
                      readOnly={true}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="flex justify-end align-center mt-50">
            <u>
              <button className="popup-link"  type="button" onClick={handleDeclineClick}>
              Decline Treatment Plan
              </button>
            </u>
            <Button disabled={!signature && true} style={{ minWidth: "165px" }}>
                {buttonText}
            </Button>

            
          </div>
        </form>
      </div>
      <Modal
        show={declineModalShow}
        onHide={handleDeclineModalClose}
        title="Decline Treatment Plan"
      >
        <ConfirmDeclineTreatment
          onHide={handleDeclineModalClose}
          message={"Are you sure you would like to decline the treatment plan?"}
          buttonText={"Decline"}
          handleDecline ={declineTreatment}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Review;
