import { Button, TextInput } from '../../../components/FormElements';
import { useFormContext, useFieldArray, useWatch, Controller } from "react-hook-form";

import '../TreatmentPlans.css';
import { products } from '../../../constants';
import AutocompleteProduct from '../../../components/AutocompleteProduct/AutocompleteProduct';

const Other = () => {

  const productNameList = products.map(d => d.Name);

  const methods = useFormContext();
  const { control } = methods;

  const watchData = useWatch();

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "other"
  });
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div className="antiWrinkle-product-wrapper mt-0" key={item.id}>
            <div className="row">
              <div className="col-md-6">
                <Controller
                  name={`other.${index}.productName`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutocompleteProduct
                      label="Select Product Name"
                      controlId={"product" + item.id}
                      //suggestions={productNameList}
                      type="others"
                      initialValue={value}
                      onValueChange={(e) => { onChange(e); }}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <TextInput
                  {...methods.register(`other.${index}.units`)}
                  label="Specify Units"
                  controlId={item.id}
                />
              </div>
              {watchData?.other?.length > 1 &&
                <div className="col-md-3 text-right">
                  <div className="remove-product-link" onClick={() => remove(index)}>Remove this item</div>
                </div>
              }
            </div>
          </div>
        );
      })}
      {watchData?.other[0]?.productName !== '' &&
        <div className="mb-20">
          <Button size="md" variant="blue btn-primary" type="button"
            onClick={() => {
              append({ productName: "", units: "" });
            }}
          >Add Another Product</Button>
        </div>
      }
    </div>
  );
};

export default Other;
