import React from 'react';
import { useFormContext } from 'react-hook-form';
import '../TreatmentPlans.css';
import Accordion from '../../../components/Accordion/Accordion';
import AntiWrinkle from './AntiWrinkle';
import Filler from './Filler';
import Other from './Other';

const Injectibles = () => {
  const methods = useFormContext();
  const { watch } = methods;
  const watchData = watch();
  return (
    <React.Fragment>
      <Accordion
        title={'Anti-Wrinkle'}
        isDefaultActive={
          watchData?.antiWrinkle[0].productName !== '' ? true : false
        }
      >
        <AntiWrinkle />
      </Accordion>
      <Accordion
        title={'Filler'}
        isDefaultActive={watchData?.filler[0].areaName !== '' ? true : false}
      >
        <Filler />
      </Accordion>
      <Accordion
        title={'Other'}
        isDefaultActive={watchData?.other[0].productName !== '' ? true : false}
      >
        <Other />
      </Accordion>
    </React.Fragment>
  );
};

export default Injectibles;
