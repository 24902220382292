import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/FormElements';
import { useFormContext, useFieldArray, Controller } from "react-hook-form";

import '../TreatmentPlans.css';
import AutocompleteProduct from '../../../components/AutocompleteProduct/AutocompleteProduct';
import FillerProducts from './FillerProducts';
import { products } from '../../../constants';

import axios from "axios";
import {envEndPoints } from '../../../app/config';
import store from "../../../app/store";

const FillerTreatmentArea = (props) => {
  const { item, index, treatmentAreas, removeButtonShow, removeArea } = props;

  const methods = useFormContext();
  const { control, watch, setValue } = methods;
  const [areasName, setAreasName] = useState('');
  const [productDetails, setProductDetails] = useState('');
  const [clearValue, setClearValue] = useState(false);
  const [autoSuggestProduct, setAutoSuggestProduct] = useState(null);

  let productFindBaseUrl = `${envEndPoints().baseUrl}/product/find`;
  const accessToken = store.getState().authentication.accessToken;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `filler[${index}].products`
  });


  const getTreatmentAreas = treatmentAreas.find(e => {
    if (treatmentAreas) {
      return e.Category === "other";
    }
  });

  const treatmentAreasList = getTreatmentAreas.Areas.map(d => d.Name);
  const productList = products.map(d => d.Name);

  let selectedProduct = products.find(e => {
    if (productDetails !== '') {
      return e.Name === productDetails;
    } else {
      return null;
    }
  });

  const changeAutoSelectedProduct = (e) => {        
    if (e !== undefined && e !== '') {
      let productFindUrl =  `${productFindBaseUrl}/${e}`;  
      axios.get(productFindUrl,{ headers : { 'Content-type': 'application/json', Authorization: `bearer ${accessToken}` } }).then((response) => {                          
        setAutoSuggestProduct(response.data);
      });
    } else {
      setAutoSuggestProduct(null); 
    }
  }

  const getTreatmentAreasId = getTreatmentAreas.Areas.find(e => {
    if (areasName) {
      return e.Name === areasName;
    }
  });

  const setAreasId = () => {
    setValue(`filler.${index}.areaId`, getTreatmentAreasId?.Id);
  }

  useEffect(() => {
    if (areasName && getTreatmentAreasId) {
      setAreasId();
    }
  }, [getTreatmentAreasId, areasName]);


  const addProduct = () => {    
    if (autoSuggestProduct) {
      setClearValue(true);
      append({
        productName: autoSuggestProduct.Name,
        units: ""
      });
      setProductDetails("");
      setAutoSuggestProduct(null); 
    };
  };

  return (
    <div className="antiWrinkle-product-wrapper">
      <div className="row">
        <div className="col-md-6">
          <Controller
            name={`filler.${index}.areaName`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteProduct
                label="Select Treatment Area"
                controlId={"area" + item.id}
                type="filler"
                suggestions={treatmentAreasList}
                initialValue={value}
                onValueChange={(e) => { onChange(e); setAreasName(e); }}
              />
            )}
          />
          <Controller
            name={`filler.${index}.areaId`}
            control={control}
            render={({ field }) => (
              <input type="hidden" {...field} />
            )}
          />
        </div>
        {removeButtonShow &&
          <div className="col-md-6 text-right">
            <div className="remove-product-link" onClick={() => removeArea(index)}>Remove this item</div>
          </div>
        }
      </div>
      {areasName &&
        <React.Fragment>
          <div className="fs-18 mb-20">
            Specify Products
          </div>

          <div className="row">
            <div className="col-md-6">
              <AutocompleteProduct
                label="Select Product"
                controlId={"pruductList"}
                type="wrinkle"
                //suggestions={productList}
                clearValue={clearValue}
                setClearValue={setClearValue}
                onValueChange={(e) => { setProductDetails(e); changeAutoSelectedProduct(e); }}
              />
            </div>
            <div className="col-md-3">
              <Button type="button" onClick={() => addProduct()} >
                Add Product
              </Button>
            </div>
          </div>
          {fields.map((item, productindex) => {
            return (
              <FillerProducts
                key={item.id}
                index={index}
                control={control}
                productindex={productindex}
                remove={remove}
                watch={watch}
                products={products}         
              />
            );
          })}
        </React.Fragment>
      }
    </div>
  );
};

export default FillerTreatmentArea;
