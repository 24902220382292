import { useState } from "react";
import sortDefaultIcon from "../../assets/images/icons/sort-default-icon.svg";
import sortDefaultUpIcon from "../../assets/images/icons/sort-default-up-icon.svg";

const TableHead = ({ columns, action, handleSort }) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (controlId) => {
    const sortOrder =
      controlId === sortField && order === "asc" ? "desc" : "asc";
    setSortField(controlId);
    setOrder(sortOrder);
  };

  return (
    <thead>
      <tr>
        {columns.map(({ label, controlId, sortable, sortIconDown, sortIconUp }) => {
          const sortIcon = sortable
            ? sortField === controlId && order === "asc"
              ? <span className="sort-icon up"><img src={sortIconUp ? sortIconUp : sortDefaultUpIcon} alt="icon" /></span>
              : sortField === controlId && order === "desc"
                ? <span className="sort-icon down"><img src={sortIconDown ? sortIconDown : sortDefaultIcon} alt="icon" /></span>
                : <span className="sort-icon default"><img src={sortIconDown ? sortIconDown : sortDefaultIcon} alt="icon" /></span>
            : "";
          return (
            <th
              key={controlId}
              onClick={sortable ? () => handleSort(controlId) : null}
            >
              {label}
              {sortIcon}
            </th>
          );
        })}
        {action && <th />}
      </tr>
    </thead>
  );
};

export default TableHead;