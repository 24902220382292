import { put } from 'redux-saga/effects';
import { setAuthenticateUserSuccess } from '../reducers/authenticationSlice';
import { resetLoader } from '../reducers/loaderSlice';

export function* authenticateUserHandler(
  action: any
): Generator<any, any, any> {
  const { userDetails, userName, userId, role, accessToken, id, prescriberNumber, placeOfPractice, contactNumber, areaOfEndorsement, additionalPassword, isUserSignExists } = action.payload;

  const res = {
    success: true,
    userDetails: { ...userDetails },
    userName: userName,
    userId: userId,
    role: role,
    accessToken: accessToken,
    id: id,
    prescriberNumber: prescriberNumber,
    placeOfPractice: placeOfPractice,
    contactNumber: contactNumber,
    areaOfEndorsement: areaOfEndorsement,
    additionalPassword: additionalPassword,
    isUserSignExists: isUserSignExists
  };
  yield put(setAuthenticateUserSuccess(res));

  yield put(resetLoader());
}
