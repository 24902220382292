import React, { useEffect, useState } from 'react';
import Heading from '../../components/Heading/Heading';
import BackButton from '../../components/BackButton/BackButton';
import { Tabs, Tab } from '../../components/Tabs/Tabs';

import './Appointments.css';
import Details from './components/Details';
import TreatmentPlans from './components/TreatmentPlans';
import PatientProfile from './components/PatientProfile';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientSuccess } from '../../app/reducers/patientSlice';
import { useNavigate } from 'react-router-dom';
import { treatmentUnmount } from '../../app/reducers/treatmentPlanSlice';
import DischargeSummary from './components/DischargeSummary';
import MedHistory from '../TreatmentPlans/MedHistory';
import MedHistoryReception from './components/MedHistoryReception';
import DetailsReception from './components/DetailsReception';
import Modal from '../../components/Modal/Modal';
import ReceptionLock from '../../components/ReceptionLock/ReceptionLock';

const AppointmentDetailsReception = (props) => {
  const appoinmentsSelector = useSelector((state) => state.appointment);
  const autenticateSelector = useSelector((state) => state.authentication);          
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleReceptionLockModalClose = () => setReceptionLockShow(false);
  const [receptionLockShow, setReceptionLockShow] = useState(false);
  const [activeTab, setActiveTab] = useState('detail');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

   //This hook will help to redirect the page to the appointments page if the patient is not available, i.e. on broswer refresh or direct url hit.
   useEffect(() => {
    if (!!!appoinmentsSelector.patient) {
      navigate('/appointments');
    }
  }, []);

  useEffect(() => {
    if (appoinmentsSelector.patient) {
      dispatch(getPatientSuccess(appoinmentsSelector.patient));
    }
  }, [appoinmentsSelector.patient]);

  useEffect(() => {
    dispatch(treatmentUnmount());
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  let selectedPlans = appoinmentsSelector?.treatmentPlans?.filter(plan => plan.isSelected === true);

  const isCompleted = appoinmentsSelector.appointmentDetails && appoinmentsSelector.appointmentDetails.AppointmentStatus && appoinmentsSelector.appointmentDetails.AppointmentStatus;


  return (
    <React.Fragment>
      {!!appoinmentsSelector && !!appoinmentsSelector.patient && (
        <React.Fragment>
          <div className='heading-section'>
            <BackButton title='Back to all Appointments' onClick={() => setReceptionLockShow(true)}  />
            <Heading
              title='Appointment'
              subHeading={`${appoinmentsSelector.patient.FirstName} ${appoinmentsSelector.patient.LastName} | ${appoinmentsSelector.patient.StrDOB}`}
            />
          </div>
          <Tabs activeTabIndex={activeTab} onTabChange={handleTabChange}>
          
            <Tab controlId={'detail'} label={'Appointment Detail'}>
              <DetailsReception
                appointmentId={
                  appoinmentsSelector.appointmentDetails.AppointmentId
                }
                additionalDetails={
                  appoinmentsSelector.appointmentAdditionalDetails
                }
                isCompleted={isCompleted}
                // onNextTab={() => handleTabChange(2)} 
                onTabChange={handleTabChange}
              />
            </Tab>

            <Tab controlId={'Medical History'} label={'Medical History'}>
              <MedHistoryReception
              medicalHistory={appoinmentsSelector.medicalHistory}
              onTabChange={handleTabChange}
              />
            </Tab>   

            <Tab controlId={'treatmentPlans'} label={'Treatment Plans'}>
              <TreatmentPlans
                appointmentId={
                  appoinmentsSelector.appointmentDetails.AppointmentId
                }
                patientId={appoinmentsSelector.patient.Id}
                treatmentPlans={appoinmentsSelector.treatmentPlans}
                clinicId={appoinmentsSelector.appointmentDetails.clinicId}
                patientName={`${appoinmentsSelector.patient.FirstName} ${appoinmentsSelector.patient.LastName}`}
                isCompleted={isCompleted}
                isReceptionView={true}
                onTabChange={handleTabChange}
              />
            </Tab>

          </Tabs>
          
          <Modal
            show={receptionLockShow}
            onHide={handleReceptionLockModalClose}
            title="View All Appointments"
          >
            <ReceptionLock
              onHide={handleReceptionLockModalClose}
              buttonText={"Go To Appointments"}
              password={autenticateSelector.additionalPassword}
            />
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AppointmentDetailsReception;
