import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Main from '../layouts/Main/Main';
import Settings from '../pages/Settings/Settings';
import Landing from '../pages/Landing/Landing';
import Patients from '../pages/Patients/Patients';
import AddPatient from '../pages/Patients/AddPatient';
import UpdatePatient from '../pages/Patients/UpdatePatient';
import ViewPatient from '../pages/Patients/ViewPatient';
import Appointments from '../pages/Appointments/Appointments';
import AppointmentDetails from '../pages/Appointments/AppointmentDetails';
import AddAppointment from '../pages/Appointments/AddAppointment';
import Packages from '../pages/TreatmentPlans/Packages';
import MedHistory from '../pages/TreatmentPlans/MedHistory';
import Review from '../pages/TreatmentPlans/Review';
import DrugBook from '../pages/DrugBook/DrugBook';
import Queue from '../pages/VideoConsult/Queue';
import Conduct from '../pages/VideoConsult/Conduct';
import PrescriberDashboard from '../pages/VideoConsult/PrescriberDashboard';
import AdverseEvent from '../pages/AdverseEvent/AdverseEvent';
import AddAdverseEvent from '../pages/AdverseEvent/AddAdverseEvent';

const PrescriberRouters = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main {...props} />}>
          <Route index element={<PrescriberDashboard {...props}/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default PrescriberRouters;
