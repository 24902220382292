import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  patients: {},
  patientDetails: {},
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    savePatient(state, action) {
      const { reqData, type, history } = action.payload;
    },
    savePatientSuccess(state, action) {
      const data = action.payload;

      return { ...state, ...data };
    },
    getPatient(state, action) {},
    getPatientSuccess(state, action) {
      return { ...state, patientDetails: action.payload  };
    },
    deletePatient(state, action) {},
    updatePatient(state, action) {},
    addPatient(state,action){},
    searchPatients(state, action) {},
    getSearchPatient(state, action) {},
    getSearchPatientSuccess(state, action) {
      const patientsData = action.payload;
      let patients = {
        patients: { ...action.payload.Patients },
        totalCount: action.payload.TotalCount || 0,
      };
      return { ...state, ...patients };
    },
    discardPatient(state, action) {},
    getPatientFullDetails(state, action) {},
  },
});

export const {
  getPatient,
  getPatientSuccess,
  deletePatient,
  updatePatient,
  addPatient,
  searchPatients,
  getSearchPatient,
  getSearchPatientSuccess,
  discardPatient,
  getPatientFullDetails
} = patientSlice.actions;

export default patientSlice.reducer;
