import React, { useState } from 'react';
import closeIcon from "../../assets/images/icons/close-icon.svg";
import './Dropdown.css';

const Dropdown = ({ items, label }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenu = () => setShowMenu(!showMenu);

  return (
    <React.Fragment>
      <div className={showMenu ? "dropdown-wrapper open" : "dropdown-wrapper"}>
        <div className="dropdown-btn" onClick={() => handleMenu()}>{label}</div>
        {showMenu &&
          <div className="dropdown-menu">
            <div className="dropdown-menu-close" onClick={() => handleMenu()}><img src={closeIcon} alt="close" /></div>
            {items.map(({ id, label, onclick }) => {
              return <div key={id} onClick={() => {onclick(); handleMenu();}} className="dropdown-menu-item">{label}</div>
            })}
          </div>
        }
      </div>
    </React.Fragment>
  )
};

export default Dropdown;