import { useEffect, useRef, useState } from 'react';
import {
  CallClient,
  VideoStreamRenderer,
  LocalVideoStream,
} from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import './CallingWindow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faVideo,
  faVideoSlash,
  faCog,
  faPhoneSlash,
  faMicrophoneSlash,
  faPhoneFlip,
} from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../../components/Loading/Loading';
import './CallingPopup.css';
import { endCall } from '../../../../app/reducers/videoConsultSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTreatmentPlan, rejoin } from '../../../../app/reducers/treatmentPlanSlice';
import { Button } from '../../../../components/FormElements';
import { Tab, Tabs } from '../../../../components/Tabs/Tabs';
import ReviewDetails from '../../../TreatmentPlans/components/ReviewDetails';
import MedicalHistory from '../MedicalHistory';
import dynamicQuestions from '../../../Appointments/MedHistoryQuestions.json';
import { treatentPlanReceiver } from '../../../../app/receivers/appointmentDetailsReceiver';
import MedHistoryReception from '../../../Appointments/components/MedHistoryReception';
import { getAcsToken } from '../../../../app/reducers/communicationSlice';

const CallingComponent = ({ token, prescriberAcsId }) => {
  const startCallButtonRef = useRef(null);
  // const hangUpCallButtonRef = useRef(null);
  const acceptCallButtonRef = useRef(null);
  const videoButtonRef = useRef(null);
  const connectedLabelRef = useRef(null);
  const remoteVideosGalleryRef = useRef(null);
  const localVideoContainerRef = useRef(null);

  const patientSelector = useSelector((state) => state.patient.patientDetails);
  const authSelector = useSelector((state) => state.authentication);
  const videoConsult = useSelector((state) => state.videoConsult);
  const appoinmentSelector = useSelector(
    (state) => state.appointment
  );

  const authenticationSelector = useSelector((state) => state.authentication);
  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);

  const treatmentPlanReceiverData = treatentPlanReceiver(
    treatmentPlanSelector.treatmentInfo
  );

  const medicalHistoryValues = appoinmentSelector.medicalHistory;

  const defaultMedHistoryValues = {
    Smoke: "yes",
    Pregnant: "no",
    CosmeticInjection: null,
    SurgicalProcedures: null,
    FacialSurgery: null,
    DentalWork: null,
    HealthProblems: "yes",
    EpilepticSeizures: "no",
    ColdSores: "no",
    AutoImmune: "yes",
    AnyAllergies: "yes",
    Medication: null,
    ShortTermMedication: null,
    Vaccinations: null,
  };

  const [callAgent, setCallAgent] = useState(null);
  const [deviceManager, setDeviceManager] = useState(null);
  const [call, setCall] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  var [localVideoStream, setLocalVideoStream] = useState(null);
  const [localVideoStreamRenderer, setLocalVideoStreamRenderer] = useState([]);
  const [remoteVideoRenderers, setRemoteVideoRenderers] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showCallingPopup, setshowCallingPopup] = useState(false);
  const [callInProgress, setCallInProgress] = useState(true);
  const [isRejoinInProgress, setIsRejoinInProgress] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initializeCallAgent = async () => {
    try {
      setIsLoading(true);
      const callClient = new CallClient();
      const tokenCredential = new AzureCommunicationTokenCredential(
        token.trim()
      );

      if (callAgent) {
        await callAgent.dispose();
      }

      const agent = await callClient.createCallAgent(tokenCredential);

      // Set up a camera device to use.
      const manager = await callClient.getDeviceManager();
      await manager.askDevicePermission({ video: true });
      await manager.askDevicePermission({ audio: true });
      setCallAgent(agent);
      setDeviceManager(manager);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initializeValues = async () => {
      try {
        setIsLoading(true);
        const callClient = new CallClient();
        const tokenCredential = new AzureCommunicationTokenCredential(
          token.trim()
        );

        // sending data as patientName| NurseName {userName} | Pronouns | Gender | Clinic -> clinic Name of the appointment
        //   const agent = await callClient.createCallAgent(tokenCredential, {
        //     displayName:
        //     patientSelector.Name +
        //     " | " +
        //     authSelector.userName +
        //     " | " +
        //     patientSelector.Pronouns +
        //     " | " +
        //     patientSelector.Gender +
        //     " | " +
        //     appoinmentSelector.clinicName,
        // });

        const agentDisplayName = `${patientSelector.Name} | ${authSelector.userName} | ${patientSelector.Pronouns} | ${patientSelector.Gender} | ${appoinmentSelector.appointmentDetails.clinicName} | ${treatmentPlanSelector.id}`;
        const agent = await callClient.createCallAgent(tokenCredential, {
          displayName: agentDisplayName,
        });
        // Set up a camera device to use.
        const manager = await callClient.getDeviceManager();
        await manager.askDevicePermission({ video: true });
        await manager.askDevicePermission({ audio: true });

        return { agent, manager };
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeValues()
      .then(({ agent, manager }) => {
        setCallAgent(agent);
        setDeviceManager(manager);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    startCall();
  }, [deviceManager]);

  const createLocalVideoStream = async () => {
    const cameras = await deviceManager.getCameras();
    if (cameras.length > 0) {
      return new LocalVideoStream(cameras[0]);
    } else {
      console.error("No camera device found on the system");
    }
  };

  const onRefresh = () => {
    dispatch(
      getTreatmentPlan({
        data: treatmentPlanSelector, // 169 //treatmentPlanSelector.id,
      })
    );
  };

  const onNext = async () => {
    const { treatmentStatusId } = treatmentPlanSelector;
    const eligibleStatusesForRedirect = [4, 5];
    const redirectUrl = '/treatmentPlan/consent';
  
    if (eligibleStatusesForRedirect.includes(treatmentStatusId)) {
      if (call && call.state === 'Connected') {
        await hangUpCall(redirectUrl);
      } else {
        navigate(redirectUrl);
      }
    }
  };
  
  const onDashboard = () => {
    navigate("/");
  };

  const displayLocalVideoStream = async () => {
    try {
      if (localVideoStream) {
        const renderer = new VideoStreamRenderer(localVideoStream);
        const view = await renderer.createView();

        // Clear the previous contents of the container
        while (localVideoContainerRef.current.firstChild) {
          localVideoContainerRef.current.removeChild(
            localVideoContainerRef.current.firstChild
          );
        }

        // Append the new video stream view to the container
        localVideoContainerRef.current.appendChild(view.target);

        setLocalVideoStreamRenderer(renderer);
      } else {
        // Clear the local video container if the local video stream is null
        localVideoContainerRef.current.innerHTML = "";
        setLocalVideoStreamRenderer(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeLocalVideoStream = async () => {
    try {
      localVideoStreamRenderer.dispose();
      localVideoContainerRef.current.hidden = true;
      setLocalVideoStreamRenderer(null);
    } catch (error) {
      console.error(error);
    }
  };

  const subscribeToCall = (call) => {
    try {
      console.log(`Call Id: ${call.id}`);

      call.on("idChanged", () => {
        console.log(`Call Id changed: ${call.id}`);
      });

      console.log(`Call state: ${call.state}`);

      call.on("stateChanged", async () => {
        console.log(`Call state changed: ${call.state}`);
        if (call.state === "Connected") {
          setshowCallingPopup(false);
          setIsRejoinInProgress(false);
          // hangUpCallButtonRef.current.disabled = false;
          remoteVideosGalleryRef.current.hidden = false;
        } else if (call.state === "Disconnected") {
          setshowCallingPopup(false);
          setCallInProgress(false);
          setIsRejoinInProgress(false);
          // stopVideoButtonRef.current.disabled = true;
          if (
            (call.callEndReason.code === 487 && call.callEndReason.subCode === 10004) ||
            call.callEndReason.code === 603 ||
            (call.callEndReason.code === 480 && call.callEndReason.subCode === 10037) ||
            call.callEndReason.code === 480 || call.callEndReason.code === 408
          ) {
            setIsRejoinInProgress(true);
            let data = {
              id: treatmentPlanSelector.id,
              prescriberId: videoConsult.prescriberId,
            };
            dispatch(getAcsToken({id:authenticationSelector.id}));

            dispatch(
              rejoin({
                data: data,
                navigate,
                redirectUrl: "/videoconsult/queue",
              })
            );
          }
          console.log(
            `Call ended, call end reason={code=${call.callEndReason.code}, subCode=${call.callEndReason.subCode}}`
          );
        }
      });

      call.localVideoStreams.forEach(async (lvs) => {
        setLocalVideoStream(lvs);
        await displayLocalVideoStream();
      });

      call.on("localVideoStreamsUpdated", (e) => {
        e.added.forEach(async (lvs) => {
          setLocalVideoStream(lvs);
          await displayLocalVideoStream();
        });
        e.removed.forEach(() => {
          removeLocalVideoStream();
        });
      });

      call.remoteParticipants.forEach((remoteParticipant) => {
        subscribeToRemoteParticipant(remoteParticipant);
      });

      call.on("remoteParticipantsUpdated", (e) => {
        e.added.forEach((remoteParticipant) => {
          subscribeToRemoteParticipant(remoteParticipant);
        });
        e.removed.forEach((remoteParticipant) => {
          console.log("Remote participant removed from the call.");
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const subscribeToRemoteParticipant = (remoteParticipant) => {
    try {
      console.log(`Remote participant state: ${remoteParticipant.state}`);

      remoteParticipant.on("stateChanged", () => {
        console.log(
          `Remote participant state changed: ${remoteParticipant.state}`
        );
      });

      remoteParticipant.videoStreams.forEach((remoteVideoStream) => {
        subscribeToRemoteVideoStream(remoteVideoStream);
      });

      remoteParticipant.on("videoStreamsUpdated", (e) => {
        e.added.forEach((remoteVideoStream) => {
          subscribeToRemoteVideoStream(remoteVideoStream);
        });
        e.removed.forEach(() => {
          console.log("Remote participant video stream was removed.");
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const subscribeToRemoteVideoStream = async (remoteVideoStream) => {
    try {
      const renderer = new VideoStreamRenderer(remoteVideoStream);
      const view = await renderer.createView();
      const remoteVideoContainer = document.createElement("div");
      remoteVideoContainer.className = "remote-video-container";
      remoteVideoContainer.appendChild(view.target);
      remoteVideosGalleryRef.current.appendChild(remoteVideoContainer);
      setRemoteVideoRenderers((prevState) => [
        ...prevState,
        { stream: remoteVideoStream, renderer },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const onCallEnd = (redirectUrl) => {
    let data = {
      userId: videoConsult.prescriberId,
      videoConsultId: videoConsult.videoConsultingId,
    };
    dispatch(
      endCall({
        data: data,
        navigate,
        redirectUrl: redirectUrl,
      })
    );
  };

  const startCall = async () => {
    try {
      setshowCallingPopup(true);
      localVideoStream = await createLocalVideoStream();
      const videoOptions = localVideoStream
        ? { localVideoStreams: [localVideoStream] }
        : undefined;
      const newCall = callAgent.startCall(
        [{ communicationUserId: prescriberAcsId.trim() }],
        {
          videoOptions,
        }
      );
      setCall(newCall);
      subscribeToCall(newCall);
    } catch (error) {
      console.error(error);
    }
  };

  const acceptCall = async () => {
    try {
      localVideoStream = await createLocalVideoStream();
      const videoOptions = localVideoStream
        ? { localVideoStreams: [localVideoStream] }
        : undefined;
      const acceptedCall = await incomingCall.accept({ videoOptions });
      setCall(acceptedCall);
      subscribeToCall(acceptedCall);
    } catch (error) {
      console.error(error);
    }
  };

  const hangUpCall = async (redirectUrl = '/') => {
    console.log('hagup call trigerred');
    try {
      await call.hangUp();
      onCallEnd(redirectUrl);
      setCallInProgress(false);
      if (callAgent) {
        await callAgent.dispose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleMute = async () => {
    try {
      if (call && call.state === "Connected") {
        if (isMuted) {
          await call.unmute();
        } else {
          await call.mute();
        }
        setIsMuted(!isMuted);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleVideo = async () => {
    try {
      if (isVideoOn) {
        await call.stopVideo(localVideoStream);
      } else {
        await call.startVideo(localVideoStream);
      }
      setIsVideoOn(!isVideoOn);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="video-call-wrapper">
        <div className="row">
          <div className="col-md-8">
            <div className="call_wrapper ratio ratio-16x9">
              {callAgent == null ? (
                <div className="loading-wrapper">
                  <Loading></Loading>
                </div>
              ) : (
                <>
                  <br />
                  {showCallingPopup && (
                    <div className="calling-popup">
                      <div className="calling-popup-content">
                        <h2>Calling...</h2>
                        <h2>{videoConsult.presciberName}</h2>
                        <FontAwesomeIcon icon={faPhoneFlip} spin={true} />
                      </div>
                    </div>
                  )}
                  {callInProgress ? (
                    <>
                      <div
                        ref={remoteVideosGalleryRef}
                        className="MainVideo"
                      ></div>
                      <div ref={localVideoContainerRef} className="InVideo">
                        My Video Player
                      </div>
                      <div className="videoconsult_controls">
                        <button
                          type="button"
                          className={`videoconsult_btns ${
                            !isMuted ? "active" : "inactive"
                          }`}
                          onClick={toggleMute}
                        >
                          <i>
                            <FontAwesomeIcon
                              icon={isMuted ? faMicrophone : faMicrophoneSlash}
                            />
                          </i>
                          {isMuted ? "Unmute" : "Mute"}
                        </button>
                        <button
                          ref={videoButtonRef}
                          type="button"
                          className={`videoconsult_btns ${
                            isVideoOn ? "active" : "inactive"
                          }`}
                          onClick={toggleVideo}
                        >
                          <i>
                            <FontAwesomeIcon
                              icon={isVideoOn ? faVideoSlash : faVideo}
                            />
                          </i>
                          {isVideoOn ? "Stop Video" : "Start Video"}
                        </button>
                        <button
                          type="button"
                          className="videoconsult_btns inactive"
                        >
                          <i>
                            <FontAwesomeIcon icon={faCog} />
                          </i>
                          Settings
                        </button>
                        {/* <button
                          ref={hangUpCallButtonRef}
                          type="button"
                          onClick={hangUpCall}
                          disabled={!call}
                          className="videoconsult_btns attend active"
                        >
                          <i>
                            <FontAwesomeIcon icon={faPhoneSlash} />
                          </i>
                          Leave
                        </button> */}
                      </div>
                    </>
                  ) : (
                    <div className="calling-popup">
                      <div className="calling-popup-content">
                        <h5>Call Ended.</h5>
                        <FontAwesomeIcon icon={faPhoneSlash} />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <div className="vc-patient-wrapper">
                <div className="vc-patient">
                  <div className="vc-patient-name">{patientSelector.Name}</div>
                  <div className="vc-pronouns">{patientSelector.Pronouns}| {patientSelector.Gender}</div>
                </div>
              </div>
              <div className="vc-approval-wrapper">
                <div className="review-sub-heading text-pink mb-20">
                  Approval Status
                </div>
                <div className="row mb-20">
                  {treatmentPlanSelector.treatmentStatusId < 4 && (
                    <div>
                      Please refresh once Prescriber approve the treatment plan.
                    </div>
                  )}
                  {treatmentPlanSelector.treatmentStatusId === 4 && (
                    <div>
                      Prescriber approved the treatment plan, Please click next
                      to redirect the user consent page.
                    </div>
                  )}
                  {treatmentPlanSelector.treatmentStatusId === 7 && (
                    <div>
                      Treatment plan has been declined by prescriber, click next
                      to redirect to dashboard page.
                    </div>
                  )}
                </div>
                <div className="flex justify-end align-center mt-30">
                  {treatmentPlanSelector.treatmentStatusId < 4 && (
                    <Button style={{ minWidth: "165px" }} onClick={onRefresh}>
                      Refresh
                    </Button>
                  )}
                  {treatmentPlanSelector.treatmentStatusId === 4 && (
                    <Button style={{ minWidth: "165px" }} onClick={onNext}>
                      Next
                    </Button>
                  )}
                  {treatmentPlanSelector.treatmentStatusId === 7 && (
                    <Button style={{ minWidth: "165px" }} onClick={onDashboard}>
                      Go to Dashboard
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vc-patient-details-wrapper">
        <Tabs>
          <Tab controlId={"treatmentPlans"} label={"Treatment Plans"}>
            <ReviewDetails data={treatmentPlanReceiverData} isApproved={true} />
          </Tab>
          <Tab controlId={"medicalHistory"} label={"Medical History"}>
            <MedicalHistory
              defaultValues={medicalHistoryValues}
              lastUpdated={"10 May 2023"}
              dynamicQuestions={dynamicQuestions}
              isReadonly={true}
              role ={authenticationSelector.role}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default CallingComponent;
