import React from 'react';
import { TextInput, Button, Calendar } from '../../../components/FormElements';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { addDrugBookAdministered } from '../../../app/reducers/drugbookSlice';
import { datefromString } from '../../../utilities/dateUtils';

const AddAdministered = (props) => {
  const { onHide, data, isReadOnly } = props;
  const authSelector = useSelector((state) => state.authentication);
  const appointmentSelector = useSelector((state) => state.appointment);
  const clinicSelector = useSelector((state) => state.userClinic);

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productName: data ? data.Product : '',
      quantity: data ? data.Qty : '',
      batchNumber: data ? data.BatchNumber : '',
      // drugsReceivedEntry: data ? data.Product+", "+data.BatchNumber : '',
      dateAdministered: appointmentSelector?.appointmentDetails?.StrStartDate,
      administeredBy: authSelector.userDetails.name,
    },
  });

  const onSubmit = (data) => {
    dispatch(addDrugBookAdministered({
      ProductName: data.productName,
      Quantity :data.quantity,
      BatchNumber: data.batchNumber,
      AppointmentId : appointmentSelector.appointmentDetails.AppointmentId,
      ClinicId :clinicSelector.UserClinicId,
      AdministeredDate: datefromString(data.dateAdministered),
      UserId: authSelector.id
    }))
    onHide();
    
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='add-form'>
          <div className='row'>
            <div className='col-md-6'>
              <Controller
                name='productName'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Product Name'
                    controlId='productName'
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Product Name is required!',
                  }
                }}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='quantity'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Quantity'
                    controlId='quantity'
                    type="number"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Quantity by is required!',
                  }
                }}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='batchNumber'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Batch Number'
                    controlId='batchNumber'
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Batch Number is required!',
                  }
                }}
              />
            </div>
            {/* <div className='col-md-6'>
              <Controller
                name='drugsReceivedEntry'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Drugs Received Entry'
                    controlId='drugsReceivedEntry'
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Drugs Received Entry is required!',
                  }
                }}
              />
            </div> */}
            {/* <div className='col-md-6'>
              <Controller
                name='patient'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Patient'
                    controlId='patient'
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Patient is required!',
                  }
                }}
              />
            </div> */}
            {/* <div className='col-md-6'>
              <Controller
                name='clinic'
                control={control}
                render={({ field }) => (
                  <SelectBox
                    label='Clinic'
                    options={clinics}
                    name='clinic'
                    readOnly={isReadOnly}
                    {...field}
                  />
                )}
              />
            </div> */}
            <div className='col-md-6'>
              <Controller
                name='dateAdministered'
                control={control}
                render={({ field }) => (
                  <Calendar
                    label='Date Administered'
                    controlId='dateAdministered'
                    disabled={true}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Date Administered is required!',
                  }
                }}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='administeredBy'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Administered by'
                    controlId='administeredBy'
                    disabled={true}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Administered by is required!',
                  }
                }}
              />
            </div>

          </div>
        </div>
        {!isReadOnly &&
          <div className='modal-footer'>
            <div className='cancel-link' onClick={() => onHide()}>
              Cancel
            </div>
            <Button size='sm'>Add Entry</Button>
          </div>
        }
      </form>
    </React.Fragment>
  );
};

export default AddAdministered;