import React, { useEffect, useState } from "react";
import "./AutocompleteNurse.css"
import axios from "axios";
import {envEndPoints } from '../../app/config'

const AutocompleteNurse = React.forwardRef((props, ref) => {
  const { suggestions, label, controlId, type, onValueChange, clearValue=false, setClearValue, initialValue, ...otherProps } = props;
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  //const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [filteredMapSuggestions, setFilteredMapSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(initialValue ? initialValue : "");
  const [productId, setProductId] = useState(0);

  let nurseBaseUrl = `${envEndPoints().baseUrl}/user`; 
  

  useEffect(() => {
    onValueChange(initialValue)
  },[initialValue]);

  const onChange = (e) => {
    let filteredSuggestions = ''
    const userInput = e.currentTarget.value;    
    if (e.currentTarget.value.length >= 3) {
        let nurseUrl =  `${nurseBaseUrl}/searchNurse/${e.currentTarget.value}`;       
        axios.get(nurseUrl).then((response) => {          
          let filteredSuggestions = response.data.map((d) => d.Name);          
          let nurseData = response.data.map((d) => {return {Id: d.Id, Name: d.Name}});
          setFilteredMapSuggestions(nurseData);
          setFilteredSuggestions(filteredSuggestions);                            
        });
    } else {      
      setFilteredSuggestions(filteredSuggestions);      
    }
    setActiveSuggestion(0);
    setShowSuggestions(true);    
    setUserInput(e.currentTarget.value);
  };

  const onClick = (e) => {            
    let nurseId = filteredMapSuggestions.find((x) => x.Name === e.currentTarget.innerText).Id;
    setFilteredMapSuggestions([]);
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(e.currentTarget.innerText);
    onValueChange(e.currentTarget.innerText, nurseId);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(filteredSuggestions[activeSuggestion]);
    }
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);

    }
  };

  useEffect(()=> {
    if(clearValue) {
      setUserInput('');
      setClearValue(false)
    }
  }, [clearValue]);

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  return (
    <div className="autocomplete-wrapper">
      <div className="floating-input">
        <input
          ref={ref}
          className="text-input"
          id={controlId}
          type="text"
          placeholder=" "
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          autocomplete="off"
          {...otherProps}
        />
        <label className="floating-label" htmlFor={controlId}>{label}</label>
      </div>
      {suggestionsListComponent}
    </div>
  )
});

export default AutocompleteNurse;