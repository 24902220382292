import React, { useEffect } from 'react';
import './VideoConsult.css';
import StartCall from './components/VideoCall/StartCall';
import { useSelector } from 'react-redux';

const Conduct = () => {
  //const { callerAcsToken, prescriberAcsToken, prescriberAcsId } = location.state;

  const callerAcsToken = useSelector((state) => state.communication.acsToken);
  const prescriberAcsId = useSelector((state) => state.videoConsult.acsId);

  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  //const callLocator = {"groupId": "ae6ae651-1c8a-11ee-b655-036b1b79499c"};
  // const participantIds = ["8:acs:2a308d5d-75cf-485d-be4d-39a15614b376_00000019-dcd6-a201-520c-6b3a0d0014f1"];

  return (
    <React.Fragment>
        {
          !!treatmentPlanSelector &&
          !!callerAcsToken &&(
            <div>
              <StartCall
                token={callerAcsToken}
                prescriberAcsId={prescriberAcsId}
                //callLocator={callLocator}
                // participantIds={participantIds}
              />
            </div>
          )}
      
    </React.Fragment>
  );
};

export default Conduct;
