import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../components/FormElements';

import './TreatmentPlans.css';
import Heading from '../../components/Heading/Heading';
import Injectibles from './components/Injectibles';
import Threads from './components/Threads';
import PRP from './components/PRP';

import { useSelector, useDispatch } from 'react-redux';
import { createTreatmentPlan } from '../../app/reducers/treatmentPlanSlice';
import { getTreatmentplanModel } from './constants/Index';
import { packageTypes } from '../../constants/index';
import TreatmentValidation from './TreatmentValidation';
import Modal from '../../components/Modal/Modal';

const Packages = () => {
  const [packageType, setPackageType] = useState('');
  const [validationPopupShow, setValidationPopupShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);
  const appointmentSelector = useSelector((state) => state.appointment);
  //setPackageType(treatmentPlanSelector.treatmentInfo.packageType);
  let defaultValues = treatmentPlanSelector.treatmentInfo;

  // if (defaultValues.packageType === '') {
  //   defaultValues.packageType = 'injectibles';
  // }
  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;

  useEffect(() => {
    setPackageType(defaultValues.packageType);
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  useEffect(() => {
    if (treatmentPlanSelector.treatmentInfo?.packageType === "injectibles") {
    const treatmentInfoCheck =
        treatmentPlanSelector.treatmentInfo?.antiWrinkle.every(
          (aw) => !aw.productName
        ) &&
        treatmentPlanSelector.treatmentInfo?.filler.every(
          (filler) =>
            !filler.areaName 
        ) &&
        treatmentPlanSelector.treatmentInfo?.other.every(
          (other) => !other.productName
        );
  
      const antiWrinkleCheck =
        treatmentPlanSelector.treatmentInfo?.antiWrinkle.every(
          (aw) =>
            !(!aw.productName &&
            !aw.units) &&
            (!aw.treatmentAreas ||
              !Object.values(aw.treatmentAreas).some((value) => value === true))
        );
  
      const fillerCheck = treatmentPlanSelector.treatmentInfo?.filler.every(
        (filler) =>
          filler.areaName !== "" &&
          (filler.products
            ? filler.products.every((product) => product.units === "")
            : true)
      );

      const otherCheck = treatmentPlanSelector.treatmentInfo?.other.every(
        (other) => other.productName !== "" && !(other.units !== "")
      );
  
      if(treatmentInfoCheck || antiWrinkleCheck || fillerCheck || otherCheck){
        setValidationPopupShow(true);
      } 
    }
  }, [treatmentPlanSelector.treatmentInfo]);


  const onSubmit = (data) => {
    dispatch(
      createTreatmentPlan({
        data: getTreatmentplanModel(
          { ...data, packageType: packageType },
          treatmentPlanSelector.patientId,
          treatmentPlanSelector.appointmentId,
          treatmentPlanSelector.id,
          treatmentPlanSelector.clinicId,
          "",
          packageType,
          appointmentSelector.medicalHistory.Id
        ),
        navigate,
        redirectUrl:""
    })
    );
  };

  const handleValidationPopupShow = () => setValidationPopupShow(false);

  return (
    <React.Fragment>
      <Heading
        title='Treatment Plan'
        subHeading={`Compile a new treatment plan for  ${treatmentPlanSelector.patientName}`}
      />
      
      <Modal
        show={validationPopupShow}
        title='Warning:'
      >
        <TreatmentValidation
          onHide={handleValidationPopupShow}
        />
      </Modal>
      <div className='mt-60'>
        <div className='flex align-center mb-50'>
          <div className='view-btn-group'>
            <div
              onClick={() => setPackageType(packageTypes.injectibles)}
              className={
                packageType === packageTypes.injectibles
                  ? 'view-btn-item active'
                  : 'view-btn-item'
              }
            >
              Injectibles
            </div>
            <div
              onClick={() => setPackageType(packageTypes.prp)}
              className={
                packageType === packageTypes.prp
                  ? 'view-btn-item active'
                  : 'view-btn-item'
              }
            >
              PRP
            </div>
            <div
              onClick={() => setPackageType(packageTypes.threads)}
              className={
                packageType === packageTypes.threads
                  ? 'view-btn-item active'
                  : 'view-btn-item'
              }
            >
              Threads
            </div>
          </div>
          <div className='package-note-text'>
            What type of package would you like to include in your package?
          </div>
        </div>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {packageType === packageTypes.injectibles && <Injectibles />}
            {packageType === packageTypes.prp && <PRP />}
            {packageType === packageTypes.threads && <Threads />}
            <hr className='divider mt-50 mb-50' />
            <div className='flex justify-end align-center'>
              <Link className='cancel-link' to='/appointments/details'>
                Cancel
              </Link>
              <Button
                disabled={packageType === '' && true}
                style={{ minWidth: '165px' }}
               
              >
                Next
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </React.Fragment>
  );
};

export default Packages;
