import React from 'react';
import { Link } from 'react-router-dom';
import './Landing.css';

import SettingsIcon from '../../assets/images/menu-icons/settings.svg';
import ReportsIcon from '../../assets/images/menu-icons/reports.svg';
import AppointmentsIcon from '../../assets/images/menu-icons/appointments.svg';
import PatientsIcon from '../../assets/images/menu-icons/patients.svg';
import AdverseEventIcon from '../../assets/images/icons/adverse-events.svg';
import DrugbBookIcon from '../../assets/images/icons/drugbook.svg';
import { Roles } from '../../constants';

const Landing = (props) => {
  return (
    <React.Fragment>
      <div className='landing-page-heading'>What are you looking for?</div>
      <div className='menu-button-wrapper'>
        {props.role === Roles.Admin && (
          <Link to='/settings' className='menu-button-item'>
            <img src={SettingsIcon} alt='Settings' />
            <div className='btn btn-primary btn-sm'>Settings</div>
          </Link>
        )}
        {props.role === Roles.Admin && (
          <Link to='/reporting' className='menu-button-item'>
            <img src={ReportsIcon} alt='Reports' />
            <div className='btn btn-primary btn-sm btn-blue'>Reports</div>
          </Link>
        )}
        <Link to='/appointments' className='menu-button-item'>
          <img src={AppointmentsIcon} alt='Appointments' />
          <div className='btn btn-primary btn-sm btn-tan'>Appointments</div>
        </Link>
        <Link to='/patient' className='menu-button-item'>
          <img src={PatientsIcon} alt='Patients' />
          <div className='btn btn-primary btn-sm'>Patients</div>
        </Link>
        <Link to='/drugbook' className='menu-button-item'>
          <img src={DrugbBookIcon} alt='DrugBook' />
          <div className='btn btn-primary btn-sm btn-tan'>Drug Book</div>
        </Link>
        <Link to='/adverseevent' className='menu-button-item'>
          <img src={AdverseEventIcon} alt='Adverse Events' />
          <div className='btn btn-primary btn-sm btn-tan'>Adverse Events</div>
        </Link>
        {/* {props.role === Roles.Admin && (
          <Link to='/' className='menu-button-item'>
            <img src={ArchivesIcon} alt='Archives' />
            <div className='btn btn-primary btn-sm btn-tan'>Archives</div>
          </Link>
        )} */}
      </div>
    </React.Fragment>
  );
};

export default Landing;
