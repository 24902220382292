import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Heading from '../../components/Heading/Heading';
import {
  Calendar,
  TextInput,
  Button,
  SelectBox,
} from '../../components/FormElements';
import noPatientIcon from '../../assets/images/icons/no-patient-icon.svg';
import Table from '../../components/Table/Table';
import AppointmentCard from '../../components/AppointmentCard/AppointmentCard';
import NoData from '../../components/NoData/NoData';
import { searchPatients } from '../../app/reducers/patientSlice';
import { useForm, Controller } from 'react-hook-form';
import { createLocalAppointment, refreshAppointment } from '../../app/reducers/appointmentSlice';
import moment from 'moment-timezone'

import './Appointments.css';
import { useSelector, useDispatch } from 'react-redux';
import AutocompleteClinic from '../../components/AutocompleteClinic/AutocompleteClinic';

const AddAppointment = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [searchPatient, setSearchPatient] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [appDetails, setAppDetails] = useState(null);
  const [clinicId, setClinicId] = useState(null);
  const [clinicName, setClinicName] = useState(null);
  const [DOB, setDob] = useState(null);  
  const data = [];
  const patientsSelector = useSelector((state) => state.patient);  
  const appointmentSelector = useSelector((state) => state.appointment);
  const userSelector = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm();

  const onSubmit = (data) => {
    const updatedData = {
      ...data,
      DOB: DOB
        ? new Date(
            DOB.getTime() - DOB.getTimezoneOffset() * 60000
          ).toISOString()
        : null,
    };
    dispatch(
      searchPatients({
        ...updatedData,
      })
    );
    setSearchClicked(true);
    setStep(2);
    setSearchPatient(
      (updatedData.FirstName !== undefined ? updatedData.FirstName : '') +
        ' ' +
        (updatedData.LastName !== undefined ? updatedData.LastName : '') +
        ' ' +
        (updatedData.DOB != null
          ? new Date(updatedData.DOB).toLocaleDateString('en-GB')
          : '') +
        ' ' +
        (updatedData.MobileNumber !== undefined
          ? updatedData.MobileNumber
          : '') +
        ' ' +
        (updatedData.EmailId !== undefined ? updatedData.EmailId : '')
    );
  };


  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const {
    control: controlRefresh,
    formState: { errors: errorsRefresh },
    handleSubmit: handleSubmitRefresh,
    setValue: setRefreshValue
  } = useForm();

  const onSubmitRefresh = (formData) => {    
    let data = {
      PatientId: selectedPatient.Id,
      AppointmentDate: moment(formData.appDate).format('MM/DD/YYYY'),
      HourTime: formData.hour.value,
      MinuteTime: formData.minute.value,
      Duration: formData.duration.value,
      ClinicId: clinicId 
    };
    setAppDetails(data);
    dispatch(refreshAppointment({data: data}));    
    setStep(4);
  };

  const {
    control: controlCreateApp,
    formState: { errors: errorsCreateApp },
    handleSubmit: handleSubmitCreateApp,
    setValue: setCreateAppValue
  } = useForm();

  const onSubmitCreateApp = (formData) => {    
    debugger;
    let data = {
      PatientId: selectedPatient.Id,
      AppointmentDate: appDetails.AppointmentDate,
      HourTime: appDetails.HourTime,
      MinuteTime: appDetails.MinuteTime,
      Duration: appDetails.Duration,
      ClinicId: appDetails.ClinicId 
    };
    dispatch(createLocalAppointment({
      data: data,
      navigate,
      redirectUrl: '/appointments',
    }));    
  };

  

  const filteredData = data?.filter((el) => {
    if (searchPatient !== '') {
      return el.Name.toLowerCase().includes(searchPatient);
    } else {
      return null;
    }
  });

  const handleAddClick = (rowId) => {
    let selectedPatient = Object.values(patientsSelector.patients).find((x) => x.Id === rowId); //.find((x) => { x.Id === rowId });    
    setSelectedPatient(selectedPatient);
    setRefreshValue("patientname", selectedPatient.Name);
    setStep(3);
  };

  const onContinueClick = () => {
    setStep(5);
    setCreateAppValue("createPatientName", selectedPatient.Name);
    setCreateAppValue("createAppDate", appDetails.AppointmentDate);
    setCreateAppValue("createHour", getSelectedHours(appDetails.HourTime));
    setCreateAppValue("createMinute", getSelectedMinutes(appDetails.MinuteTime));
    setCreateAppValue("createDuration", getSelectedDurations(appDetails.Duration));
    setCreateAppValue("createClinic", clinicName);
  };

  const columns = [
    { label: 'Name', controlId: 'Name', sortable: true },
    { label: 'DOB', controlId: 'DOB', sortable: true },
    { label: 'Email', controlId: 'EmailId', sortable: true },
    { label: 'Mobile', controlId: 'Mobile', sortable: true },
    { label: 'Home Clinic', controlId: 'HomeClinic', sortable: true },
    { label: 'Pronouns', controlId: 'Pronouns', sortable: true },
    { label: 'Last appt', controlId: 'LastAppt', sortable: true },
  ];

  const action = [{ id: 1, label: 'Add', onclick: handleAddClick }];

  const options = [
    { value: 'option1', label: 'Option one', Id: 1 },
    { value: 'option2', label: 'Option Two', Id: 2 },
    { value: 'option3', label: 'Option Three', Id: 3 },
    { value: 'option4', label: 'Option Four', Id: 4 },
  ];

  const hours = [
    { Id: 1, label: '08', value: '8'},
    { Id: 2, label: '09', value: '9'},
    { Id: 3, label: '10', value: '10'},
    { Id: 4, label: '11', value: '11'},
    { Id: 5, label: '12', value: '12'},
    { Id: 6, label: '13', value: '13'},
    { Id: 7, label: '14', value: '14'},
    { Id: 8, label: '15', value: '15'},
    { Id: 9, label: '16', value: '16'},
    { Id: 10, label: '17', value: '17'},
    { Id: 11, label: '18', value: '18'},
  ];

  const minutes = [
    { Id: 1, label: '00', value: '0'},
    { Id: 2, label: '05', value: '5'},
    { Id: 3, label: '10', value: '10'},
    { Id: 4, label: '15', value: '15'},
    { Id: 5, label: '20', value: '20'},
    { Id: 6, label: '25', value: '25'},
    { Id: 7, label: '30', value: '30'},
    { Id: 8, label: '35', value: '35'},
    { Id: 9, label: '40', value: '40'},
    { Id: 10, label: '45', value: '45'},
    { Id: 11, label: '50', value: '50'},
    { Id: 12, label: '55', value: '55'}
  ];

  const durations = [
    { Id: 1, label: '05', value: '0'},
    { Id: 2, label: '10', value: '5'},
    { Id: 3, label: '15', value: '15'},
    { Id: 4, label: '20', value: '20'},
    { Id: 5, label: '25', value: '25'},
    { Id: 6, label: '30', value: '30'},
    { Id: 7, label: '35', value: '35'},
    { Id: 8, label: '40', value: '40'},
    { Id: 9, label: '45', value: '45'},    
  ];

  const getSelectedHours = (val) => {
    let selected = hours.filter((f) => f.value === val);
    if (selected.length > 0) {
      return selected[0];
    } else {
      return hours[0];
    }
  };

  const getSelectedMinutes = (val) => {
    let selected = minutes.filter((f) => f.value === val);
    if (selected.length > 0) {
      return selected[0];
    } else {
      return minutes[0];
    }
  };

  const getSelectedDurations = (val) => {
    let selected = durations.filter((f) => f.value === val);
    if (selected.length > 0) {
      return selected[0];
    } else {
      return durations[0];
    }
  };

  const nurses = userSelector.nurses.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  const watchData = watch();

  const validate = {
    isFirstName:
      watchData.FirstName && watchData.FirstName.length >= 3 ? true : false,
    isSurname:
      watchData.LastName && watchData.LastName.length >= 3 ? true : false,
    isDob: DOB ? true : false,
    isMobileNumber:
      watchData.MobileNumber && watchData.MobileNumber.length === 10
        ? true
        : false,
    isEmailAddress:
      watchData.EmailId &&
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(watchData.EmailId)
        ? true
        : false,
  };

  const validateAtLeastOneField = () => {
    const isValidate = Object.entries(validate).filter(
      ([key, value]) => value === true
    );
    return isValidate.length >= 2 ? true : false;
  };

  return (
    <React.Fragment>
      <Heading title='Appointments' subHeading={'Add a new appointment'} />
      <div className='mt-60'>
        {step === 1 || step === 2 ? (
          <React.Fragment>
            <div className='search-heading'>
              {step === 1 ? 'Find patient profile' : 'Select Patient Profile'}
            </div>
            <div className='search-control-wrapper'>
              <div className='search-control'>
                <div className='search-control-item'>
                  <TextInput
                    label='First Name'
                    controlId='FirstName'
                    {...register('FirstName', {
                      validate: validateAtLeastOneField,
                    })}
                  />
                </div>
                <div className='search-control-item'>
                  <TextInput
                    label='Surname'
                    controlId='LastName'
                    {...register('LastName', {
                      validate: validateAtLeastOneField,
                    })}
                  />
                </div>
                <div className='search-control-item'>
                  <Calendar
                    label='Date of Birth'
                    controlId='DOB'                                        
                    onChange={(date) => setDob(date)}
                  />
                </div>
                <div className='search-control-item'>
                  <TextInput
                    label='Mobile Number'
                    controlId='MobileNumber'
                    type='number'
                    {...register('MobileNumber', {
                      validate: validateAtLeastOneField,
                    })}
                  />
                </div>
                <div className='search-control-item'>
                  <TextInput
                    label='Email Address'
                    controlId='EmailId'
                    {...register('EmailId', {
                      validate: validateAtLeastOneField,
                    })}
                  />
                </div>
              </div>
              <Button
                onClick={handleSubmit(onSubmit)}
                disabled={!validateAtLeastOneField()}
              >
                Search
              </Button>
            </div>

            {searchPatient ? (
              <React.Fragment>
                {Object.keys(patientsSelector?.patients)?.length > 0 ? (
                  <div>
                    <div className='search-results-no'>
                      {Object.keys(patientsSelector?.patients)?.length} Search
                      results for: <strong>{searchPatient}</strong>
                    </div>
                    <Table
                      data={Object.values(patientsSelector.patients)}
                      columns={columns}
                      action={action}
                    />
                  </div>
                ) : (
                  <NoData
                    variant='pink'
                    icon={noPatientIcon}
                    title='Patient Profile Not Found'
                    caption={[
                      'Unfortunately we didn’t find any patient profiles matching: ',
                      <strong>{searchPatient}</strong>,
                    ]}
                  />
                )}
              </React.Fragment>
            ) : (
              searchClicked && (
                <React.Fragment>
                  <div>
                    <Table
                      data={Object.values(patientsSelector.patients)}
                      columns={columns}
                      action={action}
                    />
                  </div>
                </React.Fragment>
              )
            )}

            <hr className='divider mt-30 mb-50' />
            <div className='flex justify-end align-center'>
              <Link className='cancel-link' to='/appointments'>
                Cancel
              </Link>
            </div>
          </React.Fragment>
        ) : null}

        {step === 3 ? (
          <React.Fragment>
            <form key={2}  onSubmit={handleSubmitRefresh(onSubmitRefresh)} >
              <div className='row'>
                <div className='col-md-6'>                  
                  <Controller
                      control={controlRefresh}
                      name="patientname"
                      render={({ field }) => (
                        <TextInput label='Patient Name' controlId='patientname' errors={errorsRefresh.patientname && true} readOnly={true} {...field}  />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: 'Patient Name is required!',
                        },
                      }}
                    />
                </div>
                <div className='col-md-6'>                  
                  <Controller
                    control={controlRefresh}                    
                    name="appDate"
                    render={({ field }) => (
                      <Calendar
                        ref={field.ref}
                        label="Date of Appointment"
                        controlId="appDate"
                        {...field}
                        onChange={(date) => field.onChange(date)}
                      />
                    )}
                  />
                </div>
                <div className='col-md-2'>                  
                  <Controller
                    control={controlRefresh}
                    name="hour"
                    render={({ field }) => (
                      <SelectBox
                        label="Hour"
                        options={hours}
                        name="hour"
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className='col-md-2'>                  
                  <Controller
                    control={controlRefresh}
                    name="minute"
                    render={({ field }) => (
                      <SelectBox
                        label="Minute"
                        options={minutes}
                        name="minute"
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className='col-md-2'>
                  <Controller
                    control={controlRefresh}
                    name="duration"
                    render={({ field }) => (
                      <SelectBox
                        label="Duration"
                        options={durations}
                        name="duration"
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className='col-md-6'>
                  <Controller
                    name='clinic'
                    control={controlRefresh}
                    render={({ field: { onChange, value } }) => (
                      <AutocompleteClinic
                        label='Clinic'
                        controlId='clinic'
                        onValueChange={(e,i) => {
                          onChange(e);
                          setClinicName(e);
                          setClinicId(i);
                        }}
                      />
                    )}
                  />
                </div>              
              </div>
              <hr className='divider mt-30 mb-50' />
              <div className='flex justify-end align-center'>
                <Link className='cancel-link' to='/appointments'>
                  Cancel
                </Link>
                <Button style={{ minWidth: '233px' }}>
                  Continue
                </Button>
              </div>
            </form>
          </React.Fragment>
        ) : null}

        {(step === 4 && appointmentSelector.zenotiAppointment?.length > 0) ? (
          <React.Fragment>
            <div className='search-heading'>
              We found a matching appointment.
              <p>
                If you’d like to use the below appointment, please select it, as
                opposed to continuing to create a new appointment.
              </p>
            </div>
            <div className='row mt-50'>
              <div className='col-md-4'>
                {appointmentSelector.zenotiAppointment &&
                  appointmentSelector.zenotiAppointment.map((item) => (
                    <div key={item.Id} className='col-md-4'>
                      <AppointmentCard data={item} />
                    </div>
                  ))
                } 
              </div>
            </div>
            <hr className='divider mt-30 mb-50' />
            <div className='flex justify-end align-center'>
              <Link className='cancel-link' to='/appointments'>
                Cancel
              </Link>
              <Button onClick={() => setStep(5)} style={{ minWidth: '233px' }}>
                Continue
              </Button>
            </div>
          </React.Fragment>
        ) : null}

        {(step === 4 && !appointmentSelector.zenotiAppointment) ? (
          <React.Fragment>
            <div className='search-heading'>
            We've checked and this appointment isn't in Zenoti. Please select continue to add a new appointment now.              
            </div>
            <div className='row mt-50'>
              <div className='col-md-4'>                
              </div>
            </div>
            <hr className='divider mt-30 mb-50' />
            <div className='flex justify-end align-center'>
              <Link className='cancel-link' to='/appointments'>
                Cancel
              </Link>
              <Button onClick={() => onContinueClick()} style={{ minWidth: '233px' }}>
                Continue
              </Button>
            </div>
          </React.Fragment>
        ) : null}

        {step === 5 ? (
          <React.Fragment>
              <form key={3} onSubmit={handleSubmitCreateApp(onSubmitCreateApp)} >
                  <div className='row'>
                    <div className='col-md-6'>                  
                      <Controller
                          control={controlCreateApp}
                          name="createPatientName"
                          render={({ field }) => (
                            <TextInput label='Patient Name' controlId='createPatientName' readOnly={true} {...field}  />
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: 'Patient Name is required!',
                            },
                          }}
                        />
                    </div>
                    <div className='col-md-6'>                  
                      <Controller
                        control={controlCreateApp}                    
                        name="createAppDate"
                        render={({ field }) => (
                          <Calendar
                            ref={field.ref}
                            label="Date of Appointment"
                            controlId="createAppDate"
                            readOnly={true}
                            {...field}
                            onChange={(date) => field.onChange(date)}
                          />
                        )}
                      />
                    </div>
                    <div className='col-md-2'>                  
                      <Controller
                        control={controlCreateApp}
                        name="createHour"
                        render={({ field }) => (
                          <SelectBox
                            label="Hour"
                            options={hours}
                            name="createHour"
                            readOnly={true}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className='col-md-2'>                  
                      <Controller
                        control={controlCreateApp}
                        name="createMinute"
                        render={({ field }) => (
                          <SelectBox
                            label="Minute"
                            options={minutes}
                            name="createMinute"
                            readOnly={true}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Controller
                        control={controlCreateApp}
                        name="createDuration"
                        render={({ field }) => (
                          <SelectBox
                            label="Duration"
                            options={durations}
                            name="createDuration"
                            readOnly={true}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Controller
                        name='createClinic'
                        control={controlCreateApp}
                        render={({ field: { onChange, value } }) => (
                          <AutocompleteClinic
                            label='Clinic'
                            controlId='createClinic'
                            initialValue={value}
                            readOnly={true}
                            onValueChange={(e,i) => {
                              onChange(e);
                              setClinicId(i);
                            }}
                          />
                        )}
                      />
                    </div> 
                    {/* <div className='col-md-6'>
                      <SelectBox
                        label='Type of Appointment'
                        options={options}
                        name='TypeofAppointment'
                      />
                    </div>        */}
                    <div className='col-md-6'>
                      <Controller
                          control={controlCreateApp}
                          name="createNurse"
                          render={({ field }) => (
                            <SelectBox
                              label="Nurse"
                              options={nurses}
                              name="createNurse"
                              {...field}
                            />
                          )}
                       />
                    </div>
                  </div>
                  <hr className='divider mt-30 mb-50' />
                  <div className='flex justify-end align-center'>
                    <Link className='cancel-link' to='/appointments'>
                      Cancel
                    </Link>
                    <Button style={{ minWidth: '233px' }}>
                      Create Appointment
                    </Button>
                  </div>
              </form>
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default AddAppointment;
