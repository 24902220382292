import React, { useEffect, useState } from 'react';
import NoData from '../../components/NoData/NoData';
import { Calendar, TextInput, Button } from '../../components/FormElements';
import Table from '../../components/Table/Table';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import Deactivate from '../../components/Deactivate/Deactivate';
import { useForm } from 'react-hook-form';
import Pagination from '../../components/Pagination/Pagination';

import noPatientIcon from '../../assets/images/icons/no-patient-icon.svg';

import { useSelector, useDispatch } from 'react-redux';

import { getPatient, searchPatients, discardPatient, getPatientFullDetails } from '../../app/reducers/patientSlice';

import './Patients.css';

const ViewPatients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pageSize = 20;

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = useForm();

  const [searchFor, setSearchFor] = useState('');
  const [deactivateModalShow, setDeactivateModalShow] = useState(false);
  const [discardModalShow, setDiscardModalShow] = useState(false);
  const [DOB, setDob] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState([]);

  const [searchClicked, setSearchClicked] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);  
  const [order, setSortOrder] = useState('ASC');
  const [sortedColumn, setSortedColumn] = useState('');
  
  const patientsSelector = useSelector((state) => state.patient);  
  
  const onPageIndexChange = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    const data = {
      ...searchData,
      pageIndex: pageIndex,
      pageSize: pageSize,
      ...(sortedColumn && { parameter: sortedColumn }),
      ...(order && { order: order }),
    };
    dispatch(searchPatients({...data}));
  };

  const userClinicId = useSelector((state) => state.userClinic.UserClinicId);
  // useEffect(() => {
  //   setCurrentPageIndex(1);
  //   dispatch(searchPatients({ClinicId: userClinicId, PageIndex: currentPageIndex, PageSize: pageSize }));
  // }, [userClinicId]);

  const handleEditClick = (rowId) => {
    dispatch(
      getPatient({
        id: rowId,
        navigate,
        redirectUrl: '/patient/update',
      })
    );
  };

  const handleViewClick = (rowId) => {   
    
    dispatch(
      getPatientFullDetails({
        id: rowId,
        navigate,
        redirectUrl: '/patient/view',
      })
    );
  };

  const handleDeactiveClick = (rowId) => {
    setDeactivateModalShow(true);
  };

  const handleDiscardClick = (rowId) => {
    const patientArray = Object.entries(patientsSelector?.patients).map(([key, value]) => value);
    const discardRecord = patientArray.filter((f) => f.Id === rowId);
    setSelectedPatient(discardRecord);
    setDiscardModalShow(true);
  };

  const handleDeactivateModalClose = () => setDeactivateModalShow(false);

  const onDiscardClick = (Id) => {
    dispatch(discardPatient({ id: Id }));
    setDiscardModalShow(false);
  };
  
  const handleSort = (columnId,formData) => {
    if (sortedColumn == "" || sortedColumn === columnId) {
      setSortOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } 
    setSortedColumn(columnId);
    setCurrentPageIndex(1);
    const updatedData = {
      ...formData,
      DOB: DOB
      ? new Date(DOB.getTime() - DOB.getTimezoneOffset() * 60000).toISOString()
      : null,
      ClinicId: userClinicId,
      PageIndex: 1, 
      PageSize: pageSize,
      Parameter:columnId, 
      Order: order === 'ASC' ? 'DESC' : 'ASC'
    }
    dispatch(searchPatients({...updatedData}));
  };

  const handleDiscardeModalClose = () => setDiscardModalShow(false);

  const columns = [
    {
      label: 'Name',
      controlId: 'Name',
      sortable: true,
    },
    {
      label: 'DOB',
      controlId: 'StrDOB',
      sortable: true,
    },
    {
      label: 'Email',
      controlId: 'EmailId',
      sortable: true,
    },
    {
      label: 'Mobile',
      controlId: 'MobileNumber',
      sortable: true,
    },
    {
      label: 'Home Clinic',
      controlId: 'HomeClinic',
      sortable: true,
    },
    {
      label: 'Pronouns',
      controlId: 'Pronouns',
      sortable: true,
    },
    {
      label: 'Last appt',
      controlId: 'LastAppt',
      sortable: true,
    },
  ];

  const action = [
    { id: 1, label: 'Edit', onclick: handleEditClick },
    { id: 2, label: 'View', onclick: handleViewClick },
    { id: 3, label: 'Deactivate', onclick: handleDeactiveClick },
    { id: 4, label: 'Discard', onclick: handleDiscardClick },
  ];

  const watchData = watch();

  const validate = {
    isFirstName: watchData.FirstName && watchData.FirstName.length >= 3 ? true : false,
    isSurname: watchData.LastName && watchData.LastName.length >= 3 ? true : false,
    isDob: DOB ? true : false,
    isMobileNumber: watchData.MobileNumber && watchData.MobileNumber.length === 10 ? true : false,
    isEmailAddress: watchData.EmailId && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(watchData.EmailId) ? true : false
  }

  const validateAtLeastOneField = () => {
    const isValidate = Object.entries(validate).filter(([key, value]) => value === true);
    return isValidate.length >= 2 ? true : false;
  };

  const onSubmit = (data) => {
    setSearchClicked(true);
    setCurrentPageIndex(1);
    const updatedData = {
      ...data,
      DOB: DOB
      ? new Date(DOB.getTime() - DOB.getTimezoneOffset() * 60000).toISOString()
      : null,
      ClinicId: userClinicId,
      PageIndex: currentPageIndex, 
      PageSize: pageSize
    }
    setSearchData(updatedData);
    dispatch(
      searchPatients({
        ...updatedData
      })
    );
    setSearchFor(
        (updatedData.FirstName !== undefined ? updatedData.FirstName : "") + " " +
        (updatedData.LastName !== undefined ? updatedData.LastName : "") + " " +
        (updatedData.DOB !== undefined && updatedData.DOB !== null ? (new Date(updatedData.DOB)).toLocaleDateString('en-GB') : "") + " " +
        (updatedData.MobileNumber !== undefined ? updatedData.MobileNumber : "") + " " +
        (updatedData.EmailId !== undefined ? updatedData.EmailId : "")
      );
  };
  
  return (
    <React.Fragment>
      <div className="search-control-wrapper">
        <div className="search-control">
          <div className="search-control-item">
            <TextInput
              label="First Name"
              controlId="FirstName"
              {...register("FirstName", { validate: validateAtLeastOneField })}
            />
          </div>
          <div className="search-control-item">
            <TextInput
              label="Surname"
              controlId="LastName"
              {...register("LastName", { validate: validateAtLeastOneField })}
            />
          </div>
          <div className="search-control-item">
            <Calendar
              label="Date of Birth"
              controlId="DOB"
              onChange={(date) => setDob(date)}
            />
          </div>
          <div className="search-control-item">
            <TextInput
              label="Mobile Number"
              controlId="MobileNumber"
              type="number"
              {...register("MobileNumber", {
                validate: validateAtLeastOneField,
              })}
            />
          </div>
          <div className="search-control-item">
            <TextInput
              label="Email Address"
              controlId="EmailId"
              {...register("EmailId", { validate: validateAtLeastOneField })}
            />
          </div>
        </div>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!validateAtLeastOneField()}
        >
          Search
        </Button>
      </div>

      {searchFor ? (
        <React.Fragment>
          {patientsSelector?.totalCount > 0 ? (
            <div>
              <div className="search-results-no">
                {patientsSelector?.totalCount} Search results
                for: <strong>{searchFor}</strong>
              </div>
              <Table
                data={Object.values(patientsSelector?.patients)}
                columns={columns}
                action={action}
                handleSort={(columnId) => handleSort(columnId, watchData)}
            />
            </div>
          ) : (
            <NoData
              variant="pink"
              icon={noPatientIcon}
              title="Patient Profile Not Found"
              caption={[
                "Unfortunately we didn’t find any patient profiles matching: ",
                <strong>{searchFor}</strong>,
              ]}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <div>
            <Table
              data={Object.values(patientsSelector?.patients)}
              columns={columns}
              action={action}
              handleSort={handleSort}
            />
          </div> */}
        </React.Fragment>
      )}
      <Pagination
        pageSize={pageSize}
        currentPageIndex={currentPageIndex}
        totalRecords={patientsSelector.totalCount}
        onPageChange={(e) => onPageIndexChange(e)}
      />
      <Modal
        show={deactivateModalShow}
        onHide={handleDeactivateModalClose}
        title="Deactivate &#123;&#123;Patient Name&#125;&#125;"
      >
        <Deactivate
          onHide={handleDeactivateModalClose}
          message={
            "Are you sure you would like to deactivate {{PatientNamePatientSurname}}, (DOB: {{PatientDOB}}). Deactivating their profile means that their current treatment plans will also be deactivated."
          }
          buttonText={"Deactivate Patient"}
        />
      </Modal>

      <Modal
        show={discardModalShow}
        onHide={handleDiscardeModalClose}
        title="Discard &#123;&#123;Patient Name&#125;&#125;"
      >
        <Deactivate
          onHide={handleDiscardeModalClose}
          message={
            "Are you sure you would like to discard {{PatientNamePatientSurname}}, (DOB: {{PatientDOB}}). Their profile will be set to be discarded, and will only be removed once there is no need to retain their information."
          }
          buttonText={"Discard Patient"}
          phrase="DISCARD"
          data={selectedPatient}
          handleDelete={onDiscardClick}
        />
      </Modal>
    </React.Fragment>
  );
};

export default ViewPatients;

