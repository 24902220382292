import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TreatmentCard from '../../../components/TreatmentCard/TreatmentCard';
import { Button } from '../../../components/FormElements';

//import treatmentPlans from '../TreatmentPlans.json';
import '../Appointments.css';
import NoData from '../../../components/NoData/NoData';
import noPlanIcon from '../../../assets/images/icons/no-plan-icon.svg';

import { navigateAddTreatment, navigateSelectedTreatment } from '../../../app/reducers/treatmentPlanSlice';
import { confirmAppointmentTreatments } from '../../../app/reducers/appointmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import AddAdministered from '../../DrugBook/components/AddAdministered';
import Modal from '../../../components/Modal/Modal';
import { navigateToAdverseEvent } from '../../../app/reducers/adverseEventsSlice';

const TreatmentPlans = (props) => {
  const { appointmentId, patientId, treatmentPlans, clinicId, patientName, isCompleted, isReceptionView, onTabChange } =
    props;

  const [selectedPlan, setSelectedPlan] = useState([]);
  const [administeredModalShow, setAdministeredModalShow] = useState(false);
  const appoinmentsSelector = useSelector((state) => state.appointment);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateTreatmentPlan = () => {
    dispatch(
      navigateAddTreatment({
        patientId: patientId,
        appointmentId: appointmentId,
        id: 0,
        clinicId: clinicId,
        navigate,
        redirectUrl: '/treatmentplan/packages',
        patientName: patientName,
      })
    );
  };

  let data = {pageIndex:1, appointmentId: appoinmentsSelector.appointmentDetails.AppointmentId};

  const navigateAdverseEvent = () => {
    dispatch(
      navigateToAdverseEvent({
        patientId: patientId,
        data:data,
        navigate,
        redirectUrl: '/adverseevent/add',
      })
    );
  };

  const navigateDraftTreatmentPlan = (e, id) => {

  const  treatmentStatus = treatmentPlans.find(x => x.id === id).treatmentStatusId;

    console.log('navigateDraftTreatmentPlan', id);
    dispatch(
      navigateSelectedTreatment({
        treatmentId: id,
        treatmentStatusId : treatmentStatus,
        navigate,
      })
    );
  };

  useEffect(() => {
    treatmentPlans.map((item) => {
      if (item.isSelected) {
        setSelectedPlan([...selectedPlan, item.id]);
      }
    });
  }, []);

  const checkPlan = (e, id) => {
    if (e) {
      setSelectedPlan([...selectedPlan, id]);
    } else {
      const idx = selectedPlan.indexOf(id);
      if (idx !== -1) {
        selectedPlan.splice(idx, 1);
        setSelectedPlan([...selectedPlan]);
      }
    }
  };

  const onSubmit = () => {
    dispatch(
      confirmAppointmentTreatments({
        appointmentId: appointmentId,
        treatmentplansId: selectedPlan,
      })
    );
  };

  const handleAdministered = () => {
    setAdministeredModalShow(true);
  };
  const handleAdministeredModalClose = () => setAdministeredModalShow(false);

  return (
    <React.Fragment>
      {treatmentPlans.length !== 0 ? (
        <React.Fragment>
          <div className="row">
            {treatmentPlans.map(
              (item) =>
                item.treatmentName !== "" && (
                  <div key={item.id} className="col-md-4">
                    <TreatmentCard
                      defaultChecked={item.isSelected}
                      data={item}
                      onChange={(e) => checkPlan(e, item.id)}
                      onEdit={(e) => navigateDraftTreatmentPlan(e, item.id)}
                    />
                  </div>
                )
            )}
          </div>
          <div className="action-msg">Not what you’re looking for?</div>
          <div className="text-center">
            <Button
              variant="secondary"
              onClick={() => navigateTreatmentPlan()}
              disabled={isCompleted || isReceptionView}
            >
              Add New
            </Button>
          </div>
          <div className="row">
            {/* <div className='col-md-6'>
              <div className='text-center fs-18 mt-60'>
              Once you’ve administered, remember to create your drug book entries for this appointment by clicking{' '}
                <u><button className='popup-link' onClick={handleAdministered}> here</button></u>
              </div>
            </div> */}
            <div className="col-md-6">
              <div className="text-center fs-18 mt-60">
                If an adverse event has occurred during this appointment while
                administering the selected DTA, please capture the Adverse Event{" "}
                <u>
                  <Button
                    variant="tertiary"
                    disabled={isReceptionView}
                    onClick={navigateAdverseEvent}
                  >
                    {" "}
                    here{" "}
                  </Button>
                </u>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NoData
            variant="tan"
            icon={noPlanIcon}
            title="No valid Treatment Plans found"
          />
          <div className="no-plan-add-button">
            <Button
              variant="secondary"
              disabled={isReceptionView}
              onClick={() => navigateTreatmentPlan()}
            >
              Add New
            </Button>
            <p>
              For prototype purposes only, please <u>click here</u> to see
              <br /> view when there are valid treatment plans
            </p>
          </div>
        </React.Fragment>
      )}
      <hr className="divider mb-50" />
      <div className="flex justify-end align-center">
        {!isReceptionView ? (
          <Link className="cancel-link" to="/appointments">
            Cancel
          </Link>
        ) : (
          <span className="cancel-link disabled">Cancel</span>
        )}
        {treatmentPlans.length !== 0 ? (
          <Button
            disabled={
              selectedPlan.length === 0 || isCompleted || isReceptionView
            }
            onClick={() => onSubmit()}
            style={{ minWidth: "165px" }}
          >
            Complete
          </Button>
        ) : (
          <Button disabled={true} style={{ minWidth: "165px" }}>
            Next
          </Button>
        )}
      </div>
      <Modal
        show={administeredModalShow}
        onHide={handleAdministeredModalClose}
        title="Add Drugs Administered"
      >
        <AddAdministered onHide={handleAdministeredModalClose} />
      </Modal>
    </React.Fragment>
  );
};

export default TreatmentPlans;
