import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Main from '../layouts/Main/Main';
import Settings from '../pages/Settings/Settings';
import Landing from '../pages/Landing/Landing';
import Patients from '../pages/Patients/Patients';
import AddPatient from '../pages/Patients/AddPatient';
import UpdatePatient from '../pages/Patients/UpdatePatient';
import ViewPatient from '../pages/Patients/ViewPatient';
import Appointments from '../pages/Appointments/Appointments';
import AppointmentDetails from '../pages/Appointments/AppointmentDetails';
import AddAppointment from '../pages/Appointments/AddAppointment';
import Packages from '../pages/TreatmentPlans/Packages';
import MedHistory from '../pages/TreatmentPlans/MedHistory';
import Review from '../pages/TreatmentPlans/Review';
import PatientConsent from '../pages/TreatmentPlans/PatientConsent';
import DrugBook from '../pages/DrugBook/DrugBook';
import Queue from '../pages/VideoConsult/Queue';
import Conduct from '../pages/VideoConsult/Conduct';
import PrescriberDashboard from '../pages/VideoConsult/PrescriberDashboard';
import AdverseEvent from '../pages/AdverseEvent/AdverseEvent';
import AddAdverseEvent from '../pages/AdverseEvent/AddAdverseEvent';
import ViewAdverseEvent from '../pages/AdverseEvent/ViewAdverseEvent';
import Reporting from '../pages/Reporting/Reporting';
import Outcome from '../pages/TreatmentPlans/Outcome';
import ReceivedDrugBook from '../pages/DrugBook/ReceivedDrugBook';
import AdministeredDrugBook from '../pages/DrugBook/AdministeredDrugBook';
import Complete from '../pages/TreatmentPlans/Complete';
import AppointmentDetailsReception from '../pages/Appointments/AppointmentDetailsReception';
import MedHistoryConfirmed from '../pages/Appointments/MedHistoryConfirmed';

const AdminRouters = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main {...props} />}>
          <Route index element={<Landing {...props} />} />
          <Route path='settings' element={<Settings />} />
          <Route path='/patient' element={<Patients />} />
          <Route path='/patient/add' element={<AddPatient />} />
          <Route path='/patient/update' element={<UpdatePatient />} />
          <Route path='/patient/view' element={<ViewPatient />} />
          <Route path='/appointments' element={<Appointments />} />
          <Route path='/appointments/details' element={<AppointmentDetails />} />
          <Route path='/appointments/receptiondetails' element={<AppointmentDetailsReception />} />
          <Route path='/appointments/add' element={<AddAppointment />} />
          <Route path='/treatmentplan/packages' element={<Packages />} />
          <Route path='/treatmentplan/medicalhistory' element={<MedHistory />} />
          <Route path='/treatmentplan/review' element={<Review {...props}/>} />
          <Route path='/treatmentplan/consent' element={<PatientConsent />} />
          <Route path='/treatmentplan/outcome' element={<Outcome />} />
          <Route path='/drugbook' element={<DrugBook />} />
          <Route path='/drugbook/received' element={<ReceivedDrugBook />} />
          <Route path='/drugbook/administered' element={<AdministeredDrugBook />} />
          <Route path='/videoconsult/queue' element={<Queue />} />
          <Route path='/videoconsult/conduct' element={<Conduct />} />
          <Route path='/videoconsult/prescriberdashboard' element={<PrescriberDashboard />} />
          <Route path='/adverseevent' element={<AdverseEvent />} />
          <Route path='/adverseevent/add' element={<AddAdverseEvent />} />
          <Route path='/adverseevent/view' element={<ViewAdverseEvent />} />
          <Route path='/reporting' element={<Reporting />} />
          <Route path='/appointments/complete' element={<Complete />} />
          <Route path='/appointments/detailsconfirmed' element={<MedHistoryConfirmed />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AdminRouters;
