import React, { useEffect } from 'react';
import './VideoConsult.css';
import { useSelector } from 'react-redux';
import PrescriberCallDisplay from './components/VideoCall/PrescriberCallDisplay';

const PrescriberDashboard = () => {
  const communicationSelector = useSelector((state) => state.communication);
  const authenticationSelector = useSelector((state) => state.authentication);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  // const defaultMedHistoryValues = {
  //   Smoke: 'yes',
  //   Pregnant: 'no',
  //   CosmeticInjection: null,
  //   SurgicalProcedures: null,
  //   FacialSurgery: null,
  //   DentalWork: null,
  //   HealthProblems: 'yes',
  //   EpilepticSeizures: 'no',
  //   ColdSores: 'no',
  //   AutoImmune: 'yes',
  //   AnyAllergies: 'yes',
  //   Medication: null,
  //   ShortTermMedication: null,
  //   Vaccinations: null,
  // };

  // useEffect(() => {
  //   console.log('video refresh', videoConsult.reqPageRefresh);
  //   if (videoConsult.reqPageRefresh)
  //   {
  //       const interval = setInterval(() => {
  //         let data = {
  //           prescriberId: authentication.id,
  //         };
  //         dispatch(
  //           getPrescriberTreatment({
  //             data: data,
  //             navigate,
  //             redirectUrl: '/',
  //           })
  //         );
  //       }, 10000);
  //       return () => clearInterval(interval);
  //   }
  // }, []);

  // const onRefresh = () => {
  //   let data = {
  //     prescriberId: authentication.id,
  //   };
  //   dispatch(
  //     getPrescriberTreatment({
  //       data: data,
  //       navigate,
  //       redirectUrl: '/',
  //     })
  //   );
  // };

  return (
    <React.Fragment>
      {/* {videoConsult.reqPageRefresh && (
        <div className='row'>
          <Button style={{ minWidth: '165px' }} onClick={onRefresh}>
            Refresh for video consult call.
          </Button>
        </div>
      )} */}
      {!!communicationSelector &&
        !!communicationSelector.acsToken &&
        !!authenticationSelector &&
        authenticationSelector.id && (
          <div>
            <PrescriberCallDisplay
              token={communicationSelector.acsToken}
              prescriberAcsId={communicationSelector.acsId}
              userId={authenticationSelector.id}
              isUserSignExists={authenticationSelector.isUserSignExists}
            />
          </div>
        )}
    </React.Fragment>
  );
};

export default PrescriberDashboard;
