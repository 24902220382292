import React from 'react';
import closeIcon from "../../assets/images/icons/close-icon.svg";
import './Modal.css';

const Modal = (props) => {
  const { show, onHide, title, children } = props;

  if (!show) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
            <div className="modal-close" onClick={() => onHide()}>
              <img src={closeIcon} />
            </div>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
      <div className="modal-overlay" onClick={() => onHide()} />
    </React.Fragment>
  )
};

export default Modal;