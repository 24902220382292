import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface UserState {
  success: boolean;
  userDetails: '';
  accessToken: '';
  id: 0;
  role: '';
  prescriberNumber: '';
  placeOfPractice: '';
  contactNumber: '';
  areaOfEndorsement: ''
  userId: '',
}

// Define the initial state using that type
const initialState: UserState = {
  success: false,
  userDetails: '',
  accessToken: '',
  id: 0,
  userId: '',
  role: '',
  prescriberNumber: '',
  placeOfPractice: '',
  contactNumber: '',
  areaOfEndorsement: ''
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthenticateUser(state, action) {},
    setAuthenticateUserSuccess(state, action) {
      const userData = action.payload;
      return { ...state, ...userData };
    },
  },
});

export const { setAuthenticateUser, setAuthenticateUserSuccess } =
  authenticationSlice.actions;

export default authenticationSlice.reducer;
