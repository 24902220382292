import { Button} from '../../../components/FormElements';
import { useFormContext,useFieldArray, useWatch } from "react-hook-form";

import '../TreatmentPlans.css';
import AntiWrinkleProducts from './AntiWrinkleProducts';
import { products } from '../../../constants';



const AntiWrinkle = () => {
  
  const methods = useFormContext();
  const { control } = methods;

  const watchData = useWatch();
 
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "antiWrinkle"
  });

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <AntiWrinkleProducts 
            key={index}
            item={item} 
            index={index} 
            product={products} 
            remove={remove} 
            removeButtonShow={watchData?.antiWrinkle?.length > 1 ? true : false}            
          />
        );
      })}
      {watchData?.antiWrinkle[0]?.productName !== '' &&
      <div className="mb-20">
        <Button size="md" variant="blue btn-primary" type="button"
          onClick={() => {
            append({ productName: "", units: "", productId: "", productCategory: "" });
          }}
        >Add Another Treatment</Button>
      </div>
      }
    </div>
  );
};

export default AntiWrinkle;
