import React from 'react';
import './AppointmentCard.css';

const AppointmentCard = (props) => {
  const { data, onClick } = props;

  return (
    <div className="appointment-card" onClick={()=> onClick && onClick(data.Id)}>
      <div className="app-name-wrapper">
        <div className="app-name">{data.PatientName}</div>
        <div className="app-datetime">{data.StrAppointmentStartDate}</div>
      </div>
      <div className="app-pronouns">{data.Pronouns} <span>|</span> {data.Gender} </div>
      <div className="app-date-wrapper">
        <div className="app-date-item">
          <div className="app-date-label">Date of Birth</div>
          <div className="app-date-value">{data.StrDOB}</div>
        </div>
        <div className="app-date-item">
          <div className="app-date-label">Med History Updated</div>
          <div className="app-date-value">{data.MedHistory}</div>
        </div>
      </div>
      <div className="app-nurse">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3457 10.25C10.4004 10.2891 11.2891 10.6895 12.0117 11.4512C12.7539 12.2129 13.125 13.1211 13.125 14.1758C13.125 14.6445 12.959 15.0449 12.627 15.377C12.2949 15.709 11.8945 15.875 11.4258 15.875H1.69922C1.23047 15.875 0.830078 15.709 0.498047 15.377C0.166016 15.0449 0 14.6445 0 14.1758C0 13.1211 0.361328 12.2129 1.08398 11.4512C1.82617 10.6895 2.72461 10.2891 3.7793 10.25L6.5625 13.0332L9.3457 10.25ZM9.19922 8.69727C8.47656 9.41992 7.59766 9.78125 6.5625 9.78125C5.52734 9.78125 4.63867 9.41992 3.89648 8.69727C3.17383 7.95508 2.8125 7.06641 2.8125 6.03125V2.80859C2.8125 2.63281 2.87109 2.45703 2.98828 2.28125C3.10547 2.10547 3.25195 1.98828 3.42773 1.92969L5.91797 0.992188C6.11328 0.914062 6.32812 0.875 6.5625 0.875C6.79688 0.875 7.01172 0.914062 7.20703 0.992188L9.69727 1.92969C9.87305 1.98828 10.0195 2.10547 10.1367 2.28125C10.2539 2.45703 10.3125 2.63281 10.3125 2.80859V6.03125C10.3125 7.06641 9.94141 7.95508 9.19922 8.69727ZM5.39062 2.98438V3.45312C5.39062 3.55078 5.43945 3.59961 5.53711 3.59961H6.18164V4.24414C6.18164 4.3418 6.23047 4.39062 6.32812 4.39062H6.79688C6.89453 4.39062 6.94336 4.3418 6.94336 4.24414V3.59961H7.58789C7.68555 3.59961 7.73438 3.55078 7.73438 3.45312V2.98438C7.73438 2.88672 7.68555 2.83789 7.58789 2.83789H6.94336V2.19336C6.94336 2.0957 6.89453 2.04688 6.79688 2.04688H6.32812C6.23047 2.04688 6.18164 2.0957 6.18164 2.19336V2.83789H5.53711C5.43945 2.83789 5.39062 2.88672 5.39062 2.98438ZM4.21875 5.5625V6.03125C4.21875 6.67578 4.44336 7.23242 4.89258 7.70117C5.36133 8.15039 5.91797 8.375 6.5625 8.375C7.20703 8.375 7.75391 8.15039 8.20312 7.70117C8.67188 7.23242 8.90625 6.67578 8.90625 6.03125V5.5625H4.21875Z" fill="currentColor" />
        </svg>
        {data.Nurse}
      </div>
    </div>
  )
};

export default AppointmentCard;