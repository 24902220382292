import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/FormElements';
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import '../Appointments.css';
import Details from '../../Patients/components/Details';

const PatientProfile = () => {
  const patientDetailsSelector = useSelector((state) => state.appointment);
  const [isSuccess, setIsSuccess] = useState(true);
  const { handleSubmit } = useForm();

  const onSubmit = (data) => console.log(data);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Details patient={patientDetailsSelector.patient} isReadOnly={isSuccess} />
        <div className="action-msg">See something that looks Incorrect?</div>
        <div className="text-center">
          <Button 
          variant="secondary" 
          onClick={()=> setIsSuccess(false)}
          >
            Edit Now
          </Button>
        </div>
        <hr className="divider mb-50" />
        <div className="flex justify-end align-center">
          <Link className='cancel-link' to="/appointments">
            Cancel
          </Link>
          <Button
            style={{ minWidth: "165px" }}>Next</Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default PatientProfile;
