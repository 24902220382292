import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import Modal from '../../../../components/Modal/Modal';
import Deactivate from '../../../../components/Deactivate/Deactivate';

import sortNamecIcon from '../../../../assets/images/icons/sort-name-icon.svg';
import sortNamecUpIcon from '../../../../assets/images/icons/sort-name-up-icon.svg';

import sortRolecIcon from '../../../../assets/images/icons/sort-role-icon.svg';
import sortRolecUpIcon from '../../../../assets/images/icons/sort-role-icon.svg';

import EditProduct from './EditProduct';

import {
  getProducts,
  deleteProduct,
} from '../../../../app/reducers/productSlice';
import { useDispatch, useSelector } from 'react-redux';

const Products = () => {
  const [productModalShow, setProductModalShow] = useState(false);
  const [deactivateModalShow, setDeactivateModalShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [order, setSortOrder] = useState('ASC');
  const [sortedColumn, setSortedColumn] = useState('');
  
  const pageSize =20;

  const dispatch = useDispatch();

  const handleSort = (columnId) => {
    if (sortedColumn === "" || sortedColumn === columnId) {
      setSortOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } 
    setSortedColumn(columnId);
    let data = { pageIndex:1, pageSize:pageSize, parameter:columnId, order: order === 'ASC' ? 'DESC' : 'ASC' };
    dispatch(getProducts({data:data}));
  };

  const productSelector = useSelector((state) => state.product);
  useEffect(() => {
    let data = { pageIndex:1, pageSize:pageSize, order : order };
    dispatch(getProducts({data: data}));
  }, []);

  const onPageIndexChange = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    let data ={PageIndex: pageIndex,
      PageSize: pageSize}
      dispatch(
        getProducts({
          data:data
        })
      );
    };

  const handleEditClick = (rowId) => {
    let editedRecord = productSelector.products.filter((f) => f.Id === rowId);
    setSelectedProduct(editedRecord);
    setProductModalShow(true);
  };

  const handleDeactiveClick = (rowId) => {
    let editedRecord = productSelector.products.filter((f) => f.Id === rowId);

    setSelectedProduct(editedRecord);
    setDeactivateModalShow(true);
  };

  const onDeleteClick = (Id) => {
    dispatch(deleteProduct({ id: Id }));
    setDeactivateModalShow(false);
  };

  const handleModalClose = () => setProductModalShow(false);

  const handleDeactivateModalClose = () => setDeactivateModalShow(false);

  const columns = [
    {
      label: 'Name',
      controlId: 'Name',
      sortable: true,
      sortIconDown: sortNamecIcon,
      sortIconUp: sortNamecUpIcon,
    },
    {
      label: 'Manufacturer',
      controlId: 'Manufacturer',
      sortable: true,
      sortIconDown: sortRolecIcon,
      sortIconUp: sortRolecUpIcon,
    },
    {
      label: 'Available for',
      controlId: 'AvailableFor',
      sortable: true,
    },
  ];

  const action = [
    { id: 1, label: 'Edit', onclick: handleEditClick },
    { id: 2, label: 'Deactivate', onclick: handleDeactiveClick },
  ];

  return (
    <React.Fragment>
      {productSelector.isLoaded && (
        <React.Fragment>
          <Table
            data={productSelector.products}
            columns={columns}
            action={action}
            handleSort={handleSort}
          />
          <Pagination 
          pageSize={pageSize}
          currentPageIndex={currentPageIndex}
          totalRecords={productSelector.totalCount}
          onPageChange={(e) => onPageIndexChange(e)}/>
        </React.Fragment>
      )}

      <Modal
        show={productModalShow}
        onHide={handleModalClose}
        title={`Edit ${selectedProduct[0]?.Name}`}
      >
        <EditProduct onHide={handleModalClose} data={selectedProduct} sortOrder={order} pageIndex={currentPageIndex} sortColumn={sortedColumn} />
      </Modal>

      <Modal
        show={deactivateModalShow}
        onHide={handleDeactivateModalClose}
        title={`Deactivate ${selectedProduct[0]?.Name}`}
      >
        <Deactivate
          onHide={handleDeactivateModalClose}
          message={
            `Are you sure you would like to deactivate ${selectedProduct[0]?.Name}. Deactivating this product will mean it can no longer be used in treatment plans, or drug book entries.`
          }
          buttonText={'Deactivate Product'}
          data={selectedProduct}
          handleDelete={onDeleteClick}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Products;
