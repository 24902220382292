import React, { useState } from 'react';
import { Tabs, Tab } from '../../components/Tabs/Tabs';
import Heading from '../../components/Heading/Heading';
import Users from './components/Users/Users';
import Clinics from './components/Clinics/Clinics';
import Products from './components/Products/Products';
import Dropdown from '../../components/Dropdown/Dropdown';
import Modal from '../../components/Modal/Modal';
import AddUser from './components/Users/AddUser';

import './Settings.css';

const Settings = () => {
  const [userModalShow, setUserModalShow] = useState(false);

  const handleAddClinic = () => {
  }

  const handleAddUser = () => {
    setUserModalShow(true);
  }

  const handleUserModalClose = () => setUserModalShow(false);

  const dropdownItems = [
    // { id: 1, label: "Clinic", onclick: handleAddClinic },
    { id: 2, label: "Users", onclick: handleAddUser }
  ];

  return (
    <React.Fragment>
      <Heading title="Settings" />
      <div className="tab-container">
        <div className="add-new-dropdown">
          <Dropdown label="Add New" items={dropdownItems} />
        </div>
        <Tabs>
          <Tab controlId={"users"} label={"Users"}>
            <Users />
          </Tab>
          <Tab controlId={"clinics"} label={"Clinics"}>
            <Clinics />
          </Tab>
          <Tab controlId={"products"} label={"Products"}>
            <Products />
          </Tab>
        </Tabs>
      </div>

      <Modal
        show={userModalShow}
        onHide={handleUserModalClose}
        title="Add New User"
      >
        <AddUser onHide={handleUserModalClose} />
      </Modal>
    </React.Fragment>
  )
};

export default Settings;