import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../components/FormElements';

import './TreatmentPlans.css';
import Heading from '../../components/Heading/Heading';
import dynamicQuestions from './MedHistoryQuestions.json';
import { useSelector, useDispatch } from 'react-redux';
import { createTreatmentPlan } from '../../app/reducers/treatmentPlanSlice';
import { getTreatmentplanModel } from './constants/Index';
import DynamicQuestions from './components/DynamicQuestions';

const MedHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);

  const appointmentSelector = useSelector((state) => state.appointment);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    // defaultValues: {
    //   Smoke: 'no',
    //   Pregnant: 'no',
    //   CosmeticInjection: 'no',
    //   SurgicalProcedures: null,
    //   FacialSurgery: null,
    //   DentalWork: null,
    //   HealthProblems: null,
    //   EpilepticSeizures: null,
    //   ColdSores: null,
    //   AutoImmune: null,
    //   AnyAllergies: null,
    //   Medication: null,
    //   ShortTermMedication: null,
    //   Vaccinations: null,
    // },
  });

  const watchData = watch();

  const questionsBySequence = [...dynamicQuestions.Questions].sort((a, b) =>
    a.Sequence > b.Sequence ? 1 : -1
  );

  const cosmeticQuestionsBySequence = [
    ...dynamicQuestions.CosmeticQuestions,
  ].sort((a, b) => (a.Sequence > b.Sequence ? 1 : -1));

  const onSubmit = (data) => {
    dispatch(
      createTreatmentPlan({
        data: getTreatmentplanModel(
          { ...treatmentPlanSelector.treatmentInfo }, //
          treatmentPlanSelector.patientId,
          treatmentPlanSelector.appointmentId,
          treatmentPlanSelector.id,
          treatmentPlanSelector.clinicId,
          '',
          treatmentPlanSelector.treatmentInfo.packageType,
          appointmentSelector.medicalHistory.Id
        ),
        navigate,
        redirectUrl: '/treatmentplan/packages',
      })
    );
  };

  const fieldMeetsCondition = () => (item) => {
    if (item.conditional) {
      let conditionMeet = true;
      item.conditional.map((e) => {
        if (e.Value !== watchData[e.GUID]) {
          return (conditionMeet = false);
        }
      });
      return conditionMeet;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Heading
        title='Treatment Plan'
        subHeading={`Capture medical history for ${treatmentPlanSelector.patientName}`}
      />
      <div className='mt-60'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DynamicQuestions
            questionsBySequence={questionsBySequence}
            fieldMeetsCondition={fieldMeetsCondition}
            watchData={watchData}
            control={control}
            register={register}
            errors={errors}
          />
          <hr className='divider mt-50 mb-30' />
          <div className='history-section-title'>Cosmetic Readiness</div>
          <DynamicQuestions
            questionsBySequence={cosmeticQuestionsBySequence}
            fieldMeetsCondition={fieldMeetsCondition}
            watchData={watchData}
            control={control}
            register={register}
            errors={errors}
          />

          <hr className='divider mt-50 mb-50' />
          <div className='flex justify-end align-center'>
            <Link className='cancel-link' to='/appointments/details'>
              Cancel
            </Link>
            <Button
              //disabled={true}
              style={{ minWidth: '165px' }}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default MedHistory;
