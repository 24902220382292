export const getTreatmentplanModel = (
  treatmentInfo,
  patientId,
  appointmentId,
  id,
  clinicId,
  Prescriber,
  treatmentType,
  medicalHistoryId
) => {
  var data = {
    id: id,
    patientId: patientId,
    clinicId: clinicId,
    prescriber: Prescriber,
    treatmentInfo: { ...treatmentInfo },
    treatmentType: treatmentType,
    medicalHistoryId: medicalHistoryId
  };
  return data;
};
