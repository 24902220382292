import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  prescriberId: 0,
  isPrescriberAssigned: false,
  acsId: '',
  token: '',
  waitTime: 5,
  videoConsultingId: 0,
  prescriberDashboard: {
    totalCall: 0,
    availibity: true,
  },
  reqPageRefresh: true
};

const VideoConsultSlice = createSlice({
  name: 'videoConsult',
  initialState,
  reducers: {
    getAcsIdToken(state, action) {},
    getAcsIdTokenSuccess(state, action) {
      let videoConsultData = { ...action.payload };
      return { ...state, ...videoConsultData };
    },
    assignPresciber(state, action) {},
    assignPresciberSuccess(state, action) {      
      let data = { ...action.payload };
      return { ...state, ...{ ...data} };
    },    
    getPrescriberTreatment(state, action) {},
    getPrescriberTreatmentSuccess(state, action) {      
      let data = { ...action.payload };
      return { ...state, ...{ ...data} };
    },
    startCall(state, action) {},
    endCall(state, action) {},
    endCallSuccess(state, action) {
      let data = { ...action.payload };
      return { ...state, ...{ ...data} };
    },
    changePrescriberStatus(state, action) {},
    getPrescriberDashboard(state,action){},
    getPrescriberDashboardSuccess(state,action){
      let prescriberDashboard = { ...action.payload };
      return { ...state, ...{ prescriberDashboard} };
    },
    unassignPrescriber(state,action){},
    joinQueueSuccess(state,action){
      let data = { ...action.payload };
      return { ...state, ...{ ...data} };
    },
    releasePrescriber(state,action){},
  },
});

export const {
  getAcsIdToken,
  getAcsIdTokenSuccess,
  assignPresciber,
  assignPresciberSuccess,
  getPrescriberTreatment,
  getPrescriberTreatmentSuccess,
  startCall,
  endCall,
  endCallSuccess,
  changePrescriberStatus,
  getPrescriberDashboardSuccess,
  getPrescriberDashboard,
  unassignPrescriber,
  joinQueueSuccess,
  releasePrescriber,
} = VideoConsultSlice.actions;

export default VideoConsultSlice.reducer;
