import React, { useEffect, useState } from 'react';
import Heading from '../../components/Heading/Heading';
import BackButton from '../../components/BackButton/BackButton';

import './Appointments.css';

import { useDispatch, useSelector } from 'react-redux';
import { getPatientSuccess } from '../../app/reducers/patientSlice';
import { useNavigate } from 'react-router-dom';
import { treatmentUnmount } from '../../app/reducers/treatmentPlanSlice';
import Toast from '../../components/Toast/Toast';
import { Button } from '../../components/FormElements';
import Modal from '../../components/Modal/Modal';
import ReceptionLock from '../../components/ReceptionLock/ReceptionLock';


const MedHistoryConfirmed = (props) => {
  const appoinmentsSelector = useSelector((state) => state.appointment);    
  const autenticateSelector = useSelector((state) => state.authentication);     
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (appoinmentsSelector.patient) {
      dispatch(getPatientSuccess(appoinmentsSelector.patient));
    }
  }, [appoinmentsSelector.patient]);

  useEffect(() => {
    dispatch(treatmentUnmount());
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  let selectedPlans = appoinmentsSelector?.treatmentPlans?.filter(plan => plan.isSelected === true);

  const isCompleted = appoinmentsSelector.appointmentDetails && appoinmentsSelector.appointmentDetails.AppointmentStatus && appoinmentsSelector.appointmentDetails.AppointmentStatus;

  const handleToastClose = () => setIsShow(false);
  const handleReceptionLockModalClose = () => setReceptionLockShow(false);
  const [receptionLockShow, setReceptionLockShow] = useState(false);


  return (
    <React.Fragment>
      {!!appoinmentsSelector && !!appoinmentsSelector.patient && (
        <React.Fragment>
          <div className="heading-section">
            <BackButton title="Back to all Appointments" onClick={() => setReceptionLockShow(true)} />
            <Heading
              title="Appointment"
              subHeading={`${appoinmentsSelector.patient.FirstName} ${appoinmentsSelector.patient.LastName} | ${appoinmentsSelector.patient.StrDOB}`}
            />
          </div>
          <div className="success-msg-wrapper">
            <Toast
              show={isShow}
              onClose={handleToastClose}
              title="You have successfully completed your form."
            />
          </div>
          <div className="question-title sub-heading">
          Please hand back the device to the receptionist.
          </div>
          <div className="ml-auto sub-heading">
            <Button
              variant="secondary"
              onClick={() => setReceptionLockShow(true)}
            >
              Proceed
            </Button>
          </div>
          <Modal
            show={receptionLockShow}
            onHide={handleReceptionLockModalClose}
            title="View All Appointments"
          >
            <ReceptionLock
              onHide={handleReceptionLockModalClose}
              buttonText={"Go To Appointments"}
              password={autenticateSelector.additionalPassword}
            />
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MedHistoryConfirmed;
