import React, { useEffect, useState } from 'react';
import '../VideoConsult.css';
import Heading from '../../../components/Heading/Heading';
import { ToggleButton } from '../../../components/FormElements';
import NoData from '../../../components/NoData/NoData';
import noCall from '../../../assets/images/icons/no-call.svg';
import { changePrescriberStatus, getPrescriberDashboard } from '../../../app/reducers/videoConsultSlice';
import { useDispatch, useSelector } from 'react-redux';


const QuequeList = () => {
  const [isNoQueque, setIsNoQueque] = useState(false);
  const [acceptingCalls, setAcceptingCalls] = useState(false); 
  const authenticationSelector = useSelector((state) => state.authentication);
  const prescriberDashboardSelector = useSelector((state) => state.videoConsult.prescriberDashboard);

  const dispatch = useDispatch();

  const handleToggleChange = (value) => {
    const status = value === 'yes';
    let data = {
        prescriberId: authenticationSelector.id,
        status: status
      };
    console.log('accepting call status', status)
    setAcceptingCalls(status);
    dispatch(changePrescriberStatus({data:data}));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let data = {
        prescriberId: authenticationSelector.id,
      };
      dispatch(
        getPrescriberDashboard({
          data: data
        })
      );
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setAcceptingCalls(prescriberDashboardSelector.availibity); 
  }, [prescriberDashboardSelector.availibity]); 

  return (
    <React.Fragment>
      <Heading
        title="Video Calls"
      />
      <div className='mt-50'>
        <div className='queque-wrapper'>
          <div className='question-item center-layout'>
            <div className='question-answer'>
              <div className='toggleButton-wrapper'>
                <ToggleButton
                  type='radio'
                  value={'no'}
                  name={'cosmeceuticals'}
                  id={'cosmeceuticalsNo'}
                  label={'No'}
                  onChange={() => handleToggleChange('no')}
                  checked={!acceptingCalls}
                />
                <ToggleButton
                  type='radio'
                  value={'yes'}
                  name={'cosmeceuticals'}
                  id={'cosmeceuticalsYes'}
                  label={'Yes'}
                  onChange={() => handleToggleChange('yes')}
                  checked = {acceptingCalls}
                />
              </div>
            </div>
            <div className='availability-toggle-title'>I’m accepting new calls</div>
          </div>
          <div className='accepting-note'>
            Please note that you will need to complete all calls already assigned to you before you will be able to stop accepting new calls.
          </div>
          <br/><br/>

          {isNoQueque ? (
            <NoData
              className="pt-50"
              variant='tan'
              icon={noCall}
              title='There are no calls currently in the queue'
            />
          ) : (
            <React.Fragment>
              
              <div className='queque-number'><strong>{prescriberDashboardSelector.totalCall}</strong> more calls currently in the queue</div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuequeList;