import React, { useState } from 'react';
import Table from '../../../../components/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import Modal from '../../../../components/Modal/Modal';
import Deactivate from '../../../../components/Deactivate/Deactivate';

import sortNamecIcon from '../../../../assets/images/icons/sort-name-icon.svg';
import sortNamecUpIcon from '../../../../assets/images/icons/sort-name-up-icon.svg';

import sortRolecIcon from '../../../../assets/images/icons/sort-role-icon.svg';
import sortRolecUpIcon from '../../../../assets/images/icons/sort-role-icon.svg';

import EditClinic from './EditClinic';

import { deleteClinic } from '../../../../app/reducers/clinicsSlice';

import { useSelector, useDispatch } from 'react-redux';

const Clinics = () => {
  const [clinicModalShow, setClinicModalShow] = useState(false);
  const [deactivateModalShow, setDeactivateModalShow] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState([]);

  const dispatch = useDispatch();

  const clinic = useSelector((state) => state.clinic);

  const handleEditClick = (rowId) => {
    let editedRecord = clinic.clinics.filter((f) => f.Id === rowId);
    setSelectedClinic(editedRecord);
    setClinicModalShow(true);
  };

  const handleDeactiveClick = (rowId) => {
    let editedRecord = clinic.clinics.filter((f) => f.Id === rowId);
    setSelectedClinic(editedRecord);
    setDeactivateModalShow(true);
  };

  const onDeleteClick = (Id) => {
    dispatch(deleteClinic({ id: Id }));
    setDeactivateModalShow(false);
  };

  const handleClinicModalClose = () => setClinicModalShow(false);

  const handleDeactivateModalClose = () => setDeactivateModalShow(false);

  const columns = [
    {
      label: 'Name',
      controlId: 'Name',
      sortable: true,
      sortIconDown: sortNamecIcon,
      sortIconUp: sortNamecUpIcon,
    },
    {
      label: 'State',
      controlId: 'State',
      sortable: true,
      sortIconDown: sortRolecIcon,
      sortIconUp: sortRolecUpIcon,
    },
    {
      label: 'Brands',
      controlId: 'Brand',
      sortable: true,
      sortIconDown: sortRolecIcon,
      sortIconUp: sortRolecUpIcon,
    },
  ];

  const action = [
    { id: 1, label: 'Edit', onclick: handleEditClick },
    { id: 2, label: 'Deactivate', onclick: handleDeactiveClick },
  ];

  return (
    <React.Fragment>
      <Table data={clinic.clinics} columns={columns} action={action} />
      <Pagination />

      <Modal
        show={clinicModalShow}
        onHide={handleClinicModalClose}
        title={`Edit ${selectedClinic[0]?.Name}`}
      >
        <EditClinic onHide={handleClinicModalClose} data={selectedClinic} />
      </Modal>

      <Modal
        show={deactivateModalShow}
        onHide={handleDeactivateModalClose}
        title={`Deactivate ${selectedClinic[0]?.Name}`}
      >
        <Deactivate
          onHide={handleDeactivateModalClose}
          message={
            `Are you sure you would like to deactivate ${selectedClinic[0]?.Name}. Deactivating this clinic means that appointments associated with this clinic will no longer to able to be viewed.`
          }
          buttonText={'Deactivate Clinic'}
          data={selectedClinic}
          handleDelete={onDeleteClick}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Clinics;
