import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '../../../components/FormElements';

import '../../TreatmentPlans/TreatmentPlans.css';
import Injectibles from '../../TreatmentPlans/components/Injectibles';
import Threads from '../../TreatmentPlans/components/Threads';
import PRP from '../../TreatmentPlans/components/PRP';
import { useSelector, useDispatch } from 'react-redux';
import { updateTreatmentPlan } from '../../../app/reducers/treatmentPlanSlice';
import { getTreatmentplanModel } from '../../TreatmentPlans/constants/Index';
import { packageTypes } from '../../../constants/index';

const EditTreatmentPlan = (props) => {
  const { setIsEdit, data } = props;
  const [packageType, setPackageType] = useState(data.packageType);
  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);
  const defaultValues = treatmentPlanSelector.treatmentInfo;

  const appointmentSelector = useSelector((state) => state.appointment);
  const dispatch = useDispatch();

  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    dispatch(
      updateTreatmentPlan({
        data: getTreatmentplanModel(
          { ...data, packageType: packageType },
          treatmentPlanSelector.patientId,
          treatmentPlanSelector.appointmentId,
          treatmentPlanSelector.id,
          treatmentPlanSelector.clinicId,
          '',
          packageType,
          0
        ),
      })
    );
    setIsEdit(false);
  };
  const handleCancel = () => {
    setIsEdit(false);
  };

  return (
    <React.Fragment>
      <div className='flex align-center mb-50'>
        <div className='view-btn-group'>
          <div
            onClick={() => setPackageType(packageTypes.injectibles)}
            className={
              packageType === packageTypes.injectibles
                ? 'view-btn-item active'
                : 'view-btn-item'
            }
          >
            {Injectibles}
          </div>
          <div
            onClick={() => setPackageType(packageTypes.prp)}
            className={
              packageType === packageTypes.prp
                ? 'view-btn-item active'
                : 'view-btn-item'
            }
          >
            {PRP}
          </div>
          <div
            onClick={() => setPackageType(packageTypes.threads)}
            className={
              packageType === packageTypes.threads
                ? 'view-btn-item active'
                : 'view-btn-item'
            }
          >
            {Threads}
          </div>
        </div>
        <div className='package-note-text'>
          What type of package would you like to include in your package?
        </div>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {packageType === packageTypes.injectibles && <Injectibles />}
          {packageType === packageTypes.prp && <PRP />}
          {packageType === packageTypes.threads && <Threads />}
          <hr className='divider mt-50 mb-50' />
          <div className='flex justify-end align-center'>
            <div className='cancel-link' onClick={() => handleCancel()}>
              Cancel
            </div>
            <Button
              disabled={packageType === '' && true}
              style={{ minWidth: '165px' }}
            >
              Update
            </Button>
          </div>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default EditTreatmentPlan;
