import React, { useEffect, useState } from "react";
import "./AutocompleteProduct.css"
import axios from "axios";
import {envEndPoints } from '../../app/config'
import { useSelector } from 'react-redux';

const AutocompleteProduct = React.forwardRef((props, ref) => {
  const { suggestions, label, controlId, type, onValueChange, clearValue=false, setClearValue, initialValue, ...otherProps } = props;
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  //const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(initialValue ? initialValue : "");
  const [productId, setProductId] = useState(0);

  let productBaseUrl = `${envEndPoints().baseUrl}/product`; 

  const clinicId = useSelector((state) => state.appointment?.appointmentDetails?.clinicId);

  useEffect(() => {
    onValueChange(initialValue)
  },[initialValue]);

  const onChange = (e) => {
    let filteredSuggestions = ''
    const userInput = e.currentTarget.value;    
    if (e.currentTarget.value.length >= 3) {
      if (type === 'wrinkle') {
        let productUrl =  `${productBaseUrl}/search/1/${clinicId}/${e.currentTarget.value}`;       
        axios.get(productUrl).then((response) => {          
          let filteredSuggestions = response.data.map((d) => d.Name);          
          setFilteredSuggestions(filteredSuggestions);                            
        });
      }
      else if (type === 'filler') {
        const filteredSuggestions = suggestions.filter(
          (suggestion) =>
            suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
        setFilteredSuggestions(filteredSuggestions);
      }
      else if (type === 'others') { 
        let productUrl =  `${productBaseUrl}/search/2/${clinicId}/${e.currentTarget.value}`;
        axios.get(productUrl).then((response) => {          
          let filteredSuggestions = response.data.map((d) => d.Name);          
          setFilteredSuggestions(filteredSuggestions);                            
        });
      }    
    } else {      
      setFilteredSuggestions(filteredSuggestions);      
    }
    setActiveSuggestion(0);
    setShowSuggestions(true);    
    setUserInput(e.currentTarget.value);
  };

  const onClick = (e) => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(e.currentTarget.innerText);
    onValueChange(e.currentTarget.innerText);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(filteredSuggestions[activeSuggestion]);
    }
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);

    }
  };

  useEffect(()=> {
    if(clearValue) {
      setUserInput('');
      setClearValue(false)
    }
  }, [clearValue]);

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  return (
    <div className="autocomplete-wrapper">
      <div className="floating-input">
        <input
          ref={ref}
          className="text-input"
          id={controlId}
          type="text"
          placeholder=" "
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          {...otherProps}
        />
        <label className="floating-label" htmlFor={controlId}>{label}</label>
      </div>
      {suggestionsListComponent}
    </div>
  )
});

export default AutocompleteProduct;