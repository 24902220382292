import { useEffect, useRef, useState, useLayoutEffect } from "react";
import {
  CallClient,
  VideoStreamRenderer,
  LocalVideoStream,
} from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import "./CallingWindow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Approval from "../Approval";
import Loading from '../../../../components/Loading/Loading';
import { startCall, endCall, releasePrescriber } from '../../../../app/reducers/videoConsultSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './IncomingCallPopup.css';
import { RoleBasedApprovalStatus } from '../../../../constants';
import { treatentPlanReceiver } from '../../../../app/receivers/appointmentDetailsReceiver';
import { approveTreatmentPlan, declineTreatmentPlan } from '../../../../app/reducers/treatmentPlanSlice';
import ReviewDetails from '../../../TreatmentPlans/components/ReviewDetails';
import EditTreatmentPlan from '../EditTreatmentPlan';
import MedicalHistory from '../MedicalHistory';
import { Tabs, Tab } from '../../../../components/Tabs/Tabs';
import dynamicQuestions from '../../../Appointments/MedHistoryQuestions.json';
import { Button } from '../../../../components/FormElements';
import ring from '../../../../assets/audio/ringsound.mp3'
import QuequeList from "../QuequeList";
import {
  faMicrophone,
  faVideo,
  faVideoSlash,
  faCog,
  faPhoneSlash,
  faMicrophoneSlash,
  faPhoneFlip,
} from '@fortawesome/free-solid-svg-icons';
import QuequeItem from "../QuequeItem";
import MedHistoryReception from "../../../Appointments/components/MedHistoryReception";
import MessageModal from '../../../../components/Modal/MessageModal';
import { getAcsToken } from "../../../../app/reducers/communicationSlice";

const PrescriberCallDisplay = ({ token, prescriberAcsId, userId, isUserSignExists }) => {
  const startCallButtonRef = useRef(null);
  const hangUpCallButtonRef = useRef(null);
  const acceptCallButtonRef = useRef(null);
  const startVideoButtonRef = useRef(null);
  const videoButtonRef = useRef(null);
  const remoteVideosGalleryRef = useRef(null);
  const localVideoContainerRef = useRef(null);

  const [showGoToDashboard, setShowGoToDashboard] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const videoConsult = useSelector((state) => state.videoConsult);
  const authenticationSelector = useSelector((state) => state.authentication);
  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);
  const medicalHistoryValues = treatmentPlanSelector.medicalHistory;
  const patientSelector =  useSelector((state) => state.patient.patientDetails);

  const treatmentPlanReceiverData = treatentPlanReceiver(
    treatmentPlanSelector.treatmentInfo
  );

  const [callAgent, setCallAgent] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);
  const [deviceManager, setDeviceManager] = useState(null);
  const [call, setCall] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  var [localVideoStream, setLocalVideoStream] = useState(null);
  const [localVideoStreamRenderer, setLocalVideoStreamRenderer] = useState([]);
  const [remoteVideoRenderers, setRemoteVideoRenderers] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showIncomingCallPopup, setShowIncomingCallPopup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const incomingCallNotification = useRef(new Audio(ring));
  const [displayWelcome, setdisplayWelcome] = useState(true);
  const [incomingCallPopupDetails, setIncomingCallPopupDetails] = useState([]);

  const [audio, setAudio]  = useState(new Audio(ring, {autoplay: true, muted: true}));

  const handleIncomingCall = async (args) => {
    const incomingCall = args.incomingCall;

    incomingCall.on('callEnded', args => {
      
      if( (args.callEndReason.code === 487 && args.callEndReason.subCode === 10004) ||
      args.callEndReason.code === 603 ||
      (args.callEndReason.code === 480 && args.callEndReason.subCode === 10037) ||
      (args.callEndReason.code === 0 && args.callEndReason.subCode === 0) ||
      args.callEndReason.code === 480 || args.callEndReason.code === 408){
        // setCallInProgress(false);
        // setShowIncomingCallPopup(false);
        // dispatch(getAcsToken({id:authenticationSelector.id}));
        setShowGoToDashboard(true);
      }
    });

    try {
   
      const remoteParticipant = incomingCall._call._remoteParticipants;
      if (remoteParticipant) {
        const values = remoteParticipant[0].displayName.split(" | ");

        const details =
          {
            patientName: values[0] || "",
            nurseName: values[1] || "",
            pronouns: values[2] || "",
            gender: values[3] || "",
            location: values[4] || "",
            treatmentPlanId: values[5] || "",
          };
        
         // incomingCallNotification.current.play();
          
        setIncomingCallPopupDetails(details);
     
      }
     
      //incomingCallNotification.current.play();
      setIncomingCall(args.incomingCall);
    } catch (error) {
      console.error(error);
    }
  };

  const initializeCallAgent = async () => {
    try {
      setIsLoading(true);
  
      const callClient = new CallClient();
      const tokenCredential = new AzureCommunicationTokenCredential(
        token.trim()
      );
  
      // Check if callAgent already exists
      if (!callAgent) {
        const agent = await callClient.createCallAgent(tokenCredential);
        
        // Set up a camera device to use.
        const manager = await callClient.getDeviceManager();
        await manager.askDevicePermission({ video: true });
        await manager.askDevicePermission({ audio: true });
  
        agent.on("incomingCall", handleIncomingCall);
  
        setCallAgent(agent);
        setDeviceManager(manager);
      } else {
        console.log("Call agent already exists for this ACS ID.");
        // You can update the existing agent or handle this case as per your requirement
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const initializeAndStartCall = async () => {
    await initializeCallAgent();
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (deviceManager) {
          clearInterval(intervalId);
          resolve();
        }
      }, 100);
    });
    await deviceManager.askDevicePermission({ video: true });
    await deviceManager.askDevicePermission({ audio: true });
    const stream = await createLocalVideoStream();
    await setLocalVideoStream(stream);
    //await startCall();
  };

  useEffect(() => {
  
    initializeAndStartCall();
  }, []);

  useEffect(() => {
    if (showIncomingCallPopup) {
      if(audio.muted){
        audio.autoplay = true;
        audio.muted = false;
        audio.loop = true;
        audio.play();
      }
    }
    else{
      audio.autoplay = true;
      audio.muted = true;
      audio.loop = false;
      audio.pause();
    }
  });

  useLayoutEffect(() => {
    if (showIncomingCallPopup) {
      if(audio.muted){
        audio.autoplay = true;
        audio.muted = false;
        audio.loop = true;
        audio.play();
      }
    }
    else{
      audio.autoplay = true;
      audio.muted = true;
      audio.loop = false;
      audio.pause();
    }
  });
    

  useEffect(() => {
    audio.autoplay = true;
    if (incomingCall) {
      setShowIncomingCallPopup(true);
    } else {
      setShowIncomingCallPopup(false);
    }
  }, [incomingCall]);

  const disposeCallAgent = async () => {
    if (callAgent) {
      await callAgent.dispose();
    }
  };

  useLayoutEffect(() => {
    audio.autoplay = true;
    if (incomingCall) {
      setShowIncomingCallPopup(true);
    } else {
      setShowIncomingCallPopup(false);
    }
  }, [incomingCall]);


  const createLocalVideoStream = async () => {
    const cameras = await deviceManager.getCameras();
    if (cameras.length > 0) {
      localVideoStream = new LocalVideoStream(cameras[0]);
      setLocalVideoStream(localVideoStream);
      return localVideoStream;
    } else {
      console.error("No camera device found on the system");
    }
  };

  // const displayLocalVideoStream = async () => {
  //   try {
  //     if (localVideoStream) {
  //       const renderer = new VideoStreamRenderer(localVideoStream);
  //       const view = await renderer.createView();
  //       localVideoContainerRef.current.hidden = false;
  //       localVideoContainerRef.current.appendChild(view.target);
  //       setLocalVideoStreamRenderer(renderer);
  //     } else {
  //       // Clear the local video container if the local video stream is null
  //       localVideoContainerRef.current.hidden = true;
  //       localVideoContainerRef.current.innerHTML = '';
  //       setLocalVideoStreamRenderer(null);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const displayLocalVideoStream = async () => {
    try {
      if (localVideoStream) {
        const renderer = new VideoStreamRenderer(localVideoStream);
        const view = await renderer.createView();

        // Clear the previous contents of the container
        while (localVideoContainerRef.current.firstChild) {
          localVideoContainerRef.current.removeChild(
            localVideoContainerRef.current.firstChild
          );
        }

        // Append the new video stream view to the container
        localVideoContainerRef.current.appendChild(view.target);

        setLocalVideoStreamRenderer(renderer);
      } else {
        // Clear the local video container if the local video stream is null
        localVideoContainerRef.current.innerHTML = "";
        setLocalVideoStreamRenderer(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeLocalVideoStream = async () => {
    try {
      if (localVideoStreamRenderer && localVideoStreamRenderer.length > 0) {
        localVideoStreamRenderer[0]?.dispose();
      }
      if (localVideoContainerRef?.current) {
        localVideoContainerRef.current.innerHTML = '';
      } 
      setLocalVideoStreamRenderer(null);
    } catch (error) {
      console.error(error);
    }
  };

  const subscribeToCall = (call) => {
    try {
     

      call.on("idChanged", () => {
       
      });

   

      call.on("stateChanged", async () => {
        console.log(`Call state changed: ${call.state}`);
        if (call.state === "Connected") {
          hangUpCallButtonRef.current.disabled = false;
          // startVideoButtonRef.current.disabled = false;
          // stopVideoButtonRef.current.disabled = false;
          remoteVideosGalleryRef.current.hidden = false;
        } else if (call.state === 'Disconnected') {
          // disposeCallAgent();
          // stopVideoButtonRef.current.disabled = true;
          dispatch(getAcsToken({id:authenticationSelector.id}));
          setCallInProgress(false);
          dispatch(releasePrescriber({ data: { prescriberId: authenticationSelector.id }, navigate, redirectUrl: 0 }));
        }
      });

      call.localVideoStreams.forEach(async (lvs) => {
        setLocalVideoStream(lvs);
        await displayLocalVideoStream();
      });

      call.on("localVideoStreamsUpdated", (e) => {
      
        e.added.forEach(async (lvs) => {
          setLocalVideoStream(lvs);
          await displayLocalVideoStream();
        });
        e.removed.forEach(() => {
          removeLocalVideoStream();
        });
      });

      call.remoteParticipants.forEach((remoteParticipant) => {
        subscribeToRemoteParticipant(remoteParticipant);
      });

      call.on("remoteParticipantsUpdated", (e) => {
        e.added.forEach((remoteParticipant) => {
          subscribeToRemoteParticipant(remoteParticipant);
        });
        e.removed.forEach((remoteParticipant) => {
        
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const subscribeToRemoteParticipant = (remoteParticipant) => {
    try {
     

      remoteParticipant.on("stateChanged", () => {
       
      });

      remoteParticipant.videoStreams.forEach((remoteVideoStream) => {
        subscribeToRemoteVideoStream(remoteVideoStream);
      });

      remoteParticipant.on("videoStreamsUpdated", (e) => {
        e.added.forEach((remoteVideoStream) => {
          subscribeToRemoteVideoStream(remoteVideoStream);
        });
        e.removed.forEach(() => {
         
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const subscribeToRemoteVideoStream = async (remoteVideoStream) => {
    try {
      const renderer = new VideoStreamRenderer(remoteVideoStream);
      const view = await renderer.createView();
      const remoteVideoContainer = document.createElement("div");
      remoteVideoContainer.className = "remote-video-container";
      remoteVideoContainer.appendChild(view.target);
      remoteVideosGalleryRef.current.appendChild(remoteVideoContainer);
      setRemoteVideoRenderers((prevState) => [
        ...prevState,
        { stream: remoteVideoStream, renderer },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  // const startCall = async () => {
  //   try {
  //     const localVideoStream = await createLocalVideoStream();
  //     const videoOptions = localVideoStream
  //       ? { localVideoStreams: [localVideoStream] }
  //       : undefined;
  //     if (prescriberAcsId) {
  //       const newCall = callAgent.startCall(
  //         [{ communicationUserId: prescriberAcsId.trim() }],
  //         {
  //           videoOptions,
  //         }
  //       );
  //       setCall(newCall);
  //       subscribeToCall(newCall);
  //     } else {
  //       console.error('Prescriber ACS ID is not available.');
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const onCallStart = () => {
    let data = {
      prescriberId: userId,
      treatmentPlanId: incomingCallPopupDetails.treatmentPlanId,
    };
    dispatch(
      startCall({
        data: data,
        navigate,
        redirectUrl: "/",
      })
    );
  };

  const onCallEnd = () => {
    let data = {
      userId: authenticationSelector.id,
      videoConsultId: videoConsult.videoConsultingId,
    };
    dispatch(
      endCall({
        data: data,
        navigate,
        redirectUrl: 0,
      })
    );
  };

  const acceptCall = async () => {
    try {
      setShowIncomingCallPopup(false);
      setCallInProgress(true);
      localVideoStream = await createLocalVideoStream();
      const videoOptions = localVideoStream
        ? { localVideoStreams: [localVideoStream] }
        : undefined;
      const acceptedCall = await incomingCall.accept({ videoOptions });

      setCall(acceptedCall);
      subscribeToCall(acceptedCall);

      await displayLocalVideoStream();

      onCallStart();
    } catch (error) {
      console.error(error);
    }
  };

  const rejectCall = async () => {
    try {
      audio.autoplay = true;
      audio.muted = true;
      audio.pause();
      setShowIncomingCallPopup(false);
  
      if (incomingCall) {
        if (localVideoStreamRenderer && localVideoStreamRenderer.length > 0) {
          localVideoStreamRenderer[0]?.dispose();
        }
        if (localVideoContainerRef?.current) {
          localVideoContainerRef.current.innerHTML = '';
        } 
  
        await incomingCall.reject();
        setCall(null);
        setCallInProgress(false);
        // rejoin();
        let data ={prescriberId: authenticationSelector.id};
        dispatch(releasePrescriber({ data: data, navigate, redirectUrl: 0 }));
        dispatch(getAcsToken({id:authenticationSelector.id}));
      }
    } catch (error) {
      console.error(error);
    }
    };

  const hangUpCall = async () => {
    try {
      await call.hangUp();
      onCallEnd();
      setCallInProgress(false);
      await callAgent.dispose();
    } catch (error) {
      console.error(error);
    }
  };

  const editPlan = () => {
    setIsEdit(true);
  };

  const statusId = RoleBasedApprovalStatus.find(
    (approval) =>
      approval.role.toLowerCase() === authenticationSelector.role.toLowerCase()
  )?.id;

  const getApprovalData = (formdata) => {
    let data = {
      id: treatmentPlanSelector.id,
      treatmentStatusId: statusId,
      approvedSignature: formdata.signature,
      approvalComment: formdata.approvalComment,
      placeOfPractice: formdata.placeOfPractice,
      prescriberNumber: formdata.prescriberNumber,
      userid: authenticationSelector.id,
    };
    dispatch(
      approveTreatmentPlan({
        data: data,
        navigate,
        redirectUrl: "/",
      })
    );
  };

  const onDeclinePlan = () => {
    setIsEdit(false);
    let plan = {
      id: treatmentPlanSelector.id,
      treatmentStatusId: 7, //Decline
      approvedSignature: "",
      approvalComment: "Decline",
      placeOfPractice: "",
      prescriberNumber: "PRE123456",
    };
    dispatch(
      declineTreatmentPlan({
        data: plan,
      })
    );
  };

  const toggleMute = async () => {
    try {
      if (call && call.state === "Connected") {
        if (isMuted) {
          await call.unmute();
        } else {
          await call.mute();
        }
        setIsMuted(!isMuted);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleVideo = async () => {
    try {
      if (isVideoOn) {
        await call.stopVideo(localVideoStream);
      } else {
        await call.startVideo(localVideoStream);
      }
      setIsVideoOn(!isVideoOn);
    } catch (error) {
      console.error(error);
    }
  };

  const releasePrescriberClick = async () => {
    try {
      await dispatch(releasePrescriber({ data: { prescriberId: authenticationSelector.id }, navigate, redirectUrl: 0 }));
      setShowGoToDashboard(false);
    } catch (error) {
      console.error(error);
    }
  };

    return (
      <>
        {showGoToDashboard ? (
          <div className="go-to-dashboard-overlay">
             <div className="go-to-dashboard-container d-flex justify-content-center align-items-center">
            <button
                className="btn btn-primary accept go-to-dashboard-btn"
                type="button"
                onClick={releasePrescriberClick}
              >
                Go To Dashboard {"  "}
              </button>
            </div>
          </div>
        ) : (
          <>
            {displayWelcome && (
              <MessageModal
                message="Remember to set whether you are accepting calls."
                title="Welcome back"
                onClick={() => setdisplayWelcome(false)}
              />
            )}
  
            {!callInProgress && <QuequeList></QuequeList>}
            <>
              {showIncomingCallPopup && (
                <div className="incoming-call-popup">
                  <div>
                    <div className="queque-list">
                      <QuequeItem data={incomingCallPopupDetails} />
                      <button
                        className="btn btn-primary accept"
                        ref={acceptCallButtonRef}
                        type="button"
                        onClick={acceptCall}
                        disabled={!incomingCall}
                      >
                        Accept Call {"  "}
                        <FontAwesomeIcon icon={faPhoneFlip} beatFade={true} />
                      </button>
                      <button
                        className="btn btn-primary reject"
                        ref={acceptCallButtonRef}
                        type="button"
                        onClick={rejectCall}
                        disabled={!incomingCall}
                      >
                        Reject Call {"  "}
                        <FontAwesomeIcon icon={faPhoneFlip} beatFade={true} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <br />
              <br />
              <br />
            </>
            {callAgent == null && (
              <div className="loading-wrapper">
                <Loading></Loading>
              </div>
            )}
            {callInProgress && (
              <>
                <div className="video-call-wrapper">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="call_wrapper ratio ratio-16x9">
                        <>
                          <br />
                          <div
                            ref={remoteVideosGalleryRef}
                            className="MainVideo"
                          ></div>
                          <div ref={localVideoContainerRef} className="InVideo">
                            My Video Player
                          </div>
                          <div className="videoconsult_controls">
                            <button
                              type="button"
                              className={`videoconsult_btns ${
                                !isMuted ? "active" : "inactive"
                              }`}
                              onClick={toggleMute}
                            >
                              <i>
                                <FontAwesomeIcon
                                  icon={isMuted ? faMicrophone : faMicrophoneSlash}
                                />
                              </i>
                              {isMuted ? "Unmute" : "Mute"}
                            </button>
                            <button
                              ref={videoButtonRef}
                              type="button"
                              className={`videoconsult_btns ${
                                isVideoOn ? "active" : "inactive"
                              }`}
                              onClick={toggleVideo}
                            >
                              <i>
                                <FontAwesomeIcon
                                  icon={isVideoOn ? faVideoSlash : faVideo}
                                />
                              </i>
                              {isVideoOn ? "Stop Video" : "Start Video"}
                            </button>
                            <button
                              type="button"
                              className="videoconsult_btns inactive"
                            >
                              <i>
                                <FontAwesomeIcon icon={faCog} />
                              </i>
                              Settings
                            </button>
                            <button
                              ref={hangUpCallButtonRef}
                              type="button"
                              onClick={hangUpCall}
                              disabled={!call}
                              className="videoconsult_btns attend active"
                            >
                              <i>
                                <FontAwesomeIcon icon={faPhoneSlash} />
                              </i>
                              Leave
                            </button>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="vc-patient-wrapper">
                        <div className="vc-patient">
                          <div className="vc-patient-name">
                            {incomingCallPopupDetails.patientName}
                          </div>
                          <div className="vc-pronouns">
                            {incomingCallPopupDetails.pronouns} |{" "}
                            {incomingCallPopupDetails.gender}
                          </div>
                        </div>
                        <div className="vc-prescriber">
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.3457 10.25C10.4004 10.2891 11.2891 10.6895 12.0117 11.4512C12.7539 12.2129 13.125 13.1211 13.125 14.1758C13.125 14.6445 12.959 15.0449 12.627 15.377C12.2949 15.709 11.8945 15.875 11.4258 15.875H1.69922C1.23047 15.875 0.830078 15.709 0.498047 15.377C0.166016 15.0449 0 14.6445 0 14.1758C0 13.1211 0.361328 12.2129 1.08398 11.4512C1.82617 10.6895 2.72461 10.2891 3.7793 10.25L6.5625 13.0332L9.3457 10.25ZM9.19922 8.69727C8.47656 9.41992 7.59766 9.78125 6.5625 9.78125C5.52734 9.78125 4.63867 9.41992 3.89648 8.69727C3.17383 7.95508 2.8125 7.06641 2.8125 6.03125V2.80859C2.8125 2.63281 2.87109 2.45703 2.98828 2.28125C3.10547 2.10547 3.25195 1.98828 3.42773 1.92969L5.91797 0.992188C6.11328 0.914062 6.32812 0.875 6.5625 0.875C6.79688 0.875 7.01172 0.914062 7.20703 0.992188L9.69727 1.92969C9.87305 1.98828 10.0195 2.10547 10.1367 2.28125C10.2539 2.45703 10.3125 2.63281 10.3125 2.80859V6.03125C10.3125 7.06641 9.94141 7.95508 9.19922 8.69727ZM5.39062 2.98438V3.45312C5.39062 3.55078 5.43945 3.59961 5.53711 3.59961H6.18164V4.24414C6.18164 4.3418 6.23047 4.39062 6.32812 4.39062H6.79688C6.89453 4.39062 6.94336 4.3418 6.94336 4.24414V3.59961H7.58789C7.68555 3.59961 7.73438 3.55078 7.73438 3.45312V2.98438C7.73438 2.88672 7.68555 2.83789 7.58789 2.83789H6.94336V2.19336C6.94336 2.0957 6.89453 2.04688 6.79688 2.04688H6.32812C6.23047 2.04688 6.18164 2.0957 6.18164 2.19336V2.83789H5.53711C5.43945 2.83789 5.39062 2.88672 5.39062 2.98438ZM4.21875 5.5625V6.03125C4.21875 6.67578 4.44336 7.23242 4.89258 7.70117C5.36133 8.15039 5.91797 8.375 6.5625 8.375C7.20703 8.375 7.75391 8.15039 8.20312 7.70117C8.67188 7.23242 8.90625 6.67578 8.90625 6.03125V5.5625H4.21875Z"
                              fill="#97D5D2"
                            />
                          </svg>
                          {incomingCallPopupDetails.nurseName}
                        </div>
                      </div>
                      {treatmentPlanSelector.treatmentStatusId === 7 ? (
                        <div>
                          Treatment plan has been declined. Please click leave to end
                          the call.
                          <br />
                          <div className="flex justify-end align-center mt-30">
                            <Button
                              style={{ minWidth: "165px" }}
                              onClick={hangUpCall}
                            >
                              Leave Call
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <Approval
                          getApprovalData={getApprovalData}
                          onDeclinePlan={onDeclinePlan}
                          isDisabled={isEdit}
                          prescriberNumebr={authenticationSelector.prescriberNumber}
                          placeOfPractice={authenticationSelector.placeOfPractice}
                          isApproved={
                            treatmentPlanSelector.treatmentStatusId === statusId
                          }
                          isUserSignExists={isUserSignExists}
                          userId={userId}
                          accessToken={authenticationSelector.accessToken}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {!!treatmentPlanSelector.medicalHistory && (
                  <div className="vc-patient-details-wrapper">
                    <Tabs>
                      <Tab controlId={"treatmentPlans"} label={"Treatment Plans"}>
                        {!isEdit && (
                          <ReviewDetails
                            data={treatmentPlanReceiverData}
                            onEdit={() => editPlan()}
                            isApproved={
                              treatmentPlanSelector.treatmentStatusId === statusId
                            }
                          />
                        )}
                        {isEdit && (
                          <EditTreatmentPlan
                            setIsEdit={setIsEdit}
                            data={treatmentPlanReceiverData}
                          />
                        )}
                      </Tab>
                      <Tab controlId={"medicalHistory"} label={"Medical History"}>
                        <MedicalHistory
                          defaultValues={medicalHistoryValues}
                          lastUpdated={"10 May 2023"}
                          dynamicQuestions={dynamicQuestions}
                          isReadonly={true}
                          role={authenticationSelector.role}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };
  
  export default PrescriberCallDisplay;
  