import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../components/FormElements';

import '../../TreatmentPlans/TreatmentPlans.css'
import Heading from '../../../components/Heading/Heading';
import dynamicQuestions from '../MedHistoryQuestions.json';
import { useSelector, useDispatch } from 'react-redux';
import {saveMedicalHistoryDetails} from '../../../app/reducers/appointmentSlice';
import DynamicQuestions from '../DynamicQuestions';
import { Roles } from '../../../constants';

const MedHistoryReception = (props) => {
  const { medicalHistory, onTabChange } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const treatmentPlanSelector = useSelector((state) => state.treatmentPlan);

  const appointmentSelector= useSelector((state) => state.appointment);
  const autehticateSelector= useSelector((state) => state.authentication);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: medicalHistory,
  });

  const watchData = watch();

  const questionsBySequence = [...dynamicQuestions.Questions].sort((a, b) =>
    a.Sequence > b.Sequence ? 1 : -1
  );

  const cosmeticQuestionsBySequence = [
    ...dynamicQuestions.CosmeticQuestions,
  ].sort((a, b) => (a.Sequence > b.Sequence ? 1 : -1));

  const buttonText = autehticateSelector.role=== Roles.Reception ? 'Submit' : 'Save & Next';

  const onSubmit = (data) => {

   
  const url =  autehticateSelector.role=== Roles.Reception? '/appointments/detailsconfirmed' : '/';

    dispatch(
      saveMedicalHistoryDetails({
        patientId: appointmentSelector.patient.Id,
        appointmentId: appointmentSelector.appointmentDetails.AppointmentId,
        userId:autehticateSelector.id, 
        medicalHistoryId: appointmentSelector.medicalHistory.Id,
        medicalHistoryData: data,
        navigate,
        redirectUrl: url,
        role: autehticateSelector.role
      })
    );
    autehticateSelector.role !== Roles.Reception && onTabChange('treatmentPlans');
  };

  const fieldMeetsCondition = () => (item) => {
    if (item.conditional) {
      let conditionMeet = true;
      item.conditional.map((e) => {
        if (e.Value !== watchData[e.GUID]) {
          return (conditionMeet = false);
        }
        // else if(e.Value === watchData[e.GUID] && !!e.PrescriberOnly && autehticateSelector.role !== 'Prescriber')
        // {
        //   return (conditionMeet = false);
        // }
      });
      return conditionMeet;
    }
    return true;
  };

  return (
    <React.Fragment>
      {/* <Heading
        title='Treatment Plan'
        subHeading={`Capture medical history for ${treatmentPlanSelector.patientName}`}
      /> */}
      <div className='mt-60'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DynamicQuestions
            questionsBySequence={questionsBySequence}
            fieldMeetsCondition={fieldMeetsCondition}
            watchData={watchData}
            control={control}
            register={register}
            errors={errors}
          />
          <hr className='divider mt-50 mb-30' />
          <div className='history-section-title'>Cosmetic Readiness</div>
          <DynamicQuestions
            questionsBySequence={cosmeticQuestionsBySequence}
            fieldMeetsCondition={fieldMeetsCondition}
            watchData={watchData}
            control={control}
            register={register}
            errors={errors}
          />

          <hr className='divider mt-50 mb-50' />
          <div className='flex justify-end align-center'>
            <Link className='cancel-link' to='.'
             onClick={() => onTabChange('detail')}
             >
              Back
            </Link>
            <Button
              //disabled={true}
              style={{ minWidth: '165px' }}
            >
              {buttonText}
            </Button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default MedHistoryReception;
