import React from 'react';
import { TextInput, Button } from '../FormElements';
import { useForm } from 'react-hook-form';
import './ReceptionLock.css';
import { useDispatch, useSelector } from 'react-redux';
import { searchAppointments } from '../../app/reducers/appointmentSlice';
import { useNavigate } from 'react-router-dom';

const ReceptionLock = (props) => {
  const {
    onHide,
    buttonText,
    password,
  } = props;

  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = () => {
    navigate('/appointments');
  };
  return (
    <React.Fragment>
      <div>
        <div className='deactivate-text'>
          <p>
        Please enter your passphrase to go back.
          </p>
        </div>
        <TextInput
          label='Confirm phrase'
          controlId='confirm'
          error={errors.confirm && true}
          {...register("confirm", {
            required: true,
            validate: (value) => value === password
          })}
        />
      </div>
      <div className='modal-footer'>
        <div className='cancel-link' onClick={() => onHide()}>
          Cancel
        </div>
        <Button size='sm' onClick={handleSubmit(onSubmit)}>
          {buttonText}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ReceptionLock;
