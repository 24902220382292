import React from 'react';
import { useForm } from 'react-hook-form';
import '../../TreatmentPlans/TreatmentPlans.css';
import DynamicQuestions from '../../Appointments/DynamicQuestions';
import { useDispatch , useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {saveMedicalHistoryDetails} from '../../../app/reducers/appointmentSlice';
import { Button } from '../../../components/FormElements';

const MedicalHistory = (props) => {
  const { defaultValues, lastUpdated, dynamicQuestions, isReadonly , role} = props;

  // console.role('role', role);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const autehticateSelector= useSelector((state) => state.authentication);
 

  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const watchData = watch();

  const questionsBySequence = [...dynamicQuestions.Questions].sort((a, b) =>
    a.Sequence > b.Sequence ? 1 : -1
  );

  const cosmeticQuestionsBySequence = [
    ...dynamicQuestions.CosmeticQuestions,
  ].sort((a, b) => (a.Sequence > b.Sequence ? 1 : -1));

  const onSubmit = (data) => {

    // no need to pass patientId,appointmentId, medicalHistoryId, userId.
    dispatch(
      saveMedicalHistoryDetails({
        patientId: 0,
        appointmentId: 0,
        userId:0, 
        medicalHistoryId: defaultValues.Id,
        medicalHistoryData: data,
        navigate,
        redirectUrl: '/',
      })
    );
  };

  const fieldMeetsCondition = () => (item) => {
    if (item.conditional) {
      let conditionMeet = true;
      item.conditional.map((e) => {
        if (e.Value !== watchData[e.GUID]) {
          return (conditionMeet = false);
        }
        // else if(e.Value === watchData[e.GUID] && !!e.PrescriberOnly && role !== 'Prescriber')
        // {
        //   return (conditionMeet = false);
        // }
      });
      return conditionMeet;
    }
    return true;
  };

  return (
    <React.Fragment>
      {/* <div className="mb-30">Last updated: <strong>{lastUpdated}</strong></div> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <DynamicQuestions
          questionsBySequence={questionsBySequence}
          fieldMeetsCondition={fieldMeetsCondition}
          control={control}
          register={register}
          isReadOnly={isReadonly}
          errors={errors}
        />
        <div className='history-section-title'>Cosmetic Readiness</div>
        <DynamicQuestions
          questionsBySequence={cosmeticQuestionsBySequence}
          fieldMeetsCondition={fieldMeetsCondition}
          control={control}
          register={register}
          isReadOnly={isReadonly}
          errors={errors}
        />
        <hr className='divider mt-50 mb-50' />
        {!isReadonly && 
          <div className='flex justify-end align-center'>
            <Button
              //disabled={true}
              style={{ minWidth: '165px' }}
            >
              Update
            </Button>
          </div>
        }
      </form>
    </React.Fragment>
  );
};

export default MedicalHistory;
