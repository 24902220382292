import React, { useEffect, useState } from 'react';
import Heading from '../../components/Heading/Heading';
import NoData from '../../components/NoData/NoData';
import { Calendar, TextInput, Button } from '../../components/FormElements';
import Table from '../../components/Table/Table';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import Deactivate from '../../components/Deactivate/Deactivate';
import { useForm } from 'react-hook-form';

import searchPatientIcon from '../../assets/images/icons/patient-search-icon.svg';
import './Patients.css';
import ViewPatients from './ViewPatients';

const Patients = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);
  
  return (
    <React.Fragment>
      <div className="heading-section">
        <Heading title='Patients' />
        <div className="header-btn-wrapper">
          <Button variant='secondary' onClick={() => navigate('/patient/add')}>
            Add New
          </Button>
        </div>
      </div>
     <ViewPatients></ViewPatients>
    </React.Fragment>
  );
};

export default Patients;

