import React, { useEffect } from 'react';
import Heading from '../../components/Heading/Heading';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button } from '../../components/FormElements';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './AdverseEvent.css';
import AdverseEventForm from './components/AdverseEventForm';
import { treatmentAreas } from '../../constants';
import { createAdverseEvent } from '../../app/reducers/adverseEventsSlice';

const AddAdverseEvent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appointmentSelector = useSelector((state) => state.appointment);

  const onSubmit =  (data) => {
    const selectedAreas = {};

    treatmentAreas[0].Areas.forEach((item) => {
      selectedAreas[item.Code] = data[item.Code] || false;
    });

    const submitData = {
      AppointmentId: appointmentSelector.appointmentDetails.AppointmentId,
      DrugEntry: data.drugEntry.value,
      EventDescription: data.eventAfterwards,
      CurrentSitutation: data.currentSituation,
      CurrentMedication: data.currentMedication,
      CurrentMedicalConditions:data.currentMedicationConditions,
      EffectedAreas: JSON.stringify(selectedAreas),
      PretreatemnetIsunwell: data.beenUnwell === "yes" ? 1 : 0,
      PretreatemnetNewMedication: data.newMedication === "yes" ? 1 : 0,
      PretreatemnetNewSkincare: data.newSkinCare === "yes" ? 1 : 0,
      PretreatemnetAdditionalInformation: data.furtherInformation,
      PostreatemnetAnyExercise: data.exercise === "yes" ? 1 : 0,
      PostreatemnetSkinTreatment: data.microdermabrasion === "yes" ? 1 : 0,
      PostreatemnetAnyCosmeceuticals: data.cosmeceuticals === "yes" ? 1 : 0,
      TreatmentPlanId: data.treatmentPlanId.value,
      PostreatemnetAdditionalInformation:data.postTreatmentDetails
    } 
    dispatch(
      createAdverseEvent({
        data:submitData,
        navigate,
        redirectUrl: '/adverseevent',
      })
    );
    console.log(submitData);
  };

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const adverseEventDetailsSelector = useSelector((state) => state.adverseEvents.AdverseEventDetails);
  const clinicsSelector = useSelector((state) => state.clinic)

  let clinics = clinicsSelector.clinics.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  const getSelectedClinic = (id) => {
    let selected = clinics.filter((f) => f.Id === id);
    if(selected.length > 0) {
    //setHomeClinicId(selected[0].Id);
    //setHomeClinicName(selected[0].value);
    return selected[0].value;
    }
    else {
      return '';
    }
  };
  
  const { control,handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
        appointment: appointmentSelector.appointmentDetails.ParentServiceName,
        clinic: getSelectedClinic(appointmentSelector.appointmentDetails.clinicId),
        patientFullName: appointmentSelector.patient.Name,
        patientDob: appointmentSelector.patient.StrDOB,
        treatmentDate: appointmentSelector.appointmentDetails.StrStartDate,
    }
  });

  return (
    <React.Fragment>
      <div className="heading-section">
        <Heading
          title="Adverse Event"
          subHeading={`${appointmentSelector.patient.Name} | ${appointmentSelector.appointmentDetails.StrStartDate}`}
        />
      </div>
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <AdverseEventForm control={control} register={register} />
        <React.Fragment>
              <hr className="divider mt-20 mb-50" />
              <div className="flex justify-end align-center">
                <Link className="cancel-link" to="/adverseevent">
                  Cancel
                </Link>
                <Button type="submit">
                  Add Event
                </Button>
              </div>
          </React.Fragment>
        </div>
      </form>

    </React.Fragment>
  );
};

export default AddAdverseEvent;

