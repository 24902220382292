import React from 'react';
import './Table.css';

const TableBody = ({ data, columns, action, columnsMergeId, columnsMergeWith }) => {
  return (
    <tbody>
      {data?.map((data) => {
        return (
          <tr key={data.Id}>
            {columns.map(({ controlId }) => {
              const tData = data[controlId] ? data[controlId] : "-";
              return <td key={controlId}>
                {tData} 
                {columnsMergeId && controlId === columnsMergeId &&
                  <React.Fragment>
                    <div className="sub-columns">
                      {columnsMergeWith?.map((controlId) =>
                        <span key={controlId}>{data[controlId]}</span>
                      )}
                    </div>
                  </React.Fragment>
                }

              </td>;
            })}

            {action && (
              <td className='text-right'>
                {action.map(({ id, label, onclick }) => {
                  return (
                    <span
                      key={id}
                      className='action-item'
                      onClick={() => onclick(data.Id)}
                    >
                      {label}
                    </span>
                  );
                })}
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
