import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import Pagination from '../../components/Pagination/Pagination';
import Modal from '../../components/Modal/Modal';
import AddReceived from './components/AddReceived';
import AddAdministered from './components/AddAdministered';
import { useDispatch, useSelector } from 'react-redux';
import { getDrugBookAdministered } from '../../app/reducers/drugbookSlice';

const AdministeredDrugBook = () => {
  const [viewReceivedModalShow, setViewReceivedModalShow] = useState(false);
  const [viewAdministeredModalShow, setViewAdministeredModalShow] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);  
  const [order, setSortOrder] = useState('ASC');
  const [sortedColumn, setSortedColumn] = useState('');
  const [rowData, setRowData] = useState('');
  const dispatch = useDispatch();
  
  const pageSize = 20;

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  const drugBookSelector = useSelector((state) => state.drugBook);
  const clinicSelector = useSelector((state) => state.clinic);

  const handleSort = (columnId) => {
    if (sortedColumn == "" || sortedColumn === columnId) {
      setSortOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } 
    setSortedColumn(columnId);
    setCurrentPageIndex(1);
    let data = { pageIndex:1, pageSize:pageSize, parameter:columnId, order: order === 'ASC' ? 'DESC' : 'ASC' };
    dispatch(getDrugBookAdministered({data:data}));
  };

  const columns = [

    {
      label: 'Product',
      controlId: 'ProductName',
      sortable: true,
    },
    {
      label: 'Qty',
      controlId: 'Qty',
      sortable: true,
    },
    {
      label: 'Batch Number',
      controlId: 'BatchNumber',
      sortable: true,
    },
    {
      label: 'Appointment Date',
      controlId: 'StrAppointmentDate',
      sortable: true,
    },
    {
      label: 'Clinic',
      controlId: 'Clinic',
      sortable: true,
    },
   
    {
      label: 'Captured By',
      controlId: 'CapturedBy',
      sortable: true,
    }
  ];
 
  const handleViewClick = (rowId) => {
    let getType = drugBookSelector.drugBookAdministered.find(e => {
      if (rowId) {
        return e.Id === rowId;
      }
    });
    setRowData(getType);
    if (getType?.Type === "Received") {
      setViewReceivedModalShow(true);
    } else if (getType?.Type === "Administered") {
      setViewAdministeredModalShow(true);
    }
  };

  const handleViewReceivedModalClose = () => setViewReceivedModalShow(false);
  const handleViewAdministeredModalClose = () => setViewAdministeredModalShow(false);

  const onPageIndexChange = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    const data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      ...(sortedColumn && { parameter: sortedColumn }),
      ...(order && { order: order }),
    };
    dispatch(getDrugBookAdministered({data:data}));
  };

  return (
    <React.Fragment>
      <div className="mt-40">
        {drugBookSelector.drugBookAdministered && (
          <Table
            data={drugBookSelector.drugBookAdministered}
            columns={columns}
            handleSort={handleSort}
          />
        )}
        <Pagination pageSize={pageSize} currentPageIndex={currentPageIndex} totalRecords={drugBookSelector.totalCountAdmin} onPageChange={e => onPageIndexChange(e)}/>
      </div>

      <Modal
        show={viewReceivedModalShow}
        onHide={handleViewReceivedModalClose}
        title="Drugs Received"
      >
        <AddReceived data={rowData} isReadOnly={true} />
      </Modal>

      <Modal
        show={viewAdministeredModalShow}
        onHide={handleViewAdministeredModalClose}
        title=" Drugs Administered"
      >
        <AddAdministered data={rowData} isReadOnly={true} />
      </Modal>
    </React.Fragment>
  );
};

export default AdministeredDrugBook;

