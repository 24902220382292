import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  medicalHistory: {
    AnyAllergies: '',
    AutoImmune: '',
    ColdSores: '',
    CosmeticInjection: '',
    CosmeticInjection_LastTreatment: '',
    CosmeticInjection_TreatmentType: '',
    DentalWork: '',
    EpilepticSeizures: '',
    FacialSurgery: '',
    HealthProblems: '',
    Medication: '',
    Pregnant: '',
    ShortTermMedication: '',
    Smoke: '',
    SurgicalProcedures: '',
    Vaccinations: '',
    specify_medications: '',
    Cosmetic_AppearanceConcerns: '',
    Cosmetic_ConcernsPreoccupy: '',
    Cosmetic_Distress: '',
    Cosmetic_Functionin: '',
    Cosmetic_SignificantlyInterfereSchoolWork: '',
    Cosmetic_SignificantlyInterfereSocialLife: '',
    Cosmetic_WorriedAboutAppearance: '',
    Smoke_Details: '',
    Pregnant_Details: '',
    SurgicalProcedures_Date: '',
    SurgicalProcedures_Type: '',
    FacialSurgery_Details: '',
    DentalWork_Details: '',
    HealthProblems_Details: '',
    EpilepticSeizures_Details: '',
    ColdSores_Details: '',
    AutoImmune_Details: '',
    AnyAllergies_Details: '',
    ShortTermMedication_Details: '',
    Vaccinations_Details: '',
  },
  patientId: 0,
  appointmentId: 0,
  id: 0,
  treatmentInfo: {
    packageType: '',
    antiWrinkle: [
      {
        productName: '',
        units: '',
      },
    ],
    filler: [
      {
        areaName: '',
      },
    ],
    other: [
      {
        productName: '',
        units: '',
      },
    ],
  },
};

const treatmentPlanSlice = createSlice({
  name: 'treatmentPlan',
  initialState,
  reducers: {
    createTreatmentPlan(state, action) {},
    createTreatmentPlanSuccess(state, action) {
      //To do
      const treatmentData = action.payload;
      let plans = {
        ...action.payload,
      };

      return { ...state, ...plans };
    },
    updateTreatmentPlan(state, action) {},
    updateTreatmentPlanSuccess(state, action) {
      //To do
      const treatmentData = action.payload;
      let plans = {
        ...action.payload,
      };

      return { ...state, ...plans };
    },
    navigateAddTreatment(state, action) {},
    navigateAddTreatmentSuccess(state, action) {
      return { ...state, ...{ ...action.payload } };
    },
    approveTreatmentPlan(state, action) {},
    approveTreatmentPlanSuccess(state, action) {
      return { ...state, ...{ ...action.payload } };
    },
    getTreatmentPlan(state, action) {},
    getTreatmentPlanSuccess(state, action) {
      return { ...state, ...{ ...action.payload } };
    },
    patientConsent(state, action) {},
    patientConsentSuccess(state, action) {
      return { ...state, treatmentStatusId: action.payload.treatmentStatusId };
    },
    declineTreatmentPlan(state, action) {},
    declineTreatmentPlanSuccess(state, action) {
      return { ...state, ...{ ...action.payload } };
    },
    rejoin(state, action) {},
    generateDocuments(state, action) {},
    treatmentUnmount(state) {
      return { ...initialState };
    },
    navigateSelectedTreatment(state, action) {},
  },
});

export const {
  createTreatmentPlan,
  createTreatmentPlanSuccess,
  updateTreatmentPlan,
  updateTreatmentPlanSuccess,
  navigateAddTreatment,
  navigateAddTreatmentSuccess,
  approveTreatmentPlan,
  approveTreatmentPlanSuccess,
  getTreatmentPlan,
  getTreatmentPlanSuccess,
  patientConsent,
  patientConsentSuccess,
  declineTreatmentPlan,
  declineTreatmentPlanSuccess,
  rejoin,
  generateDocuments,
  treatmentUnmount,
  navigateSelectedTreatment,
} = treatmentPlanSlice.actions;

export default treatmentPlanSlice.reducer;
