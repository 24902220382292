import { treatmentAreas } from '../../constants/index';
import { datefromString, formatToDatePickerDate } from "../../utilities/dateUtils";
import Moment from 'moment';


let isAntiWrinkle = false;
let isFiller = false;
let isOther = false;
export const treatentPlanReceiver = (treatmentInfo) => {
  return {
    packageType: treatmentInfo.packageType,
    antiWrinkle: getAntiwinkleTreatments(treatmentInfo.antiWrinkle),
    filler: getFillerTreatments(treatmentInfo.filler),
    other: getOtherTreatments(treatmentInfo.other),
  };
};

export const appointmentDetails = (request) => {
  const getTreatmentPlans = () => {
    let treatmentPlans = [];
    if (!!request.TreatmentPlans && !!request.TreatmentPlans.length > 0) {
      request.TreatmentPlans.forEach((plan) => {
        isAntiWrinkle = false;
        isFiller = false;
        isOther = false;
        treatmentPlans.push({
          id: plan.id,
          patientId: plan.patientId,
          isDeleted: plan.isDeleted,
          isCanceled: plan.isCanceled,
          dateOfIssues: plan.dateOfIssues,
          strDateOfIssues: plan.strDateOfIssues,
          clinicName: plan.clinicName,
          clinicId: plan.clinicId,
          prescriber: plan.prescriber,
          createdDate: plan.createdDate,
          isValid: plan.isValid,
          validTill: plan.validTill,
          strValidTill: plan.strValidTill,
          isSelected: plan.isSelected,
          treatmentInfo: {
            packageType: plan.treatmentInfo.packageType,
            antiWrinkle: getAntiwinkleTreatments(
              plan.treatmentInfo.antiWrinkle
            ),
            filler: getFillerTreatments(plan.treatmentInfo.filler),
            other: getOtherTreatments(plan.treatmentInfo.other),
          },
          treatmentName: getTreatmentName(plan.treatmentInfo.packageType),
          treatmentStatusId: plan.treatmentStatusId,
          hideValidity: plan.hideValidity,
        });
      });
    }

    return treatmentPlans;
  };

  const getTreatmentName = (packageType) => {
    let name = '';
    if(packageType === 'prp') {
      name = 'PRP';
    }
    if(packageType === 'threads') {
      name = 'Threads';
    }
    if (isAntiWrinkle === true) {
      name = 'Anti-Wrinkle';
    }
    if (isFiller === true) {
      name = name !== '' ? `${name} / Filler` : 'Filler';
    }
    if (isOther === true) {
      name = name !== '' ? `${name} / Other` : 'Other';
    }
    return name;
  };


  const getDischargeSummaries =(summaries) =>{

    let dischargeSummaries = [];
    if (!!summaries && !!summaries.length > 0) {
      summaries.forEach((summary) => {

        dischargeSummaries.push({
        volume : summary.Volume,
        productName : { label: summary.ProductName },
        treatmentArea : { label: summary.TreatmentArea},
        batchNumber: summary.BatchNumber,
        clinicName: summary.ClinicName,
        administeredDate : datefromString(summary.AdministeredDate),
        administeredUserName: summary.AdministeredUser
        });
      });
    }

    return dischargeSummaries;

  }

  const getMedicalHisory = () => {

    request.medicalHistory.CosmeticInjection_LastTreatment = formatToDatePickerDate( request.medicalHistory.CosmeticInjection_LastTreatment);
    request.medicalHistory.SurgicalProcedures_Date =   formatToDatePickerDate( request.medicalHistory.SurgicalProcedures_Date);
   
    return request.medicalHistory;
  };


  return {
    appointmentAdditionalDetails: !!!request.AppointmentAdditionalDetails? null: request.AppointmentAdditionalDetails,
    appointmentDetails: !!!request.AppointmentDetails ? null : request.AppointmentDetails,
    patient: !!!request.PatientDetails? null: request.PatientDetails,
    treatmentPlans: getTreatmentPlans(),
    dischargeSummaries: !!!request.DischargeSummaries ? null: getDischargeSummaries(request.DischargeSummaries),
    medicalHistory:!!!request.medicalHistory ?null : getMedicalHisory(),
  };
};

const getOtherTreatments = (others) => {
  let data = [];

  if (!!others && !!others.length > 0) {
    others.forEach((other) => {
      if (other.productName !== '') {
        isOther = true;
        data.push(other);
      }
    });
  }
  return data;
};
const getFillerTreatments = (fillers) => {
  let data = [];

  if (!!fillers && !!fillers.length > 0) {
    fillers.forEach((filler) => {
      if (filler.areaName !== '') {
        isFiller = true;
        data.push({
          areaName: filler.areaName,
          areaId: filler.areaId,
          dosage: filler.dosage,
          products: filler.products,
        });
      }
    });
  }

  return data;
};

const getAntiwinkleTreatments = (antiwrinkles) => {
  let data = [];

  if (!!antiwrinkles && !!antiwrinkles.length > 0) {
    antiwrinkles.forEach((antiwrinkle) => {
      if (antiwrinkle.productName !== '') {
        isAntiWrinkle = true;
        data.push({
          productName: antiwrinkle.productName,
          productId: antiwrinkle.productId,
          units: antiwrinkle.units,
          productCategory: antiwrinkle.productCategory,
          treatmentAreas: getTreatmentAreas(
            antiwrinkle.treatmentAreas,
            antiwrinkle.productCategory
          ),
        });
      }
    });
  }

  return data;
};

const getTreatmentAreas = (_treatmentAreas, productCategory) => {
  let areas = [];

  if(_treatmentAreas){

  const areaCodes = treatmentAreas.find((e) => {
    return e.Category === productCategory;
  });

  Object.keys(_treatmentAreas).forEach((e) => {
    if (_treatmentAreas[e] === true) {
      let selectedArea = areaCodes.Areas.find((a) => a.Code === e);
      areas.push({
        areaName: selectedArea.Name,
        dosage: selectedArea.Dosage,
      });
    }
  });
}
  return areas;
};
