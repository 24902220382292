import React, { useState, useEffect } from 'react';

import './TreatmentCard.css';

const TreatmentCard = React.forwardRef((props, ref) => {
  const { defaultChecked, data, onChange, onEdit, ...otherProps } = props;
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleChange = (e) => {
    setIsChecked((isChecked) => !isChecked);
    onChange(e.target.checked);
  };

  const handleSelectedDraftedApp = (e) => {
    onEdit(e);
  };


  const isDraftApplication = data.treatmentStatusId < 5;

  return (
    <React.Fragment>
      <label
        className={
          isChecked && !data.isCancelled
            ? 'treatment-card selected'
            : 'treatment-card'
        }
      >
        {!data.isCancelled &&(
          <input
            ref={ref}
            type={'checkbox'}
            checked={isChecked}
            onChange={isDraftApplication? handleSelectedDraftedApp: handleChange}
            {...otherProps}
          />
        )}
        {isChecked && !data.isCancelled && (
          <div className='tp-checkmark'>
            <svg
              width='40'
              height='40'
              viewBox='0 0 40 40'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='20' cy='20' r='18.5' stroke='white' strokeWidth='3' />
              <path
                d='M20 5.21875C22.5781 5.21875 25.0391 5.92188 27.2656 7.21094C29.4922 8.5 31.25 10.2578 32.5391 12.4844C33.8281 14.7109 34.5312 17.1719 34.5312 19.75C34.5312 22.3867 33.8281 24.7891 32.5391 27.0156C31.25 29.2422 29.4922 31.0586 27.2656 32.3477C25.0391 33.6367 22.5781 34.2812 20 34.2812C17.3633 34.2812 14.9609 33.6367 12.7344 32.3477C10.5078 31.0586 8.69141 29.2422 7.40234 27.0156C6.11328 24.7891 5.46875 22.3867 5.46875 19.75C5.46875 17.1719 6.11328 14.7109 7.40234 12.4844C8.69141 10.2578 10.5078 8.5 12.7344 7.21094C14.9609 5.92188 17.3633 5.21875 20 5.21875ZM20 8.03125C17.8906 8.03125 15.8984 8.55859 14.1406 9.61328C12.3242 10.668 10.918 12.1328 9.86328 13.8906C8.80859 15.707 8.28125 17.6406 8.28125 19.75C8.28125 21.8594 8.80859 23.8516 9.86328 25.6094C10.918 27.4258 12.3242 28.832 14.1406 29.8867C15.8984 30.9414 17.8906 31.4688 20 31.4688C22.1094 31.4688 24.043 30.9414 25.8594 29.8867C27.6172 28.832 29.082 27.4258 30.1367 25.6094C31.1914 23.8516 31.7188 21.8594 31.7188 19.75C31.7188 17.6406 31.1914 15.707 30.1367 13.8906C29.082 12.1328 27.6172 10.668 25.8594 9.61328C24.043 8.55859 22.1094 8.03125 20 8.03125ZM28.2031 15.6484C28.3203 15.8242 28.4375 16 28.4375 16.1758C28.4375 16.4102 28.3203 16.5273 28.2031 16.6445L18.125 26.6641C17.9492 26.8398 17.7734 26.8984 17.5977 26.8984C17.3633 26.8984 17.2461 26.8398 17.1289 26.6641L11.7969 21.332C11.6211 21.2148 11.5625 21.0391 11.5625 20.8047C11.5625 20.6289 11.6211 20.4531 11.7969 20.3359L13.1445 18.9883C13.2617 18.8711 13.3789 18.8125 13.6133 18.8125C13.7891 18.8125 13.9648 18.8711 14.1406 18.9883L17.5977 22.5625L25.918 14.3008C26.0352 14.1836 26.1523 14.125 26.3867 14.125C26.5625 14.125 26.7383 14.2422 26.9141 14.3594L28.2031 15.6484Z'
                fill='white'
              />
            </svg>
          </div>
        )}
        {data.isCancelled && <div className='cancelled-ribbon'>Cancelled</div>}

        {isDraftApplication && <div className='cancelled-ribbon'>Draft</div>}
        {!data.hideValidity && !isDraftApplication &&
        <div className='app-nurse'>
          
          <svg
            width='14'
            height='16'
            viewBox='0 0 14 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12.7734 5.5625H0.351562C0.117188 5.5625 0 5.44531 0 5.21094V4.15625C0 3.76563 0.136719 3.43359 0.410156 3.16016C0.683594 2.88672 1.01562 2.75 1.40625 2.75H2.8125V1.22656C2.8125 0.992188 2.92969 0.875 3.16406 0.875H4.33594C4.57031 0.875 4.6875 0.992188 4.6875 1.22656V2.75H8.4375V1.22656C8.4375 0.992188 8.55469 0.875 8.78906 0.875H9.96094C10.1953 0.875 10.3125 0.992188 10.3125 1.22656V2.75H11.7188C12.1094 2.75 12.4414 2.88672 12.7148 3.16016C12.9883 3.43359 13.125 3.76563 13.125 4.15625V5.21094C13.125 5.44531 13.0078 5.5625 12.7734 5.5625ZM0.351562 6.5H12.7734C13.0078 6.5 13.125 6.61719 13.125 6.85156V14.4688C13.125 14.8594 12.9883 15.1914 12.7148 15.4648C12.4414 15.7383 12.1094 15.875 11.7188 15.875H1.40625C1.01562 15.875 0.683594 15.7383 0.410156 15.4648C0.136719 15.1914 0 14.8594 0 14.4688V6.85156C0 6.61719 0.117188 6.5 0.351562 6.5ZM10.1074 9.3125L9.28711 8.49219C9.13086 8.31641 8.96484 8.30664 8.78906 8.46289L5.68359 11.5684L4.33594 10.1914C4.17969 10.0352 4.01367 10.0352 3.83789 10.1914L3.01758 11.0117C2.8418 11.1875 2.8418 11.3535 3.01758 11.5098L5.41992 13.9707C5.5957 14.127 5.76172 14.127 5.91797 13.9707L10.1074 9.81055C10.2832 9.63477 10.2832 9.46875 10.1074 9.3125Z'
              fill='currentColor'
            />
          </svg>
          
          {/* {data.isValid ? 'Valid' : 'Invalid'} at {data.clinicName} & until{' '}
          {data.strValidTill} */}
          {data.isValid ? `Valid until ${data.strValidTill}` : 'Invalid' }
         
        </div>
        }
        <div className='app-date-wrapper'>
          <div className='app-date-item'>
            <div className='app-date-label'>Date of Issue</div>
            <div className='app-date-value'>{data.strDateOfIssues}</div>
          </div>
          <div className='app-date-item'>
            <div className='app-date-label'>Prescriber</div>
            <div className='app-date-value'>{data.prescriber}</div>
          </div>
        </div>
        <div className='app-name'>{data.treatmentName}</div>
        <div className='tp-product-wrapper'>
          {data.treatmentInfo.antiWrinkle.length !== 0 &&
            data?.treatmentInfo?.antiWrinkle.map((item, index) => (
              <div className='tp-product-item' key={index}>
                <div className='tp-product-name'>{item?.productName}</div>
                {item?.treatmentAreas?.map((item, index) => (
                  <div key={index} className='tp-treatment-area'>
                    {item.areaName} (Max Dose: {item.dosage})
                  </div>
                ))}
              </div>
            ))}
          {data?.treatmentInfo?.filler.length !== 0 &&
            data?.treatmentInfo?.filler.map((item, index) => (
              <div className='tp-product-item' key={index}>
                <div className='tp-product-name'>{item?.areaName}</div>
                {item?.products?.map((item, index) => (
                  <div key={index} className='tp-treatment-area'>
                    {item.productName} (Max Dose: {item.units})
                  </div>
                ))}
              </div>
            ))}

          {data?.treatmentInfo?.other.length !== 0 &&
            data?.treatmentInfo?.other.map((item, index) => (
              <div className='tp-product-item' key={index}>
                <div className='tp-product-name'>{item?.productName}</div>
                <div className='tp-treatment-area'>Max Dose: {item.units}</div>
              </div>
            ))}
        </div>
      </label>
    </React.Fragment>
  );
});

export default TreatmentCard;
