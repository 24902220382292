import React, { useState } from 'react';
import {
  TextInput,
  SelectBox,
  Button,
} from '../../../../components/FormElements';
import { useForm, Controller } from 'react-hook-form';
import { updateUser } from '../../../../app/reducers/userSlice';

import './Users.css';
import { useSelector, useDispatch } from 'react-redux';

import AutocompleteClinic from '../../../../components/AutocompleteClinic/AutocompleteClinic';

const EditUser = (props) => {
  const clinicSelector = useSelector((state) => state.clinic);

  const userRolesSelector = useSelector((state) => state.userRoles);
  const authenticationSelector = useSelector((state) => state.authentication);

  console.log('userRolesSelector', userRolesSelector.roles);
  const dispatch = useDispatch();

  

  const role = [
     // { value: 'receptionist', label: 'Receptionist', Id: 1 },
    // { value: 'injector', label: 'Nurse Injector', Id: 2 },
    // { value: 'practitioners', label: 'Nurse Practitioners', Id: 3 },
    // { value: 'prescribers', label: 'Prescribers', Id: 4 },
    // { value: 'administrator', label: 'System Administrator', Id: 5 },
    //
    { value: 'Admin', label: 'Admin', Id: 1 },
    { value: 'Prescriber', label: 'Prescriber', Id: 2 },
    { value: 'User', label: 'User', Id: 5 },
    { value: 'NurseInjector', label: 'NurseInjector', Id: 6 },
    { value: 'NursePractitioner', label: 'NursePractitioner', Id: 7 },
    { value: 'Reception', label: 'Reception', Id: 8},
  ];

 

  let clinics = clinicSelector.clinics.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  // let selected = clinics.filter((f) => f.Id === props.data[0].HomeClinicId)[0].value;
  // setRoleId(selected);
  // console.log('role',roleId);

  const getSelectedClinic = (id) => {
    let selected = clinics.filter((f) => f.Id === id);
    if (selected.length > 0) {
      return selected[0];
    } else {
      return clinics[0];
    }
  };

  const getHomeClinic =(id) => {
    let selected = clinics.filter((f) => f.Id === id);
    if(selected.length > 0) {
    //setHomeClinicId(selected[0].Id);
    //setHomeClinicName(selected[0].value);
    return selected[0].value;
    }
    else {
      return '';
    }
  };

  const getOtherClinic =(id) => {
    let selected = clinics.filter((f) => f.Id === id);
    if(selected.length > 0) {
    //setOtherClinicId(selected[0].Id);
    //setOtherClinicName(selected[0].value);
    return selected[0].value;
    }
    else {
      return '';
    }
  };

  const getSelectedRole = (id) => {    
    let selected = userRolesSelector.roles.filter((f) => f.Id === id);
    if (selected.length > 0) {
      return selected[0];
    } else {
      return role[0];
    }
  };

  const roleOpt = getSelectedRole(props.data[0].RoleId);
  const [roleId, setRoleId] = useState(roleOpt.Id);
  const [homeClinicId, setHomeClinicId] = useState(props.data[0].HomeClinicId);  
  const [otherClinicId, setOtherClinicId] = useState(props.data[0].ActiveClinicId);  

  const onRoleChange = (e) => {    
    setRoleId(e.Id);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      FirstName: props.data[0].FirstName,
      Surname: props.data[0].LastName,
      EmailId: props.data[0].EmailId,
      HomeClinic: getHomeClinic(props.data[0].HomeClinicId),
      OtherClinic: getOtherClinic(props.data[0].ActiveClinicId),
      Role: getSelectedRole(props.data[0].RoleId),
      PlaceOfPractice: props.data[0].PlaceOfPractice,
      PrescriberNumber: props.data[0].PrescriberNumber,
      ContactNumber: props.data[0].ContactNumber,
      AreaOfEndorsement: props.data[0].AreaOfEndorsement,
      AdditionalPassword: props.data[0].AdditionalPassword
    },
  });
  const onSubmit = (data) => {    
    dispatch(
      updateUser({
        FirstName: data.FirstName,
        LastName: data.Surname,
        RoleId: roleId,
        ActiveClinicId: otherClinicId,
        HomeClinicId: homeClinicId,
        UserId: props.data[0].Id,
        EmailId: data.EmailId,
        PlaceOfPractice: data.PlaceOfPractice,
        PrescriberNumber: data.PrescriberNumber,
        ContactNumber: data.ContactNumber,
        AreaOfEndorsement: data.AreaOfEndorsement,
        loginUserId: authenticationSelector.id,
        AdditionalPassword:data.AdditionalPassword
      })
    );
    props.onHide();
  };


  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='add-user-form'>
          <div className='row'>
            <div className='col-md-6'>
              <Controller
                name='FirstName'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='First Name'
                    controlId='FirstName'
                    error={errors.FirstName && true}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'First Name is required!',
                  },
                }}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='Surname'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Surname'
                    controlId='Surname'
                    error={errors.Surname && true}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Surname is required!',
                  },
                }}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='EmailId'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Email Address'
                    controlId='EmailId'
                    error={errors.EmailId && true}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Email address is required!',
                  },
                  validate: {
                    matchPattern: (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      'Email address must be a valid address',
                  },
                }}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='HomeClinic'
                control={control}
                render={({ field: { onChange, value } }) => (                  
                  <AutocompleteClinic
                    label='Home Clinic'
                    controlId='HomeClinic'
                    initialValue={value}
                    onValueChange={(e,i) => {                      
                      onChange(e);             
                      if(i !== undefined) {
                        setHomeClinicId(i);
                      }                               
                    }}
                  />
                )}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='OtherClinic'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteClinic
                    label='Other Clinic'
                    controlId='OtherClinic'
                    initialValue={value}
                    onValueChange={(e,i) => {                      
                      onChange(e);
                      if(i !== undefined) {
                        setOtherClinicId(i);
                      }                               
                    }}
                  />
                )}
              />
            </div>
            <div className='col-md-6'>
              {userRolesSelector.roles && (
                <Controller
                  name='Role'
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      label='System Role'
                      options={userRolesSelector.roles}
                      name='Role'
                      defaultValue={roleOpt}
                      onChange={(e) => onRoleChange(e)}
                      error={errors.Role && true}                      
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Role is required!',
                    },
                  }}
                />
              )}
            </div>
            {roleId === 2 && (
              <React.Fragment>
                <div className='col-md-6'>
                  <Controller
                    name='PlaceOfPractice'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Place Of Practice' 
                        controlId='PlaceOfPractice' 
                        error={errors.PlaceOfPractice && errors.PlaceOfPractice.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Place Of Practice is required!',
                      },
                    }}
                  />
                </div>
                <div className='col-md-6'>
                  <Controller
                    name='PrescriberNumber'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Prescriber Number' 
                        controlId='PrescriberNumber' 
                        error={errors.PrescriberNumber && errors.PrescriberNumber.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Prescriber Number is required!',
                      },
                    }}
                  />
                </div>
                <div className='col-md-6'>
                  <Controller
                    name='ContactNumber'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Contact Number' 
                        controlId='ContactNumber' 
                        error={errors.ContactNumber && errors.ContactNumber.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Contact Number is required!',
                      },
                    }}
                  />
                </div>
              </React.Fragment>
            )}
            {roleId === 7 && (
              <React.Fragment>
                <div className='col-md-6'>
                  <Controller
                    name='PrescriberNumber'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Prescriber Number' 
                        controlId='PrescriberNumber' 
                        error={errors.PrescriberNumber && errors.PrescriberNumber.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Prescriber Number is required!',
                      },
                    }}
                  />
                </div>
                <div className='col-md-6'>
                  <Controller
                    name='AreaOfEndorsement'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Area Of Endorsement' 
                        controlId='AreaOfEndorsement' 
                        error={errors.AreaOfEndorsement && errors.AreaOfEndorsement.message}
                        {...field}
                        />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Area Of Endorsement is required!',
                      },
                    }}
                  />
                </div>
              </React.Fragment>
            )}
            <div className='col-md-6'>
                  <Controller
                    name='AdditionalPassword'
                    control={control}
                    render={({ field }) => (
                      <TextInput 
                        label='Passphrase' 
                        controlId='AdditionalPassword' 
                        error={errors.AdditionalPassword && errors.AdditionalPassword.message}
                        {...field}
                        />
                    )}
                  />
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <div className='cancel-link' onClick={() => props.onHide()}>
            Cancel
          </div>
          <Button size='sm'>Update User</Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default EditUser;
