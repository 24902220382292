import React from "react";
import {
  TextInput,
  SelectBox,
  Button,
  Calendar,
} from "../../../components/FormElements";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { addRemovedDrugBook } from "../../../app/reducers/drugbookSlice";
import { datefromString, currentdate, nowDateTime } from "../../../utilities/dateUtils";
import { useState } from "react";

const AddRemoved = (props) => {
  const { onHide, data, isReadOnly } = props;
  const clinicSelector = useSelector((state) => state.userClinic);
  const clinicsSelector = useSelector((state) => state.clinic);
  const authSelector = useSelector((state) => state.authentication);
  const [selectedOption, setSelectedOption] = useState("");

  const dispatch = useDispatch();

  let clinics = clinicsSelector.clinics.map((c) => ({
    value: c.Name,
    label: c.Name,
    Id: c.Id,
  }));

  const getSelectedClinic = (id) => {
    let selected = clinics.filter((f) => f.Id === id);
    if (selected.length > 0) {
      return selected[0];
    }
  };

  const options = [
    {
      value: "Moved To Another Clinic",
      label: "Moved To Another Clinic",
      Id: 1,
    },
    {
      value: "Expired",
      label: "Expired",
      Id: 2,
    },
    {
      value: "Discarded",
      label: "Discarded",
      Id: 2,
    },
  ];

  const handleDropdownChange = (value) => {
    setSelectedOption(value);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productName: data ? data.productName: "",
      batchNumber: data ? data.BatchNumber : "",
      clinic: clinicSelector
        ? getSelectedClinic(clinicSelector?.UserClinicId)
        : "",
      dateRemoved: currentdate(),
      quantity: data ? data.Qty : "",
      removedBy: authSelector.userDetails.name
    },
  });

  const onSubmit = (data) => {
      dispatch(addRemovedDrugBook({
        ProductName: data.productName,
        BatchNumber: data.batchNumber,
        ClinicId: clinicSelector.UserClinicId,
        RemovedDate: nowDateTime() ? nowDateTime().toISOString() : null,
        Quantity: data.quantity,
        UserId: authSelector.id,
        Reason: selectedOption.value
      })
    );
    onHide();
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="add-form">
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="productName"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label="Product Name"
                    controlId="productName"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Product Name is required!",
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="batchNumber"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label="Batch Number"
                    controlId="batchNumber"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Batch Number is required!",
                  },
                }}
              />
            </div>
            
            <div className="col-md-6">
              <Controller
                name="quantity"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label="Quantity"
                    controlId="quantity"
                    type="number"
                    disabled={isReadOnly}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Quantity is required!",
                  },
                }}
              />
            </div>
            <div className="search-control-item">
              <div className="select-box-container">
                <SelectBox
                  label="Reason"
                  options={options}
                  name="Reason"
                  value={selectedOption}
                  onChange={handleDropdownChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <Controller
                name="dateRemoved"
                control={control}
                render={({ field }) => (
                  <Calendar
                    label="Date Removed"
                    controlId="dateRemoved"
                    disabled={true}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Date Removed is required!",
                  },
                }}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="clinic"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    label="Clinic"
                    options={clinics}
                    name="clinic"
                    readOnly={true}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='removedBy'
                control={control}
                render={({ field }) => (
                  <TextInput
                    label='Removed by'
                    controlId='removedBy'
                    disabled={true}
                    {...field}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Removed by is required!',
                  }
                }}
              />
            </div>
          </div>
        </div>
        {!isReadOnly && (
          <div className="modal-footer">
            <div className="cancel-link" onClick={() => onHide()}>
              Cancel
            </div>
            <Button size="sm">Add Entry</Button>
          </div>
        )}
      </form>
    </React.Fragment>
  );
};

export default AddRemoved;
